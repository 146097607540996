import React from 'react';
import { useTranslation } from 'react-i18next';
import { Construction } from 'lucide-react';
import { meterToKilometer } from '@/utils/analysis/unitsConvertor';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import TKTextWithLabel from '@/components/Common/TKTextWithLabel';
import { TypographyH4 } from '@/components/ui/typography';

interface ServiceDistanceForm {
  km: number | null;
}
interface Props {
  equipmentId: string;
}

function EquipmentServiceTab({ equipmentId }: Props) {
  const { companyId } = useAuth();
  const { t, i18n } = useTranslation();

  function formatValue(meter: number | undefined | null) {
    if (meter === undefined || meter === null) {
      return '-';
    }
    const km = meterToKilometer(meter);
    if (km <= 0) {
      return `${km} (${t(
        'analysis.reports.service_distance.distance_reached'
      )})`;
    }

    return t(`shared-reports.units.kilometer`, {
      amount: km.toLocaleString(i18n.language),
      count: km,
    });
  }

  const serviceDistance = trpc.equipmentDistance.serviceById.useQuery({
    equipmentId,
    companyId,
  });

  const form = useForm<ServiceDistanceForm>({
    values:
      typeof serviceDistance.data?.overwriteDistanceToNextServiceMeter ===
      'number'
        ? {
            km: meterToKilometer(
              serviceDistance.data?.overwriteDistanceToNextServiceMeter
            ),
          }
        : undefined,
  });

  const insertServiceDistance =
    trpc.equipmentDistance.overwriteServiceDistance.useMutation({
      onSuccess() {
        toast.success(t('equipment.service_distance_updated'));
      },
      onError() {
        toast.error(t('equipment.service_distance_update_failed'));
      },
    });

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <Construction />
            <TypographyH4>{t('equipment.service')}</TypographyH4>
          </CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <TKTextWithLabel
            loading={serviceDistance.isLoading}
            label={t('analysis.reports.service_distance.title')}
            text={formatValue(
              serviceDistance.data?.overwriteDistanceToNextServiceMeter ||
                serviceDistance.data?.distanceToNextServiceMeter
            )}
          />

          {serviceDistance.isSuccess && (
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit((val) => {
                  insertServiceDistance.mutate({
                    companyId,
                    equipmentId,
                    km: val?.km || null,
                  });
                })}
                className="space-y-4"
              >
                <FormField
                  control={form.control}
                  name="km"
                  rules={{
                    min: {
                      value: 1,
                      message: t('validations.minNumber', { min: 1 }),
                    },
                  }}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        {t('equipment.overwrite_automatic_service')}
                      </FormLabel>
                      <Input
                        type="number"
                        {...field}
                        value={field.value?.toString() || ''}
                        onChange={(e) => field.onChange(e.target.valueAsNumber)}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button
                  type="submit"
                  isLoading={insertServiceDistance.isLoading}
                >
                  {t('common.saveText')}
                </Button>
              </form>
            </Form>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default EquipmentServiceTab;
