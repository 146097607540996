import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Control,
  useController,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form';
import { PHONE_REGEX } from '@/utils/common/validators';
import { FormField } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { TypographySmall } from '@/components/ui/typography';
import SelectCountry from './SelectCountry';
import { useCountriesTranslation } from '@/hooks/common/useCountriesTranslation';

interface Props<TForm extends FieldValues> {
  control: Control<TForm>;
  phoneName: Path<TForm>;
  prefixName: Path<TForm>;
  prefixValue: PathValue<TForm, Path<TForm>> | undefined;
  phoneValue: PathValue<TForm, Path<TForm>> | undefined;
  required: boolean;
  autoComplete?: string;
  errors: FieldErrors<TForm | any>;
}

const TKPhoneNumber = <TForm extends object>({
  control,
  phoneName,
  prefixName,
  errors,
  phoneValue,
  prefixValue,
  required = true,
  autoComplete = 'tel-local',
}: Props<TForm>) => {
  const { t } = useTranslation();
  const countries = useCountriesTranslation();

  const prefixController = useController({
    name: prefixName,
    control,
    defaultValue: prefixValue,
    rules: {
      required: {
        value: required,
        message: t('validations.phone.required'),
      },
    },
  });

  const prefixDisplayValue =
    countries.find(
      (country) => country.phonePrefix === prefixController.field.value
    ) || null;

  return (
    <div className="space-y-2">
      <Label htmlFor={phoneName}>{t('validations.phone.label')}</Label>
      <div className="flex gap-1">
        <FormField
          control={control}
          name={prefixName}
          rules={{
            required: {
              value: required,
              message: t('validations.phone.required'),
            },
          }}
          render={({ field }) => (
            <div>
              <SelectCountry
                onChange={(option) => field.onChange(option?.phonePrefix)}
                value={prefixDisplayValue}
                error={errors[prefixName]?.message?.toString?.() ?? ''}
                defaultValue={field.value}
              />
            </div>
          )}
        />

        <FormField
          control={control}
          name={phoneName}
          rules={{
            required: {
              value: required,
              message: t('validations.phone.required'),
            },
            pattern: {
              message: t('validations.phone.pattern'),
              value: PHONE_REGEX,
            },
          }}
          render={({ field }) => (
            <div className="flex-1 relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <TypographySmall>
                  {prefixController.field.value}
                </TypographySmall>
              </div>
              <Input
                {...field}
                id={phoneName}
                type="tel"
                autoComplete={autoComplete}
                defaultValue={phoneValue}
                className="pl-12"
              />
            </div>
          )}
        />
      </div>
      {(errors?.[phoneName]?.message || errors?.[prefixName]?.message) && (
        <TypographySmall className="text-destructive">
          {errors[phoneName]?.message?.toString() ||
            errors[prefixName]?.message?.toString()}
        </TypographySmall>
      )}
    </div>
  );
};

export default TKPhoneNumber;
