import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDebounce } from '@/hooks/common/useDebounce';
import { useAuth } from '@/contexts/Global/AuthContext';
import { toast } from 'sonner';
import { useCompany } from '@/hooks/auth/useCompany';
import { trpc } from '@/api/trpc';
import { EquipmentTypeEnum, EquipmentFuelTypeEnum } from '@/types/equipment';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@/components/ui/form';
import { AutoComplete } from '@/components/ui/autocomplete';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import { SelectMultipleGroups } from '@/components/FormControls/MultipleSelect';
import EquipmentTypeField from '@/components/Equipment/EquipmentTypeField';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface EquipmentFormData {
  type: EquipmentTypeEnum;
  fuelType: EquipmentFuelTypeEnum;
  serialNumber: string;
  groupIds: string[];
  teltonikaImei: string | null;
  dpiotId: string | null;
}

function CreateEquipmentDialog({ open, setOpen }: Props) {
  const company = useCompany();
  const { companyId } = useAuth();
  const { t } = useTranslation();

  const form = useForm<EquipmentFormData>({
    defaultValues: {
      type: EquipmentTypeEnum.TRUCK,
      serialNumber: '',
      groupIds: [],
      fuelType: EquipmentFuelTypeEnum.DIESEL,
    },
  });
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const { isFetching, data, isError } =
    trpc.statensVegvesen.searchVehicle.useQuery(
      {
        companyId,
        vinOrRegistrationNo: debouncedSearch,
      },
      {
        enabled: !!debouncedSearch,
        refetchOnWindowFocus: false,
        retry: false,
      }
    );
  const options = data
    ? [{ label: data.registrationNumber, value: data.serialNumber }]
    : [];

  const createMutation = trpc.equipment.insert.useMutation({
    onSuccess() {
      toast.success(
        t('common.api.insert.success', {
          type: t('equipment.equipment'),
        })
      );
      closeDialog();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  function closeDialog() {
    setSearch('');
    form.reset();
    setOpen(false);
  }
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('equipment.addCustomEquipment')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((values) =>
              createMutation.mutate({
                ...values,
                companyId,
                teltonikaImei: values.teltonikaImei || null,
                dpiotId: values.dpiotId || null,
              })
            )}
          >
            <div className="space-y-4">
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('equipment.type')}</FormLabel>
                    <Select
                      onValueChange={(value) => {
                        if (
                          value === EquipmentTypeEnum.TRUCK ||
                          value === EquipmentTypeEnum.CAR
                        ) {
                          form.setValue('serialNumber', '');
                        }
                        field.onChange(value);
                      }}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={t('equipment.type')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={EquipmentTypeEnum.TRUCK}>
                          <EquipmentTypeField type={EquipmentTypeEnum.TRUCK} />
                        </SelectItem>
                        <SelectItem value={EquipmentTypeEnum.MACHINE}>
                          <EquipmentTypeField
                            type={EquipmentTypeEnum.MACHINE}
                          />
                        </SelectItem>
                        <SelectItem value={EquipmentTypeEnum.TRAILER}>
                          <EquipmentTypeField
                            type={EquipmentTypeEnum.TRAILER}
                          />
                        </SelectItem>
                        <SelectItem value={EquipmentTypeEnum.TRAILER}>
                          <EquipmentTypeField
                            type={EquipmentTypeEnum.TRAILER}
                          />
                        </SelectItem>
                        <SelectItem value={EquipmentTypeEnum.CAR}>
                          <EquipmentTypeField type={EquipmentTypeEnum.CAR} />
                        </SelectItem>
                        <SelectItem value={EquipmentTypeEnum.CONTAINER}>
                          <EquipmentTypeField
                            type={EquipmentTypeEnum.CONTAINER}
                          />
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="serialNumber"
                rules={{
                  required: {
                    value: true,
                    message: t('validations.required'),
                  },
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('validations.vin.label')}</FormLabel>
                    <FormControl>
                      {form.watch('type') === EquipmentTypeEnum.TRUCK ||
                      form.watch('type') === EquipmentTypeEnum.CAR ? (
                        <AutoComplete
                          isLoading={isFetching}
                          isError={isError}
                          isFetching={isFetching}
                          emptyMessage={t('common.noDataMatchingFilters')}
                          options={options}
                          value={{
                            label: field.value,
                            value: field.value,
                          }}
                          onSearch={(val) => {
                            setSearch(val);
                            field.onChange(val);
                          }}
                          searchValue={search}
                          onValueChange={(opt) => {
                            field.onChange(opt.value || '');
                          }}
                        />
                      ) : (
                        <Input
                          {...field}
                          placeholder={t('validations.serialNumber.label')}
                          data-testid="input-custom-equipment-name"
                        />
                      )}
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="teltonikaImei"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('validations.teltonikaImei.label')}
                    </FormLabel>
                    <FormControl>
                      <Input {...field} data-testid="input-teltonika-imei" />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="dpiotId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('validations.dpiotId.label')}</FormLabel>
                    <FormControl>
                      <Input {...field} data-testid="input-dpiot-imei" />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="fuelType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('equipment.columns.fuelType')}</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={t('equipment.columns.fuelType')}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Object.values(EquipmentFuelTypeEnum).map(
                          (fuelType) => (
                            <SelectItem key={fuelType} value={fuelType}>
                              {`${t(`equipment.${fuelType}`)}`.toUpperCase()}
                            </SelectItem>
                          )
                        )}
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />

              {company.data?.featureEnableGroups && (
                <FormField
                  control={form.control}
                  name="groupIds"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('company.groups.base_other')}</FormLabel>
                      <FormControl>
                        <SelectMultipleGroups
                          values={field.value}
                          onChange={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              )}
            </div>

            <DialogFooter className="mt-6">
              <TKConfirmButton
                dialogType="create"
                isLoading={createMutation.isLoading}
                disabled={createMutation.isLoading}
                type="submit"
                data-testid="update-equipment-button"
              />
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateEquipmentDialog;
