import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import { Switch } from '@/components/ui/switch';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { useAuth } from '@/contexts/Global/AuthContext';
import { removeEmptyFields } from '@/utils/common/removeEmptyFields';
import { EMAIL_REGEX, PHONE_REGEX } from '@/utils/common/validators';
import { SelectMultipleUnlinkedDriverCards } from '@/components/FormControls/MultipleSelect';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import upperFirst from 'lodash/upperFirst';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  driverId: number;
}

interface EditDriverForm {
  id: number;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  driverCardIds: string[];
  active: boolean;
}

function UpdateDriverFormDialog({
  open,
  setOpen,
  driverId,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const { companyId } = useAuth();
  const driver = trpc.driver.byId.useQuery(
    { id: driverId, companyId },
    {
      enabled: open,
    }
  );
  const form = useForm<EditDriverForm>({
    values: driver?.data
      ? {
          id: driver.data.id,
          email: driver.data.email,
          firstName: driver.data.firstName,
          lastName: driver.data.lastName,
          phone: driver.data.phone,
          driverCardIds: driver.data.driverCards.map((card) => card.id),
          active: driver.data.active,
        }
      : undefined,
  });

  const updateMutation = trpc.driver.update.useMutation({
    onSuccess() {
      toast.success(
        upperFirst(t('common.api.update.success', { type: t('drivers.base') }))
      );
      form.reset();
      setOpen(false);
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t(`drivers.updateDriverDialog.title`)}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((values) => {
              updateMutation.mutate({
                ...removeEmptyFields(values),
                companyId,
              });
            })}
            className="space-y-4"
          >
            <FormField
              control={form.control}
              name="driverCardIds"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('drivers.tachoDriverIds')}</FormLabel>
                  <FormControl>
                    <SelectMultipleUnlinkedDriverCards
                      excludeLinkedDriverId={driverId}
                      values={(field.value as unknown as string[]) || []}
                      onChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="firstName"
                rules={{ required: t('validations.firstname.required') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('validations.firstname.label')}</FormLabel>
                    <FormControl>
                      <Input {...field} value={field.value ?? ''} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                rules={{ required: t('validations.lastname.required') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('validations.lastname.label')}</FormLabel>
                    <FormControl>
                      <Input {...field} value={field.value ?? ''} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="phone"
              rules={{
                pattern: {
                  value: PHONE_REGEX,
                  message: t('validations.phone.pattern'),
                },
              }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('validations.phone.label')}</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value ?? ''} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              rules={{
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('validations.email.pattern'),
                },
              }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('validations.email.label')}</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value ?? ''} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="active"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>{t('drivers.active')}</FormLabel>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => setOpen(false)}
              >
                {t('common.cancelText')}
              </Button>
              <TKConfirmButton
                dialogType="update"
                isLoading={updateMutation.isLoading}
                disabled={updateMutation.isLoading}
                data-testid="confirm-driver"
              />
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
export default UpdateDriverFormDialog;
