import { CompareFiltersType } from './CompareFilters';
export const comparisons = [
  'total',
  'equipment',
  'drivers',
  'projects',
] as const;

export type FilterComparisons = (typeof comparisons)[number];

export const getActiveComparison = (filters: CompareFiltersType | null) => {
  if (filters === null) return 'total';

  let comparison: FilterComparisons = 'total';
  if (
    filters?.groupDrivers &&
    filters?.groupEquipment &&
    filters?.groupProjects
  ) {
    comparison = 'total';
  } else if (!filters?.groupDrivers) {
    comparison = 'drivers';
  } else if (!filters?.groupEquipment) {
    comparison = 'equipment';
  } else if (!filters?.groupProjects) {
    comparison = 'projects';
  }
  return comparison;
};
