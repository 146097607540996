import { trpc } from '@/api/trpc';
import { TokenResult, useAuth } from '@/contexts/Global/AuthContext';
import { signInWithCustomToken } from 'firebase/auth';
import { firebaseAuth } from '@/lib/firebaseClient';

export function useLoginIntoCompany() {
  const signInMutation = trpc.auth.signIn.useMutation();
  const auth = useAuth();

  async function loginIntoCompany(companyId: number) {
    const data = await signInMutation.mutateAsync({ companyId });
    const newUser = await signInWithCustomToken(firebaseAuth, data.token);

    const result = await newUser.user.getIdTokenResult();
    const claims = result.claims as unknown as TokenResult['claims'];
    auth.handleSetClaims(claims);

    return { companyId, claims };
  }

  return {
    loginIntoCompany,
  };
}
