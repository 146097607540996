import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { RouterInputs, RouterOutputs, trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@/components/ui/form';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';

import { SelectMultipleCompanies } from '@/components/FormControls/MultipleSelect';

type Config = RouterOutputs['remoteConfig']['sysAdminList'][number];
type RemoteConfigFormData = RouterInputs['remoteConfig']['updateByParameter'];

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  values: Config;
}

function RemoteConfigFormDialog({ values, open, setOpen }: Props) {
  const { t } = useTranslation();
  const form = useForm<RemoteConfigFormData>({
    values: values
      ? {
          ...values,
          companyIds: values.companies.map((company) => company.id),
        }
      : undefined,
  });

  const updateMutation = trpc.remoteConfig.updateByParameter.useMutation({
    onSuccess() {
      toast.success(
        t(`common.api.update.success`, {
          type: t(`sysAdmin.remoteConfig.types.${values.parameter}`),
        })
      );
      form.reset();
      setOpen(false);
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  return (
    <Dialog
      open={open}
      onOpenChange={(newOpenState) => {
        setOpen(false);
        if (!newOpenState) {
          form.reset();
        }
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t(`sysAdmin.remoteConfig.types.${values.parameter}`)}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) => {
              updateMutation.mutate({
                parameter: data.parameter,
                companyIds: data.companyIds,
              });
            })}
          >
            <div className="space-y-4 min-h-[200px] overflow-hidden">
              <FormField
                control={form.control}
                name="companyIds"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('sysAdmin.remoteConfig.companies')}
                    </FormLabel>
                    <FormControl>
                      <SelectMultipleCompanies
                        onChange={(values) => {
                          field.onChange(values);
                        }}
                        values={field.value ?? []}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <DialogFooter className="mt-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  setOpen(false);
                }}
                disabled={updateMutation.isLoading}
              >
                {t('common.cancelText')}
              </Button>
              <TKConfirmButton
                dialogType={'update'}
                isLoading={updateMutation.isLoading}
                disabled={updateMutation.isLoading}
                data-testid="confirm-company-notification"
              />
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default RemoteConfigFormDialog;
