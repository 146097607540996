import React, { useState } from 'react';
import { trpc, RouterOutputs } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useTranslation } from 'react-i18next';
import TKWidgetHeader from '@/components/WidgetSystem/TKWidgetHeader';
import WidgetDataHandler from '@/components/WidgetSystem/WidgetDataHandler';
import HomepageWidgetHeader from '../HomepageWidgetHeader';
import { useExportDataTable } from '@/hooks/common/useExportDataTable';
import TKChartContainer from '@/components/Charts/TKChartContainer';
import { DataTable } from '@/components/Table/data-table';
import { HomepageWidgetBaseCard } from '../HomepageWidgetBaseCard';
import { LinkCreateDriverForm } from './LinkCreateDriverForm';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { toast } from 'sonner';
import { Trash2Icon } from 'lucide-react';

type DriverCardRow =
  RouterOutputs['widget']['executeDetectedDriverCard'][number];
function DetectedDriverCardReport(): JSX.Element {
  const [assignRow, setAssigningRow] = useState<DriverCardRow | null>(null);
  const [deleteRow, setDeletingRow] = useState<DriverCardRow | null>(null);
  function handleDialogClose(open: boolean) {
    if (!open) {
      setAssigningRow(null);
    }
  }

  const { companyId } = useAuth();
  const { t } = useTranslation();
  const {
    data = [],
    isLoading,
    error,
  } = trpc.widget.executeDetectedDriverCard.useQuery({
    companyId,
  });
  const title = t('analysis.reports.new_driver_detected.title');

  const { exportToCsv, exportToExcel } = useExportDataTable(
    data,
    [
      {
        id: 'tachoDriverId',
        title: t('analysis.reports.new_driver_detected.tacho_driver_id'),
      },
      {
        id: 'firstName',
        title: t('analysis.reports.new_driver_detected.name'),
        render(row) {
          return [row.firstName, row.lastName].filter(Boolean).join(' ');
        },
      },
    ],
    `${title}`
  );

  const rejectMutation = trpc.driver.driverCardReject.useMutation({
    onSuccess: () => {
      toast.success(t('drivers.successDeletingDriverCard'));
      setDeletingRow(null);
    },
    onError: (error) => {
      toast.error(t('drivers.errorDeletingDriverCard'), {
        description: error.message,
      });
    },
  });

  return (
    <HomepageWidgetBaseCard>
      <TKWidgetHeader title={title}>
        <HomepageWidgetHeader
          handleExportToCsv={exportToCsv}
          handleExportToExcel={exportToExcel}
          loading={isLoading}
        />
      </TKWidgetHeader>
      <WidgetDataHandler data={data} loading={isLoading} error={error}>
        <TKChartContainer>
          <DataTable
            isLoading={isLoading}
            error={error}
            wrapperClassName="p-0 border-none"
            columns={[
              {
                id: 'tachoDriverId',
                title: t(
                  'analysis.reports.new_driver_detected.tacho_driver_id'
                ),
                enableSorting: true,
              },
              {
                id: 'firstName',
                title: t('analysis.reports.new_driver_detected.name'),
                enableSorting: true,
                render(row) {
                  return [row.firstName, row.lastName]
                    .filter(Boolean)
                    .join(' ');
                },
              },
              {
                id: 'driverCardId',
                title: '',
                render: (row) => (
                  <div className="flex gap-x-1 justify-end">
                    <Button
                      onClick={() => {
                        setAssigningRow(row);
                      }}
                      size="sm"
                    >
                      {t('drivers.assignToDriver')}
                    </Button>
                    <Button
                      onClick={() => {
                        setDeletingRow(row);
                      }}
                      size="sm"
                      variant="destructive"
                    >
                      <Trash2Icon className="size-4" />
                    </Button>
                  </div>
                ),
              },
            ]}
            data={data}
          />
        </TKChartContainer>
      </WidgetDataHandler>

      <Dialog open={!!deleteRow} onOpenChange={() => setDeletingRow(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('drivers.driverCard')}</DialogTitle>
            <DialogDescription>
              {t('drivers.deleteDescription')}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setDeletingRow(null)}>
              {t('common.cancelText')}
            </Button>
            <Button
              isLoading={rejectMutation.isLoading}
              onClick={() => {
                if (deleteRow) {
                  rejectMutation.mutate({
                    id: deleteRow.driverCardId,
                    companyId,
                  });
                }
              }}
            >
              {t('common.delete')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <LinkCreateDriverForm
        open={!!assignRow}
        setOpen={handleDialogClose}
        driverCardId={assignRow?.driverCardId ?? ''}
        tachoDriverId={assignRow?.tachoDriverId ?? ''}
      />
    </HomepageWidgetBaseCard>
  );
}

export default DetectedDriverCardReport;
