import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { toast } from 'sonner';
import { createUrl } from '@/routes/routesList';
import TKTypedLink from '@/components/Common/TKTypedLink';
import AuthLayout from '@/components/Layouts/AuthLayout';
import { endpoints } from '@/constants/configs';
import { firebaseAuth } from '@/lib/firebaseClient';
import { useFirebaseChecks } from '@/hooks/auth/useFirebaseChecks';
import { useRouter } from '@/hooks/common/useRouter';
import { sendPasswordResetEmail } from 'firebase/auth';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Spinner } from '@/components/ui/spinner';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { TypographyH4 } from '@/components/ui/typography';

const FormSchema = z.object({
  email: z.string().email(),
});

const AuthResetPassword: React.FC = () => {
  const { isLogged } = useFirebaseChecks();

  const { t } = useTranslation();
  const [wasEmailSent, setWasEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    if (isLogged) {
      router.replace('/', {});
    }
  }, [isLogged, router]);

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    try {
      setLoading(true);
      const RETURN_URL_AFTER_RESET =
        endpoints.domain + createUrl('/auth-login', {});

      await sendPasswordResetEmail(firebaseAuth, data.email, {
        url: RETURN_URL_AFTER_RESET,
      });

      setWasEmailSent(true);
    } catch (error) {
      toast.error(t('login.resetPassword.error'));
      setWasEmailSent(false);
    } finally {
      setLoading(false);
    }
  };

  const email = form.watch('email');
  useEffect(() => {
    setWasEmailSent(false);
  }, [email]);

  return (
    <AuthLayout>
      <div className="flex flex-col space-y-6 w-full max-w-md">
        <div className="flex flex-col space-y-2 text-center">
          <TypographyH4>{t('login.resetPassword.subtitle')}</TypographyH4>
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('validations.email.label')}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="name@example.com"
                      type="email"
                      autoCapitalize="none"
                      autoComplete="email"
                      autoCorrect="off"
                      disabled={loading}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {wasEmailSent && (
              <p className="text-sm text-muted-foreground">
                <Trans
                  values={{ email }}
                  i18nKey="login.resetPassword.sentSuccessMessage"
                  components={{ bold: <strong />, br: <br /> }}
                />
              </p>
            )}

            <Button type="submit" disabled={loading}>
              {loading && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
              {t('login.resetPassword.reset')}
            </Button>
          </form>
        </Form>

        <Button variant="outline" asChild>
          <TKTypedLink to="/auth-login" params={{}}>
            {t('login.resetPassword.goToLoginPage')}
          </TKTypedLink>
        </Button>
      </div>
    </AuthLayout>
  );
};

export default AuthResetPassword;
