import { useDateFns } from '@/hooks/common/useDateFns';
import FileSaver from 'file-saver';

type FormatAndDownloadArgs = {
  content: string;
  name: string;
  extension: string;
  date: string;
};

const useFormatAndDownloadFile = () => {
  const { format } = useDateFns();
  return ({ content, date, extension, name }: FormatAndDownloadArgs) => {
    const generatedAt = format(new Date(date), 'Pp');

    let fileName = `${name}___${generatedAt}.${extension.toLowerCase()}`;
    // replace non-alphanumerical characters with underscores
    fileName = fileName.replace(/[^a-zA-Z0-9]/g, '_');

    FileSaver.saveAs(content, fileName, { autoBom: true });
  };
};

export { useFormatAndDownloadFile };
