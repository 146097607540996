import React from 'react';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/api/trpc';
import { useDateFns } from '@/hooks/common/useDateFns';
import { useAuth } from '@/contexts/Global/AuthContext';
import { TypographyP, TypographySmall } from '@/components/ui/typography';
import { Badge } from '@/components/ui/badge';
import { AlertCircle, CheckCircle } from 'lucide-react';

interface Props {
  type: string;
  clientId: string;
  configId: number;
}

function IntegrationStatusList({
  configId,
  type,
  clientId,
}: Props): JSX.Element {
  const { companyId } = useAuth();
  const { format } = useDateFns();
  const { t } = useTranslation();

  const statusQuery = trpc.integration.statusHistoryById.useQuery(
    { configId, companyId },
    {
      staleTime: 5000,
    }
  );

  function parseAndStringifyStatus(status: string) {
    function parseStatus(status: string): string {
      try {
        const parsed = JSON.parse(JSON.parse(status));
        return JSON.stringify(parsed, null, 2);
      } catch {
        return status;
      }
    }

    return parseStatus(status);
  }

  return (
    <div className="space-y-4">
      <div>
        <TypographySmall>
          {t('company.integrations.detailsList.idTitle')}
        </TypographySmall>
        <TypographyP>{clientId}</TypographyP>
      </div>

      <div>
        <TypographySmall>
          {t('company.integrations.detailsList.typeTitle')}
        </TypographySmall>
        <TypographyP>{type}</TypographyP>
      </div>

      <div>
        <TypographySmall>
          {t('company.integrations.detailsList.statusTitle')}
        </TypographySmall>
        <div className="mt-2 space-y-3">
          {statusQuery.data && statusQuery.data.length > 0 ? (
            statusQuery.data.map((status) => (
              <div key={status.id} className="flex space-x-1 items-center">
                {status.hasError ? (
                  <AlertCircle className="size-4 text-destructive mt-0.5" />
                ) : (
                  <CheckCircle className="size-4 text-success mt-0.5" />
                )}
                <div className="flex-grow">
                  <div className="flex items-center space-x-2">
                    <TypographySmall className="font-medium">
                      {status.workerName}
                    </TypographySmall>
                    <Badge
                      variant={status.hasError ? 'destructive' : 'secondary'}
                    >
                      {status.hasError
                        ? t('company.integrations.detailsList.statusError')
                        : t('company.integrations.detailsList.statusOk')}
                    </Badge>
                    <TypographySmall className="text-muted-foreground">
                      {format(new Date(status.createdAt), 'Pp')}
                    </TypographySmall>
                  </div>
                  {status.hasError && !!status.status && (
                    <pre className="text-destructive mt-1">
                      {parseAndStringifyStatus(status.status)}
                    </pre>
                  )}
                </div>
              </div>
            ))
          ) : (
            <TypographyP>-</TypographyP>
          )}
        </div>
      </div>
    </div>
  );
}

export default IntegrationStatusList;
