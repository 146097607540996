import { Card, CardContent } from '@/components/ui/card';

export function HomepageWidgetBaseCard({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Card
      className="w-full h-[45vh] overflow-y-hidden flex flex-1 flex-col"
      data-testid="widget-component-item"
    >
      <CardContent className="p-2 flex flex-1 flex-col overflow-x-hidden overflow-y-hidden">
        {children}
      </CardContent>
    </Card>
  );
}
