import React from 'react';
import { useTranslation } from 'react-i18next';
import TKTextWithLabel from '@/components/Common/TKTextWithLabel';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import capitalize from 'lodash/capitalize';
import { useDateFns } from '@/hooks/common/useDateFns';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { PlusIcon } from 'lucide-react';

interface Props {
  equipmentId: string;
}

function RegistrationSection({ equipmentId }: Props) {
  const { companyId } = useAuth();
  const { t } = useTranslation();
  const { format } = useDateFns();
  const registrationQuery = trpc.statensVegvesen.registrationStatus.useQuery({
    equipmentId,
    companyId,
  });

  const data = registrationQuery?.data;

  const registrationNorway = data?.firstTimeRegisteredInNorway;
  const registration = data?.firstTimeRegisteredOnOwner;

  const regStatus = data?.statusName
    ? capitalize(data.statusName)
    : t('common.notAvailable');

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <PlusIcon />
          {t('equipment.columns.vehicleRegistration')}
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4 flex flex-col">
        <TKTextWithLabel
          label={t('equipment.registrationNumber')}
          text={data?.registrationNumber}
        />
        <TKTextWithLabel
          label={t('equipment.columns.registrationDateNorway')}
          text={
            registrationNorway
              ? format(new Date(registrationNorway), 'P')
              : t('common.notAvailable')
          }
        />
        <TKTextWithLabel
          label={t('equipment.columns.registrationDate')}
          text={
            registration
              ? format(new Date(registration), 'P')
              : t('common.notAvailable')
          }
        />
        <TKTextWithLabel
          label={t('equipment.columns.statusOfRegistration')}
          text={regStatus}
        />
      </CardContent>
    </Card>
  );
}

export default RegistrationSection;
