import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@/components/ui/form';

import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import { DashboardForm } from '@/types/analysis';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useCompany } from '@/hooks/auth/useCompany';
import { SelectMultipleCompanyUsers } from '@/components/FormControls/MultipleSelect';

interface Props {
  id: number;
  open: boolean;
  setOpen: (value: boolean) => void;
  onUpdate?: (id: number | null) => void;
}

function AnalysisUpdateFormDialog({ id, open, setOpen, onUpdate }: Props) {
  const { t } = useTranslation();

  const { companyId, isOwner, companyUserId: currentCompanyUserId } = useAuth();
  const { data: companyData } = useCompany();

  const query = trpc.analysis.byId.useQuery({
    id,
    companyId,
  });

  const form = useForm<DashboardForm>({
    defaultValues: {
      name: '',
      isShareable: false,
      sharedWithCompanyUserIds: [],
    },
  });

  // sync form with query data as soon as it loads
  useEffect(() => {
    if (query.data) {
      form.reset(query.data);
    }
  }, [query.data, form]);

  const updateMutation = trpc.analysis.updateById.useMutation({
    onSuccess(data) {
      toast.success(
        t('common.api.update.success', { type: t('analysis.base') })
      );
      if (onUpdate) {
        onUpdate(data.id);
      }
      closeDialog();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  function closeDialog() {
    setOpen(false);
    form.reset(query.data);
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('analysis.updateDashboardDialog.title')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) =>
              updateMutation.mutate({
                id,
                companyId,
                name: data.name,
                isShareable: data.isShareable ?? false,
                sharedWithCompanyUserIds: data.sharedWithCompanyUserIds,
              })
            )}
            className="space-y-4"
          >
            <FormField
              control={form.control}
              name="name"
              rules={{
                required: {
                  value: true,
                  message: t('validations.dashboardName.required'),
                },
              }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('validations.dashboardName.label')}</FormLabel>
                  <FormControl>
                    <Input {...field} autoFocus autoComplete="off" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {isOwner && (
              <FormField
                control={form.control}
                name="isShareable"
                render={({ field }) => (
                  <FormItem className="flex items-center space-x-2">
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel>
                      {companyData?.isTemplateCompany
                        ? t(
                            'analysis.dashboardForm.shareableDashboardWithOtherCompanies'
                          )
                        : t('analysis.dashboardForm.shareableDashboard')}
                    </FormLabel>
                  </FormItem>
                )}
              />
            )}

            {form.watch('isShareable') && (
              <FormField
                control={form.control}
                name="sharedWithCompanyUserIds"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <SelectMultipleCompanyUsers
                        values={field.value}
                        collapseAt={3}
                        onChange={field.onChange}
                        excludeCompanyUserId={currentCompanyUserId}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            )}

            <DialogFooter>
              <Button type="button" variant="outline" onClick={closeDialog}>
                {t('common.cancelText')}
              </Button>
              <TKConfirmButton
                dialogType="update"
                isLoading={updateMutation.isLoading}
                disabled={updateMutation.isLoading}
                data-testid="confirm-dashboard"
              />
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default AnalysisUpdateFormDialog;
