import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Role } from '@/types/common/roles';
import { CompanyUserFormData } from '@/types/company/users';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { useAuth } from '@/contexts/Global/AuthContext';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  defaultValues: CompanyUserFormData;
}
const ALLOWED_ROLES: Role[] = [Role.Owner, Role.Manager];

function UpdateCompanyUserFormDialog({ open, defaultValues, setOpen }: Props) {
  const { companyId } = useAuth();

  const form = useForm<CompanyUserFormData>({
    mode: 'onChange',
    defaultValues: defaultValues ?? { role: Role.Owner },
  });
  const { t } = useTranslation();

  const updateMutation = trpc.companyUser.update.useMutation({
    onSuccess() {
      toast.success(
        t('common.api.update.success', { type: t('company.users.base') })
      );
      form.reset();
      closeDialog();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  function closeDialog() {
    setOpen(false);
  }
  return (
    <Dialog open={open} onOpenChange={(isOpen) => !isOpen && closeDialog()}>
      <DialogContent className="sm:max-w-[425px]">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) => {
              updateMutation.mutate({
                id: Number(defaultValues.id),
                role: data.role,
                companyId,
              });
            })}
            className="space-y-4"
          >
            <DialogTitle>
              {t('company.users.updateUserDialog.title')}
            </DialogTitle>

            <FormField
              control={form.control}
              name="role"
              rules={{ required: true }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('company.users.table.role')}</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue
                          placeholder={t('company.users.table.role')}
                        />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {ALLOWED_ROLES.map((role) => (
                        <SelectItem value={role} key={`select_${role}`}>
                          {t(`shared-roles.${role}`)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button type="button" variant="outline" onClick={closeDialog}>
                {t('common.cancelText')}
              </Button>
              <TKConfirmButton
                dialogType="update"
                isLoading={updateMutation.isLoading}
                data-testid="confirm-user"
              />
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
export default UpdateCompanyUserFormDialog;
