import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreHorizontal } from 'lucide-react';
import { useDialog } from '@/contexts/Global/Dialog/DialogContext';
import { Role } from '@/types/common/roles';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import UpdateCompanyUserFormDialog from './UpdateCompanyUserFormDialog';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

interface Props {
  id: number;
  companyId: number;
  email: string;
  fullName: string;
  role: Role;
}

function CompanyUserActionsMenu({
  id,
  email,
  fullName,
  role,
  companyId,
}: Props): JSX.Element | null {
  const { t } = useTranslation();

  const dialog = useDialog();

  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  const deleteMutation = trpc.companyUser.deleteById.useMutation({
    onSuccess() {
      toast.success(
        t('common.api.delete.success', { type: t('company.users.base') })
      );
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  function handleOpenDialog() {
    setUpdateDialogOpen(true);
  }

  function handleDeleteUser() {
    dialog({
      title: t('company.users.deleteUserDialog.title', { name: fullName }),
      description: t('company.users.deleteUserDialog.description', {
        name: fullName,
      }),
      dialogType: 'delete',
    }).then(() => deleteMutation.mutate({ id, companyId }));
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="group opacity-0 transition-all group-hover/row:opacity-100"
            data-testid={`company-user-${email}-actions-menu`}
          >
            <MoreHorizontal className="h-4 w-4" />
            <span className="sr-only">Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuItem
            onClick={handleOpenDialog}
            data-testid={`company-user-options-${email}-update-menu`}
          >
            {t('company.users.updateUser')}
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={handleDeleteUser}
            data-testid={`company-user-options-${email}-delete-menu`}
          >
            {t('company.users.deleteUser')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {updateDialogOpen && (
        <UpdateCompanyUserFormDialog
          open={updateDialogOpen}
          setOpen={setUpdateDialogOpen}
          defaultValues={{
            email,
            id,
            role,
          }}
        />
      )}
    </>
  );
}

export default CompanyUserActionsMenu;
