import React from 'react';
import { useTranslation } from 'react-i18next';
import { LibraryCategoryType } from '.';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { TypographySmall } from '@/components/ui/typography';

type LibraryCategoriesSelectProps = {
  setCategoryId: React.Dispatch<React.SetStateAction<string>>;
  categoryId: string;
  categories: LibraryCategoryType[];
};

const LibraryCategoriesSelect: React.FC<LibraryCategoriesSelectProps> = ({
  categoryId,
  setCategoryId,
  categories,
}) => {
  const { t } = useTranslation();

  return (
    <div className="p-2 mt-4 space-y-2">
      <TypographySmall className="uppercase">
        {t('validations.reportCategory.label')}
      </TypographySmall>
      <Select value={categoryId} onValueChange={setCategoryId}>
        <SelectTrigger className="w-full">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          {categories.map((item) => (
            <SelectItem key={`add_library_category_${item.id}`} value={item.id}>
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default LibraryCategoriesSelect;
