import React from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from '@/lib/utils';

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';

import { languages } from '@/lib/i18nInstance';
import CountryImageFlag from '../Common/CountryImageFlag';

type TKSelectLanguageProps = {
  darkBackground?: boolean;
  onLanguageChange?: (langCode?: string) => void;
  className?: string;
};

/**
 * Displays a dropdown component that allows users to change their preferred language in TK Dashboard.
 *
 */
const TKSelectLanguage: React.FC<TKSelectLanguageProps> = ({
  darkBackground = false,
  onLanguageChange,
  className,
}) => {
  const { i18n } = useTranslation();

  const handleChangeLanguage = async (newCode: string) => {
    if (onLanguageChange) onLanguageChange(newCode);
    await i18n.changeLanguage(newCode);
  };

  // Generate all the available languages options
  const options = languages.map((language) => {
    return {
      value: language.code,
      label: language.code.toUpperCase(),
      fullLabel: language.fullName,
      flagCode: language.region,
    };
  });

  const selectedOption = options.find(
    (option) => option.value === i18n.language
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant={darkBackground ? 'secondary' : 'default'}
          size="sm"
          className={cn('flex items-center ring-0 outline-none', className)}
          data-testid="language-menu"
        >
          {selectedOption && (
            <span className="flex flex-col items-center">
              <CountryImageFlag alphaCode={selectedOption?.flagCode} />
              {selectedOption.flagCode}
            </span>
          )}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="min-w-16">
        {options.map((option) => (
          <DropdownMenuItem
            key={`${option.value}`}
            onSelect={() => handleChangeLanguage(option.value)}
            className="flex items-center space-x-1"
          >
            <CountryImageFlag alphaCode={option.flagCode} />
            <span className={cn(option.value === i18n.language && 'font-bold')}>
              {option.fullLabel}
            </span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default TKSelectLanguage;
