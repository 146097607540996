import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@/hooks/common/useRouter';
import AuthLayout from '@/components/Layouts/AuthLayout';
import { useFirebaseChecks } from '@/hooks/auth/useFirebaseChecks';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { firebaseAuth } from '@/lib/firebaseClient';
import { toast } from 'sonner';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import TKTypedLink from '@/components/Common/TKTypedLink';
import { TypographyH4 } from '@/components/ui/typography';
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  PASSWORD_REGEX,
} from '@/utils/common/validators';

const FormSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8).max(100),
});

const AuthLogin: React.FC = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const { isLogged, emailVerified, hasProfileFinished } = useFirebaseChecks();

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  useEffect(() => {
    if (isLogged && hasProfileFinished) {
      router.replace('/companies', {});
    }

    if (isLogged && !hasProfileFinished && !emailVerified) {
      router.replace('/auth-signup', {});
    }

    const shouldFinishProfile = isLogged && !hasProfileFinished;

    if (shouldFinishProfile) {
      router.replace('/auth-finish-profile', {});
    }
  }, [emailVerified, hasProfileFinished, isLogged, router]);

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    try {
      setIsLoading(true);
      const { email, password } = data;
      await signInWithEmailAndPassword(firebaseAuth, email, password);
      toast.success(t('login.welcomeMessage'));
    } catch (error) {
      toast.error(t('login.emailPassword.error'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout>
      <div className="flex flex-col space-y-6 w-full max-w-md">
        <div className="flex flex-col space-y-2 text-center">
          <TypographyH4>{t('login.welcomeMessage')}</TypographyH4>
        </div>

        <div className={cn('grid gap-6')}>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              <FormField
                control={form.control}
                name="email"
                rules={{
                  required: t('validations.email.required'),
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: t('validations.email.pattern'),
                  },
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('validations.email.label')}</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="name@example.com"
                        type="email"
                        autoCapitalize="none"
                        autoComplete="email"
                        autoCorrect="off"
                        disabled={isLoading}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                rules={{
                  required: t('validations.password.required'),
                  minLength: {
                    value: MIN_PASSWORD_LENGTH,
                    message: t('validations.password.minLength', {
                      amount: MIN_PASSWORD_LENGTH,
                    }),
                  },
                  maxLength: {
                    value: MAX_PASSWORD_LENGTH,
                    message: t('validations.password.maxLength', {
                      amount: MAX_PASSWORD_LENGTH,
                    }),
                  },
                  pattern: {
                    value: PASSWORD_REGEX,
                    message: t('validations.password.pattern'),
                  },
                }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('validations.password.label')}</FormLabel>
                    <FormControl>
                      <Input
                        type="password"
                        autoCapitalize="none"
                        autoComplete="current-password"
                        autoCorrect="off"
                        disabled={isLoading}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" isLoading={isLoading}>
                {t('login.emailPassword.login')}
              </Button>
            </form>
          </Form>
          <div className="relative">
            <div className="absolute inset-0 flex items-center">
              <span className="w-full border-t" />
            </div>
            <div className="relative flex justify-center text-xs uppercase">
              <span className="bg-background px-2 text-muted-foreground">
                {t('login.or')}
              </span>
            </div>
          </div>
          <div className="grid gap-2">
            <Button variant="outline" asChild>
              <TKTypedLink to="/auth-reset-password" params={{}}>
                {t('login.authForm.goToResetPassword')}
              </TKTypedLink>
            </Button>
            <Button variant="outline" asChild>
              <TKTypedLink to="/auth-signup" params={{}}>
                {t('login.chooseLoginMethod.signup')}
              </TKTypedLink>
            </Button>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default AuthLogin;
