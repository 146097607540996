'use client';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Area,
  AreaChart as BaseAreaChart,
  XAxis,
  YAxis,
} from 'recharts';
import {
  eachMonthOfInterval,
  eachWeekOfInterval,
  eachDayOfInterval,
} from 'date-fns';
import { useDateFns } from '@/hooks/common/useDateFns';
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from '@/components/ui/chart';
import { DatasetGroups } from '@/hooks/analysis/useReportsDataAsChartDatasets';
import { mapDatasetToRechartsFormat } from '@/utils/common/mapDatasetToRechartsFormat';

interface Props {
  id: string | number | undefined;
  datasets: DatasetGroups;
  syncId: string | number | undefined;
  timeUnit: string | null | undefined;
  tooltipLabel: (value: number | null) => string;
  minTime?: string;
  maxTime?: string;
}

export function AreaChart({
  id,
  datasets,
  tooltipLabel,
  timeUnit,
  syncId,
  minTime,
  maxTime,
}: Props) {
  const { format } = useDateFns();
  const { t } = useTranslation();

  const recharts = useMemo(() => {
    return mapDatasetToRechartsFormat({
      datasets,
      timeUnit: timeUnit ?? 'day',
      minTime,
      maxTime,
    });
  }, [datasets, timeUnit, minTime, maxTime]);

  const formatXAxis = (timestamp: number) => {
    const date = new Date(timestamp);

    switch (timeUnit) {
      case 'day':
        return format(date, 'd MMM yyyy');
      case 'week':
        return `${t('analysis.week')} ${format(date, 'w')}`;
      case 'month':
        return format(date, 'MMM yyyy');
      default:
        return format(date, 'PP');
    }
  };

  const xAxisTicks = useMemo(() => {
    const start = new Date(recharts.minDate);
    const end = new Date(recharts.maxDate);
    let ticks: number[];

    switch (timeUnit) {
      case 'month':
        ticks = eachMonthOfInterval({ start, end }).map((date) =>
          date.getTime()
        );
        break;
      case 'week':
        ticks = eachWeekOfInterval({ start, end }).map((date) =>
          date.getTime()
        );
        break;
      case 'day':
        ticks = eachDayOfInterval({ start, end }).map((date) => date.getTime());
        break;
      default:
        ticks = recharts.data.map((item) => item.date);
    }

    return ticks;
  }, [timeUnit, recharts.data, recharts.minDate, recharts.maxDate]);

  return (
    <ChartContainer
      id={id?.toString()}
      config={recharts.chartConfig}
      className="w-full h-[340px]"
    >
      <BaseAreaChart
        accessibilityLayer
        syncId={syncId}
        data={recharts.data}
        margin={{
          left: 12,
          right: 12,
          top: 24,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="date"
          domain={[recharts.minDate, recharts.maxDate]}
          tickFormatter={formatXAxis}
          ticks={xAxisTicks}
          tickLine={false}
          axisLine={false}
          tickMargin={8}
        />
        <YAxis tickFormatter={tooltipLabel} width={40} />
        <ChartTooltip
          cursor={false}
          content={
            <ChartTooltipContent
              indicator="dot"
              nameKey="date"
              labelFormatter={(name, payload) => {
                const datePayload = payload.find(
                  (p) => p.name === name
                )?.payload;
                return datePayload ? formatXAxis(datePayload.date) : '';
              }}
            />
          }
        />

        {Object.keys(recharts.chartConfig).map((name) => (
          <Area
            key={name}
            type="natural"
            dataKey={name}
            strokeWidth={3}
            name={name}
            fill={recharts.chartConfig[name].color}
            fillOpacity={0.4}
            stroke={recharts.chartConfig[name].color}
            stackId="a"
          />
        ))}
        <ChartLegend content={<ChartLegendContent />} />
      </BaseAreaChart>
    </ChartContainer>
  );
}
