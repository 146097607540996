import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserProfile } from '@/hooks/auth/useUserProfile';
import { Checkbox } from '@/components/ui/checkbox';
import {
  FormField,
  FormItem,
  FormControl,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { NavLink } from 'react-router-dom';

const termsOfServiceURL = 'https://tkdashboard.no/brukervilkar/';
const privacyPolicyURL = 'https://tkdashboard.no/personvern/';

interface Props {
  form: UseFormReturn<UserProfile>;
}

function UserLegalConsent({ form }: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col my-4 space-y-4">
      <FormField
        control={form.control}
        name="hasConsentedTermsOfService"
        render={({ field }) => (
          <FormItem className="flex flex-row items-start space-x-3 space-y-0">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <div className="space-y-1 leading-none">
              <FormLabel>
                {t('legal.consent.iAgreeWith')}{' '}
                <NavLink to={termsOfServiceURL}>
                  {t('legal.termsOfService')}
                </NavLink>
              </FormLabel>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="hasConsentedPrivacyPolicy"
        render={({ field }) => (
          <FormItem className="flex flex-row items-start space-x-3 space-y-0">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <div className="space-y-1 leading-none">
              <FormLabel>
                {t('legal.consent.iAgreeWith')}{' '}
                <NavLink to={privacyPolicyURL}>
                  {t('legal.privacyPolicy')}
                </NavLink>
              </FormLabel>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
    </div>
  );
}

export default UserLegalConsent;
