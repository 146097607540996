import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import TKDataHandler from '@/components/Progress/TKDataHandler';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useReportsTemplatesList } from '@/hooks/analysis/useReportsTemplatesList';
import ReportWidgetTemplate from './ReportWidgetTemplate';
import { FiltersConfig } from '@/types/analysis';
import { useUpdateReportsPosition } from '@/hooks/analysis/useUpdateReportsPosition';
import TKDescriptiveProgress from '@/components/Progress/TKDescriptiveProgress';

import type { TKWidgetLayoutExtra } from '@/components/WidgetSystem/TKWidgetLayout';
const TKWidgetLayout = React.lazy(
  () => import('@/components/WidgetSystem/TKWidgetLayout')
);

interface Props {
  canMoveWidgets: boolean;
  isEditable: boolean;
  dashboardId: number;
  filters: FiltersConfig;
}
function AnalysisWidgets({
  canMoveWidgets,
  dashboardId,
  isEditable,
  filters,
}: Props): JSX.Element {
  const { companyId } = useAuth();
  const { t } = useTranslation();

  const { handleUpdateReportsPosition } = useUpdateReportsPosition({
    dashboardId,
    isEditable,
    canMoveWidgets,
  });

  const {
    data: reports = [],
    isLoading,
    error,
  } = trpc.analysisReport.list.useQuery({
    companyId,
    dashboardId,
  });

  const {
    data: reportTemplates = [],
    isLoading: templatesLoading,
    error: templatesError,
  } = useReportsTemplatesList();

  const widgets: TKWidgetLayoutExtra[] = [];
  for (const report of reports) {
    const template = reportTemplates.find(
      (template) => template.reportTypeId === report.type
    )!;
    if (template) {
      widgets.push({
        i: report.id.toString(),
        x: report.widgetXPosition,
        y: report.widgetYPosition,
        w: report.widgetWidth,
        h: report.widgetHeight,
        children: (
          <ReportWidgetTemplate
            dashboardId={dashboardId}
            reportId={report.id}
            template={template}
            isEditable={isEditable}
            filters={filters}
          />
        ),
      });
    }
  }

  return (
    <TKDataHandler
      loading={isLoading || templatesLoading}
      entryText={t('analysis.reports.base', { count: 2 })}
      error={error || templatesError}
      data={widgets}
      noDataDescription={t('analysis.noReportsDataFound')}
    >
      <Suspense fallback={<TKDescriptiveProgress />}>
        <TKWidgetLayout
          onLayoutChange={handleUpdateReportsPosition}
          layout={widgets}
          canMoveWidgets={canMoveWidgets}
        />
      </Suspense>
    </TKDataHandler>
  );
}
export default AnalysisWidgets;
