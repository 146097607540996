import React from 'react';
import { Redirect } from 'react-router-dom';
import { createUrl, Path, PathParams } from '@/routes/routesList';

type TypedRedirectProps<P extends Path, Q extends Path> = {
  to: P;
  params: PathParams<P>;
  push?: boolean;
  from?: Q;
};

/**
 * Type-safe version of `react-router-dom`'s Redirect component.
 *
 */
const TKTypedRedirect = <P extends Path, Q extends Path>({
  to,
  params,
  push,
  from,
}: TypedRedirectProps<P, Q>) => {
  return <Redirect to={createUrl(to, params)} push={push} from={from} />;
};

export default TKTypedRedirect;
