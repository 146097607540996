import { DateFiltersValue } from '@/components/FilterDropdowns/DateFilters/DateFilters';
import { differenceInDays, differenceInMonths } from 'date-fns';
import { ReportInterval } from '@/hooks/analysis/useReportsTemplatesList';
import { calculateFilterDates } from '@/utils/analysis/calculateFilterDates';

export const DEFAULT_INTERVAL_FILTER = ReportInterval.Month;

const useDetermineInterval = () => {
  return (filters: DateFiltersValue) => {
    let interval = filters?.interval || DEFAULT_INTERVAL_FILTER;

    const { toTime, fromTime } = calculateFilterDates(filters);

    if (filters.presetType === 'allTime') {
      return ReportInterval.Month;
    }

    // If all time is selected, keep the preferred interval;
    if (!fromTime || !toTime) {
      return interval;
    }

    const start = new Date(fromTime);
    const end = new Date(toTime);

    const monthsDiff = differenceInMonths(end, start);
    const daysDiff = differenceInDays(end, start);
    // Find the preferred interval
    if (monthsDiff >= 3) {
      interval = ReportInterval.Month;
    } else if (monthsDiff > 1 || daysDiff >= 30) {
      interval = ReportInterval.Week;
    } else {
      interval = ReportInterval.Day;
    }

    return interval;
  };
};

export { useDetermineInterval };
