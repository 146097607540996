interface WidgetItem {
  widgetXPosition: number;
  widgetYPosition: number;
  widgetWidth: number;
  widgetHeight: number;
}
export function findNextWidgetAvailableSpot(widgets: WidgetItem[]) {
  // Find the highest Y value;
  const highestY = widgets.reduce((result, current) => {
    return Math.max(result, current.widgetYPosition);
  }, 0);

  return {
    x: widgets.length % 2,
    y: highestY + 1,
  };
}
