import TKDataHandler, {
  TKDataHandlerProps,
} from '@/components/Progress/TKDataHandler';
import React from 'react';
import { useTranslation } from 'react-i18next';

const WidgetDataHandler: React.FC<Omit<TKDataHandlerProps, 'entryText'>> = ({
  data,
  children,
  ...restProps
}) => {
  const { t } = useTranslation();

  return (
    <TKDataHandler
      {...restProps}
      entryText={t('analysis.reports.base')}
      data={!data ? undefined : data}
    >
      {children}
    </TKDataHandler>
  );
};

export default WidgetDataHandler;
