import React, { useState } from 'react';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import { useTranslation } from 'react-i18next';
import AnalysisFilters from './AnalysisFilters';
import { useAnalysisFilters } from '@/hooks/analysis/useAnalysisFilters';
import Library from '@/components/Library';
import { useReportsTemplatesList } from '@/hooks/analysis/useReportsTemplatesList';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { findNextWidgetAvailableSpot } from '@/components/WidgetSystem/calculateWidgetPositionUtils';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';
import { Button } from '@/components/ui/button';
import { Lock, Unlock } from 'lucide-react';
import { useTailwindBreakpoint } from '@/hooks/common/useTailwindBreakpoint';

interface Props {
  filtersHook: ReturnType<typeof useAnalysisFilters>;
  isEditable: boolean;
  canMoveWidgets: boolean;
  setCanMoveWidgets: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  activeDashboardId: number | null;
}

function AnalysisLayout({
  canMoveWidgets,
  filtersHook,
  isEditable,
  setCanMoveWidgets,
  children,
  activeDashboardId,
}: Props): JSX.Element {
  const { companyId } = useAuth();
  const { t } = useTranslation();
  const isDesktop = useTailwindBreakpoint('md');

  const [reportsLibraryOpen, setReportsLibraryOpen] = useState(false);

  const {
    error: listError,
    isLoading: listLoading,
    data: reportTemplates,
  } = useReportsTemplatesList();
  const { data: categories = [] } = trpc.analysisReport.listCategories.useQuery(
    {
      companyId,
    }
  );
  const { data: reports = [] } = trpc.analysisReport.list.useQuery(
    {
      companyId,
      dashboardId: activeDashboardId!,
    },
    {
      enabled: !!activeDashboardId,
    }
  );

  const insertMutation = trpc.analysisReport.insert.useMutation({
    onSuccess() {
      setReportsLibraryOpen(false);
      // await sleep(1000);
      // const scrollContainer = document.querySelector('#dashboard-scroll-container');
      // if (!scrollContainer) return;
      // scrollContainer.scroll({
      //   top: scrollContainer.scrollHeight,
      //   behavior: 'smooth',
      // });
    },
  });

  return (
    <>
      <DashboardLayout
        disablePadding
        AppBarComponent={
          <TKPageAppBar
            title={<AnalysisFilters filtersHook={filtersHook} />}
            minHeight={null}
          >
            {isEditable && isDesktop && (
              <Button
                onClick={() => setCanMoveWidgets((prev) => !prev)}
                variant="outline"
                data-testid="toggle-dashboard-lock"
              >
                {canMoveWidgets ? (
                  <>
                    <Lock className="mr-2 h-4 w-4" />
                    {t('analysis.lockDashboard')}
                  </>
                ) : (
                  <>
                    <Unlock className="mr-2 h-4 w-4" />
                    {t('analysis.unlockDashboard')}
                  </>
                )}
              </Button>
            )}
            {isEditable && (
              <Button
                onClick={() => setReportsLibraryOpen(true)}
                title={t('analysis.createReport')}
                variant="default"
                data-testid="open-reports-library-dialog"
              >
                {t('analysis.createReport')}
              </Button>
            )}
          </TKPageAppBar>
        }
      >
        {children}
      </DashboardLayout>

      <Library
        open={reportsLibraryOpen}
        setOpen={setReportsLibraryOpen}
        loading={insertMutation.isLoading}
        loadingId={insertMutation.variables?.reportTypeId}
        categories={categories}
        handleSelect={(reportTypeId) => {
          const { x, y } = findNextWidgetAvailableSpot(reports);
          insertMutation.mutate({
            companyId,
            dashboardId: activeDashboardId!,
            reportTypeId,
            widgetXPosition: x,
            widgetYPosition: y,
          });
        }}
        items={(reportTemplates || []).map((report) => ({
          category: report.categoryId,
          id: report.reportTypeId,
          name: report.name,
          description: report.description,
        }))}
        selectButtonText={t('analysis.addReport')}
        listLoading={listLoading}
        listError={listError}
        dataHandlerEntryText={t('analysis.reports.base')}
      />
    </>
  );
}

export default AnalysisLayout;
