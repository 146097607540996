import { startOfDay, startOfWeek, startOfMonth } from 'date-fns';
import { ChartConfig } from '@/components/ui/chart';

import { DatasetGroups } from '@/hooks/analysis/useReportsDataAsChartDatasets';
interface Args {
  datasets: DatasetGroups;
  timeUnit: string;
  minTime?: string;
  maxTime?: string;
}
export function mapDatasetToRechartsFormat({
  datasets,
  timeUnit,
  minTime,
  maxTime,
}: Args) {
  const dataMap = new Map<
    number,
    Record<string, number | null> & { date: number }
  >();
  const chartConfig: ChartConfig = {};
  const absoluteMinDate = minTime ? new Date(minTime) : undefined;
  const absoluteMaxDate = maxTime ? new Date(maxTime) : undefined;
  let chartMinDate = Infinity;
  let maxDate = -Infinity;

  datasets.forEach((dataset) => {
    chartConfig[dataset.name] = {
      label: dataset.name,
      color: `hsl(var(--chart-${datasets.indexOf(dataset) + 1}))`,
    };

    dataset.data.forEach((point) => {
      const date = new Date(point.x);

      // Skip points before minDate if it's provided
      if (absoluteMinDate && date < absoluteMinDate) {
        return;
      }

      // Skip points after maxDate if it's provided
      if (absoluteMaxDate && date > absoluteMaxDate) {
        return;
      }

      let groupedDate: number;

      switch (timeUnit) {
        case 'day':
          groupedDate = startOfDay(date).getTime();
          break;
        case 'week':
          groupedDate = startOfWeek(date).getTime();
          break;
        case 'month':
          groupedDate = startOfMonth(date).getTime();
          break;
        default:
          groupedDate = point.x;
      }

      if (!dataMap.has(groupedDate)) {
        dataMap.set(groupedDate, { date: groupedDate });
      }

      const existingValue = dataMap.get(groupedDate)![dataset.name];
      dataMap.get(groupedDate)![dataset.name] = existingValue
        ? existingValue + (point.y ?? 0)
        : point.y;

      if (groupedDate < chartMinDate) {
        chartMinDate = groupedDate;
      }
      if (groupedDate > maxDate) {
        maxDate = groupedDate;
      }
    });
  });

  return {
    data: Array.from(dataMap.values()).sort(
      (a, b) => (a.date ?? 0) - (b.date ?? 0)
    ),
    chartConfig,
    minDate: chartMinDate,
    maxDate,
  };
}
