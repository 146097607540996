import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { TypographySmall } from '@/components/ui/typography';
import { X } from 'lucide-react';
import { cn } from '@/lib/utils';

export interface FilterBaseVariant {
  variant: 'light' | 'dark';
}

interface FilterBaseProps {
  buttonText: string | JSX.Element;
  iconButton: React.FC<{ className?: string }>;
  dataTestButton?: string;
  dataTestContent?: string;
  className?: string;
  isOpen?: boolean;
  children: React.ReactNode;
  variant?: 'light' | 'dark';
}

function FilterBase({
  buttonText,
  iconButton: IconButton,
  children,
  className,
  dataTestButton,
  dataTestContent,
  variant = 'dark',
  isOpen,
}: FilterBaseProps): JSX.Element {
  const [open, setOpen] = useState(isOpen ?? false);

  const handleClose = () => {
    setOpen(false);
  };

  const textIconClass = cn(
    variant === 'light'
      ? 'text-primary-foreground md:text-foreground'
      : 'text-foreground',
    open && variant === 'dark' && 'text-secondary-foreground',
    open && variant === 'light' && 'text-primary-foreground md:text-foreground'
  );

  return (
    <div className="flex">
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className={cn('mr-1 my-0.5 h-9 w-full md:w-auto')}
            type="button"
            data-testid={dataTestButton}
          >
            <IconButton className={cn('mr-2 size-5', textIconClass)} />
            <TypographySmall className={cn('truncate', textIconClass)}>
              {buttonText}
            </TypographySmall>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          className={cn(
            'w-full h-full md:w-auto md:h-auto p-2 max-w-[400px] overflow-auto max-h-[400px] md:max-h-full bg-card',
            className
          )}
          data-testid={dataTestContent}
        >
          <button
            onClick={handleClose}
            className="absolute z-10 right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          >
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </button>
          {children}
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default FilterBase;
