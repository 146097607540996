import { ReportUnit } from '@/types/analysis';

const isValidNumber = (num: unknown) => {
  const amount = Number(num);
  return typeof amount === 'number' && !isNaN(amount);
};

export function dropUnitDecimals<T extends number | null>(
  unit: ReportUnit,
  value: T
): T {
  if (value === null) return null as T;
  if (value === 0) return 0 as T;
  const decimalsMap: Record<ReportUnit, number> = {
    kilometer: 0,
    liter: 0,
    percent: 2,
    hours: 0,
    count: 0,
    kg: 0,
    ton: 2,
    liter_per_10km: 2,
    kilometer_per_hour: 0,
    kg_per_10km: 2,
  };
  let num: T = value;

  if (unit === ReportUnit.Percent) {
    num = (value * 100) as T;
  }

  const amountOfDecimals = decimalsMap[unit];
  return Number((num as number).toFixed(amountOfDecimals)) as T;
}

/**
 * Converts milliliters to liters.
 */
const milliliterToLiter = (
  milliliterAmount: number,
  keepAmountOfDecimals?: number
) => {
  if (!isValidNumber(milliliterAmount)) return 0;

  const liter = milliliterAmount / 1000;
  if (keepAmountOfDecimals) {
    return Number(liter.toFixed(keepAmountOfDecimals));
  } else {
    return Number(liter.toFixed(0));
  }
};

/**
 * Converts grams to kilograms.
 */
const gramToKilogram = (gramAmount: number, keepAmountOfDecimals?: number) => {
  if (!isValidNumber(gramAmount)) return 0;

  const kg = gramAmount / 1000;
  if (keepAmountOfDecimals) {
    return Number(kg.toFixed(keepAmountOfDecimals));
  } else {
    return Number(kg.toFixed(0));
  }
};

/**
 * Converts meters to kilometers.
 */
const meterToKilometer = (
  meterAmount: number,
  keepAmountOfDecimals?: number
) => {
  if (!isValidNumber(meterAmount)) return 0;

  const km = meterAmount / 1000;
  if (keepAmountOfDecimals) {
    return Number(km.toFixed(keepAmountOfDecimals));
  } else {
    return Number(km.toFixed(0));
  }
};

export { milliliterToLiter, meterToKilometer, gramToKilogram };
