import React from 'react';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from '@/components/ui/form';

import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import TKDatePicker from '@/components/FormControls/TKDatePicker';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { useRouter } from '@/hooks/common/useRouter';
import { useCompany } from '@/hooks/auth/useCompany';
import { EquipmentFuelTypeEnum, EquipmentTypeEnum } from '@/types/equipment';
import { SelectMultipleGroups } from '@/components/FormControls/MultipleSelect';
import EquipmentTypeField from '@/components/Equipment/EquipmentTypeField';

interface EquipmentEditFormData {
  id: string;
  type: EquipmentTypeEnum;
  fuelType: EquipmentFuelTypeEnum;
  customName: string | null;
  soldAt: string | null;
  teltonikaImei: string | null;
  dpiotId: string | null;
  groupIds: string[];
}

interface Props {
  initialValues: EquipmentEditFormData;
  open: boolean;
  setOpen: (value: boolean) => void;
}

function EditEquipmentDialog({ open, setOpen, initialValues }: Props) {
  const router = useRouter();
  const company = useCompany();
  const { companyId } = useAuth();
  const { t } = useTranslation();

  const form = useForm<EquipmentEditFormData>({
    mode: 'onChange',
    defaultValues: initialValues,
  });
  const editMutation = trpc.equipment.update.useMutation({
    async onSuccess(data) {
      // Redirect to main equipment page if it was sold
      if (data.soldAt) {
        router.replace('/equipment', {});
      }
      toast.success(
        t('common.api.update.success', {
          type: t('equipment.equipment'),
        })
      );

      form.reset();
      setOpen(false);
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('equipment.edit')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((values) => {
              editMutation.mutate({
                ...values,
                companyId,
                customName: values.customName || null,
                teltonikaImei: values.teltonikaImei || null,
                dpiotId: values.dpiotId || null,
                id: initialValues.id,
              });
            })}
          >
            <div className="space-y-4">
              <FormField
                control={form.control}
                name="type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('equipment.type')}</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={t('equipment.type')} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={EquipmentTypeEnum.TRUCK}>
                          <EquipmentTypeField type={EquipmentTypeEnum.TRUCK} />
                        </SelectItem>
                        <SelectItem value={EquipmentTypeEnum.MACHINE}>
                          <EquipmentTypeField
                            type={EquipmentTypeEnum.MACHINE}
                          />
                        </SelectItem>
                        <SelectItem value={EquipmentTypeEnum.TRAILER}>
                          <EquipmentTypeField
                            type={EquipmentTypeEnum.TRAILER}
                          />
                        </SelectItem>
                        <SelectItem value={EquipmentTypeEnum.CAR}>
                          <EquipmentTypeField type={EquipmentTypeEnum.CAR} />
                        </SelectItem>
                        <SelectItem value={EquipmentTypeEnum.CONTAINER}>
                          <EquipmentTypeField
                            type={EquipmentTypeEnum.CONTAINER}
                          />
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="customName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('validations.customName.label')}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        value={field.value || ''}
                        data-testid="input-custom-equipment-name"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="teltonikaImei"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('validations.teltonikaImei.label')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        value={field.value || ''}
                        data-testid="input-teltonika-imei"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="dpiotId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('validations.dpiotId.label')}</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        value={field.value || ''}
                        data-testid="input-dpiot-imei"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="soldAt"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('validations.soldAt.label')}</FormLabel>
                    <FormControl>
                      <TKDatePicker
                        disableFuture
                        selectedDate={
                          field.value ? new Date(field.value) : null
                        }
                        handleDateChange={(date) => {
                          field.onChange(date?.toISOString() ?? null);
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="fuelType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('equipment.columns.fuelType')}</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={t('equipment.columns.fuelType')}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={EquipmentFuelTypeEnum.DIESEL}>
                          {`${t('equipment.DIESEL')}`.toUpperCase()}
                        </SelectItem>

                        <SelectItem value={EquipmentFuelTypeEnum.GASOLINE}>
                          {`${t('equipment.GASOLINE')}`.toUpperCase()}
                        </SelectItem>

                        <SelectItem value={EquipmentFuelTypeEnum.LNG_GAS}>
                          {`${t('equipment.LNG_GAS')}`.toUpperCase()}
                        </SelectItem>

                        <SelectItem value={EquipmentFuelTypeEnum.LBG_GAS}>
                          {`${t('equipment.LBG_GAS')}`.toUpperCase()}
                        </SelectItem>

                        <SelectItem value={EquipmentFuelTypeEnum.CNG_GAS}>
                          {`${t('equipment.CNG_GAS')}`.toUpperCase()}
                        </SelectItem>

                        <SelectItem value={EquipmentFuelTypeEnum.CBG_GAS}>
                          {`${t('equipment.CBG_GAS')}`.toUpperCase()}
                        </SelectItem>

                        <SelectItem value={EquipmentFuelTypeEnum.ELECTRIC}>
                          {`${t('equipment.CBG_GAS')}`.toUpperCase()}
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />

              {company.data?.featureEnableGroups && (
                <FormField
                  control={form.control}
                  name="groupIds"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('company.groups.base_other')}</FormLabel>
                      <FormControl>
                        <SelectMultipleGroups
                          values={field.value}
                          onChange={field.onChange}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              )}
            </div>

            <DialogFooter className="mt-6">
              <TKConfirmButton
                dialogType="update"
                isLoading={editMutation.isLoading}
                disabled={editMutation.isLoading}
                type="submit"
                data-testid="update-equipment-button"
              />
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default EditEquipmentDialog;
