import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from 'react';
import { useTailwindBreakpoint } from '@/hooks/common/useTailwindBreakpoint';
import { usePrevious } from '@/hooks/common/usePrevious';

type DrawerType = {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  shouldAnimate: boolean;
  isDesktop: boolean;
  isMobile: boolean;
};

const DrawerContext = createContext<DrawerType | undefined>(undefined);

interface Props {
  children: ReactNode;
}

const DrawerProvider: React.FC<Props> = ({ children }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const isDesktop = useTailwindBreakpoint('md');
  const isMobile = !isDesktop;
  const [drawerOpen, setDrawerOpen] = useState(isDesktop);

  const prevIsDesktop = usePrevious(isDesktop);

  useEffect(() => {
    // If the screen side changed from mobile to desktop, open the drawer
    if (!prevIsDesktop && isDesktop) {
      setDrawerOpen(true);
    }
    // If the screen side changed from desktop to mobile, close the drawer
    if (prevIsDesktop && !isDesktop) {
      setDrawerOpen(false);
    }
  }, [isDesktop, prevIsDesktop]);

  // Dispatch resize event to trigger map resize
  useEffect(() => {
    const timer = setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
    return () => clearTimeout(timer);
  }, [drawerOpen]);

  useEffect(() => {
    const timer = setTimeout(() => setShouldAnimate(true), 0);
    return () => clearTimeout(timer);
  }, []);

  const value: DrawerType = {
    drawerOpen,
    setDrawerOpen,
    shouldAnimate,
    isDesktop,
    isMobile,
  };

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }
  return context;
};

export { DrawerProvider, useDrawer };
