import React from 'react';
import { useTranslation } from 'react-i18next';

import DashboardLayout from '@/components/Layouts/DashboardLayout';
import UserAccountSettings from './AccountSettings';
import UserPersonalInformation from './UserPersonalInformation';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';

const UserProfile: React.FC = () => {
  const { t } = useTranslation();
  return (
    <DashboardLayout
      AppBarComponent={
        <TKPageAppBar title={t('userProfile.title')}></TKPageAppBar>
      }
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <UserPersonalInformation />
        </div>
        <div>
          <UserAccountSettings />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default UserProfile;
