import React, { useMemo, memo } from 'react';
import FilterBase, { FilterBaseVariant } from '../FilterBase';
import MultistepListCheckbox, {
  CheckboxList,
  MultistepItem,
} from '@/components/FormControls/MultistepListCheckbox';
import { User } from 'lucide-react';
import { Trans, useTranslation } from 'react-i18next';
import DescribeIcon from '@/components/Common/DescribeIcon';
import ProjectsFiltersTitle from './ProjectsFiltersTitle';
import { useProjects } from '@/hooks/data/useProjects';
import { TypographyP } from '@/components/ui/typography';

type ProjectsFilterType = string[] | null;

export interface ProjectsFiltersValue {
  projectIds: ProjectsFilterType;
  hideDataFromUnknownProjects: boolean;
}
interface ProjectsFiltersProps extends ProjectsFiltersValue {
  handleChange: (newValues: ProjectsFiltersValue) => void;
}

function ProjectsFilters({
  projectIds,
  hideDataFromUnknownProjects,
  handleChange,
  variant,
}: ProjectsFiltersProps & FilterBaseVariant): JSX.Element {
  const { t } = useTranslation();
  const { projects, isLoading } = useProjects();

  function hasProjectWithoutRules() {
    if (!projectIds?.length) return false;
    const result = projectIds?.every((projectId) => {
      const project = projects.find(({ id }) => id === projectId);
      return !project?.hasRules;
    });
    return result || false;
  }
  const hasSelectedProjectWithoutRules = hasProjectWithoutRules();

  const allProjectsSelected = projectIds === null;
  const data: CheckboxList = useMemo(() => {
    const noProjectOption: MultistepItem = {
      active: !hideDataFromUnknownProjects,
      label: t('filters.projects.unknownProject'),
      searchFields: [t('filters.projects.unknownProject')],
      value: 'hideDataFromUnknownProjects',
      category: 'hideDataFromUnknownProjects',
    };
    const selectedProjects: MultistepItem[] = projects.map((project) => {
      const active = projectIds ? projectIds.includes(project.id) : false;

      return {
        label: project.name,
        searchFields: [project.name],
        value: project.id,
        active: active || allProjectsSelected,
        category: 'projectIds',
      };
    });
    selectedProjects.unshift(noProjectOption);

    return {
      title: t('filters.projects.title'),
      items: selectedProjects,
    };
  }, [
    hideDataFromUnknownProjects,
    allProjectsSelected,
    projectIds,
    projects,
    t,
  ]);

  const handleSelect = (item: MultistepItem) => {
    const projectsFilters: ProjectsFiltersValue = {
      hideDataFromUnknownProjects,
      projectIds,
    };
    const allProjectsIds = projects.map(({ id }) => id);

    // Toggle unknown project data
    if (item.category === 'hideDataFromUnknownProjects') {
      projectsFilters.hideDataFromUnknownProjects =
        !projectsFilters.hideDataFromUnknownProjects;
    }

    if (item.category === 'projectIds' && typeof item.value === 'string') {
      // In case the projectIds filter is null, that means every id is selected, so use the list of every project available in the company
      // Otherwise, use the array of projectIds
      const listToAdd = allProjectsSelected ? allProjectsIds : projectIds || [];

      // Using a Set to make sure every value is unique
      // Add every project ids to the Set
      const projectsSet = new Set<string>(listToAdd);

      // Remove project from the list if it exists, otherwise add id
      if (projectsSet.has(item.value)) {
        projectsSet.delete(item.value);
      } else {
        projectsSet.add(item.value);
      }
      projectsFilters.projectIds = [...projectsSet];
    }

    const hasAllSelected =
      projectsFilters.projectIds?.length === projects.length ||
      projectsFilters.projectIds === null;

    if (projectsFilters.hideDataFromUnknownProjects && hasAllSelected) {
      projectsFilters.projectIds = [...allProjectsIds];
    }

    handleChange(projectsFilters);
  };

  const handleSelectAll = () => {
    handleChange({
      projectIds: null,
      hideDataFromUnknownProjects: false,
    });
  };
  const handleDeselectAll = () => {
    handleChange({
      projectIds: [],
      hideDataFromUnknownProjects: true,
    });
  };

  return (
    <FilterBase
      variant={variant}
      iconButton={User}
      buttonText={
        <ProjectsFiltersTitle
          isLoading={isLoading}
          hideDataFromUnknownProjects={hideDataFromUnknownProjects}
          projectIds={projectIds}
          hasSelectedProjectWithoutRules={hasSelectedProjectWithoutRules}
          dataLength={projects.length}
        />
      }
      dataTestButton="open-projects-filters-button"
      dataTestContent="projects-filters-content"
    >
      <div className="w-[280px] h-full">
        <MultistepListCheckbox
          handleSelect={handleSelect}
          data={data}
          description={t('filters.projects.description')}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          noDataText={t('filters.projects.noData')}
          noDataWithSearchText={t('filters.projects.noDataWithSearch')}
        />
        {hasSelectedProjectWithoutRules && (
          <div className="flex items-center justify-center mt-2">
            <DescribeIcon
              variant="warn"
              withText={t('filters.projects.selectedProjectWithNoRulesTitle')}
            >
              <TypographyP>
                <Trans
                  i18nKey="filters.projects.selectedProjectWithNoRules"
                  values={{ text: t('filters.projects.unknownProject') }}
                  components={{ bold: <strong /> }}
                />
              </TypographyP>
            </DescribeIcon>
          </div>
        )}
      </div>
    </FilterBase>
  );
}

export default memo(ProjectsFilters);
