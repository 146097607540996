import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/api/trpc';
import { MapIcon } from 'lucide-react';
import { useAuth } from '@/contexts/Global/AuthContext';
import { Button } from '@/components/ui/button';
import TKTypedLink from '../Common/TKTypedLink';
import TKTextWithLabel from '../Common/TKTextWithLabel';

interface Props {
  equipmentId: string;
  displayName: string;
}

function CurrentPositionField({ equipmentId, displayName }: Props) {
  const { t } = useTranslation();
  const { companyId } = useAuth();
  const [now] = useState(() => new Date().toISOString());
  const positionQuery = trpc.equipment.positionAtTime.useQuery(
    {
      id: equipmentId,
      atTime: now,
      companyId,
    },
    {
      retry: 0,
    }
  );
  const { latitude, longitude, sampledAt } = positionQuery?.data ?? {};

  const addressQuery = trpc.geocode.addressAtCoordinates.useQuery(
    { lat: latitude!, lng: longitude! },
    {
      enabled: !!latitude && !!longitude,
    }
  );

  const text = addressQuery?.data?.address ?? t('common.notAvailable');

  const showFindOnMapButton = !!displayName && !!latitude && !!longitude;

  return (
    <TKTextWithLabel
      loading={positionQuery.isLoading || addressQuery.isInitialLoading}
      label={t('equipment.columns.currentPosition')}
      timestamp={sampledAt}
      text={
        <div className="flex items-center justify-between">
          {showFindOnMapButton ? (
            <Button
              variant="link"
              asChild
              className="font-bold underline flex items-center"
            >
              <TKTypedLink
                to="/map/:equipmentId?"
                params={{ 'equipmentId?': equipmentId }}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <MapIcon className="mr-1 size-5" />
                {text}
              </TKTypedLink>
            </Button>
          ) : (
            <span>{text}</span>
          )}
        </div>
      }
    />
  );
}

export default CurrentPositionField;
