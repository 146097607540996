import { useTranslation } from 'react-i18next';
import { ReactComponent as NotFoundImage } from '@/assets/images/companies/undraw_towing_6yy4.svg';
import { Button } from '@/components/ui/button';
import { TypographyH3 } from '@/components/ui/typography';

function NotFound() {
  const { t } = useTranslation();

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-full max-w-3xl">
        <div className="flex flex-col items-center space-y-6 p-6">
          <NotFoundImage
            viewBox="0 0 1002.5009 516.22024"
            width="100%"
            height="auto"
            className="max-w-md"
          />

          <TypographyH3 className="text-center">
            {t('commonPages.notFound.description')}
          </TypographyH3>

          <Button onClick={handleGoBack}>
            {t('commonPages.notFound.goBack')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
