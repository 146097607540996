import React from 'react';
import { useTranslation } from 'react-i18next';
import { TypographySmall } from '@/components/ui/typography';
import { GroupFiltersValue } from './GroupFilter';

interface Props extends GroupFiltersValue {
  dataLength: number;
  isLoading: boolean;
}

function Title({
  groupIds,
  hideDataFromWithoutGroups,
  dataLength,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const total = groupIds?.length || 0;

  if ((!groupIds || dataLength === total) && !hideDataFromWithoutGroups) {
    return <>{t('filters.groups.all')}</>;
  }
  if (
    Array.isArray(groupIds) &&
    !groupIds?.length &&
    !hideDataFromWithoutGroups
  ) {
    return <>{t('filters.groups.withoutGroups')}</>;
  }

  if (total === 0) return <>{t('filters.groups.title')}</>;

  return <>{t('filters.groups.base', { count: total })}</>;
}

function GroupFiltersTitle({
  groupIds,
  hideDataFromWithoutGroups,
  dataLength,
  isLoading,
}: Props): JSX.Element {
  return (
    <span className="flex items-center">
      <TypographySmall>
        <Title
          isLoading={isLoading}
          groupIds={groupIds}
          dataLength={dataLength}
          hideDataFromWithoutGroups={hideDataFromWithoutGroups}
        />
      </TypographySmall>
    </span>
  );
}

export default GroupFiltersTitle;
