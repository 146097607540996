import React, { memo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { TypographyH5, TypographyP } from '@/components/ui/typography';
import { cn } from '@/lib/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { TKLogoIcon } from '@/components/Common/TKCustomIcons';
import upperFirst from 'lodash/upperFirst';

interface Props {
  activeReportId: string | null;
  setActiveReportId: (id: string | null) => void;
  reports: { id: string; name: string }[];
}

function ReportSidebar({
  activeReportId,
  setActiveReportId,
  reports,
}: Props): JSX.Element {
  const listRef = useRef<HTMLUListElement>(null);
  const { t } = useTranslation();

  return (
    <div className="h-full flex flex-col">
      <div className="px-4 py-2">
        <TypographyH5>
          {upperFirst(t('analysis.reports.base_other'))}
        </TypographyH5>
      </div>
      <div className="flex-grow py-4 overflow-hidden">
        <ScrollArea className="flex-grow">
          <ul ref={listRef} className="p-0 m-0">
            {reports.map((report) => {
              const isActive = report.id === activeReportId;
              return (
                <li
                  key={report.id}
                  className={cn('px-2 py-1 rounded-md cursor-pointer')}
                >
                  <Button
                    variant="link"
                    className={cn(
                      'w-full justify-between px-4 py-2 group h-10 hover:no-underline hover:bg-accent',
                      isActive && 'bg-accent text-accent-foreground'
                    )}
                    onClick={() => setActiveReportId(report.id)}
                    data-report-id={report.id}
                    data-testid="analysis-report-list-item"
                  >
                    <TypographyP
                      className={cn('truncate', isActive && 'font-medium')}
                      data-testid="report-list-item-text"
                    >
                      {report.name}
                    </TypographyP>
                    <div className="flex items-center">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              className="h-10 w-10 hover:bg-transparent"
                            >
                              <TKLogoIcon className="size-5" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            {t('analysis.presetDashboard')}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </Button>
                </li>
              );
            })}
          </ul>
        </ScrollArea>
      </div>
    </div>
  );
}

export default memo(ReportSidebar);
