import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { useAuth } from '@/contexts/Global/AuthContext';
import { EMAIL_REGEX, PHONE_REGEX } from '@/utils/common/validators';
import { SelectMultipleUnlinkedDriverCards } from '@/components/FormControls/MultipleSelect';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import upperFirst from 'lodash/upperFirst';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}

interface CreateDriverForm {
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  driverCardIds: string[];
}

function CreateDriverDialog({ open, setOpen }: Props): JSX.Element {
  const { t } = useTranslation();
  const { companyId } = useAuth();
  const form = useForm<CreateDriverForm>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: null,
      phone: null,
      driverCardIds: [],
    },
  });

  const createMutation = trpc.driver.insert.useMutation({
    onSuccess() {
      toast.success(
        upperFirst(t('common.api.insert.success', { type: t('drivers.base') }))
      );
      form.reset();
      setOpen(false);
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        setOpen(open);
        form.reset();
      }}
    >
      <DialogContent className="overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{t('drivers.createDriverDialog.title')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) =>
              createMutation.mutate({
                companyId,
                ...data,
              })
            )}
            className="space-y-4"
          >
            <FormField
              control={form.control}
              name="driverCardIds"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('drivers.tachoDriverIds')}</FormLabel>
                  <FormControl>
                    <SelectMultipleUnlinkedDriverCards
                      values={field.value}
                      onChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="firstName"
                rules={{ required: t('validations.firstname.required') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('validations.firstname.label')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="lastName"
                rules={{ required: t('validations.lastname.required') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('validations.lastname.label')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="phone"
              rules={{
                pattern: {
                  value: PHONE_REGEX,
                  message: t('validations.phone.pattern'),
                },
              }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('validations.phone.label')}</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value ?? ''} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              rules={{
                pattern: {
                  value: EMAIL_REGEX,
                  message: t('validations.email.pattern'),
                },
              }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('validations.email.label')}</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value ?? ''} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => setOpen(false)}
              >
                {t('common.cancelText')}
              </Button>
              <TKConfirmButton
                dialogType="create"
                isLoading={createMutation.isLoading}
                disabled={createMutation.isLoading}
                data-testid="confirm-create-driver"
              />
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default CreateDriverDialog;
