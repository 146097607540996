import React from 'react';
import { Spinner } from '@/components/ui/spinner';
import { TypographySmall } from '@/components/ui/typography';
import { TKDataHandlerImageProps } from './TKDataHandler';

type TKDescriptiveProgressProps = React.ComponentProps<typeof Spinner> &
  TKDataHandlerImageProps & {
    children?: React.ReactNode;
  };

const TKDescriptiveProgress: React.FC<TKDescriptiveProgressProps> = ({
  size,
  imageWidth = '70%',
  children,
  ...rest
}) => {
  return (
    <div
      className="flex flex-col justify-center items-center h-full w-full"
      data-testid="data-loading"
    >
      <div
        style={{ width: imageWidth }}
        className="max-w-[400px] flex justify-center h-auto"
      >
        <Spinner size={size} {...rest} />
      </div>
      {children && (
        <TypographySmall className="pt-2 text-primary">
          {children}
        </TypographySmall>
      )}
    </div>
  );
};

export default TKDescriptiveProgress;
