import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { SelectSingleDriver } from '@/components/FormControls/SingleSelect';
import { Input } from '@/components/ui/input';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from '@/components/ui/form';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { useAuth } from '@/contexts/Global/AuthContext';
import { EMAIL_REGEX, PHONE_REGEX } from '@/utils/common/validators';
import upperFirst from 'lodash/upperFirst';
import { Spinner } from '@/components/ui/spinner';
interface DriverLinkForm {
  driverId: number;
  driverCardId: string;
  tabType: 'link';
}
interface DriverCreateForm {
  driverCardId: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  tabType: 'create';
}
type FormData = DriverLinkForm | DriverCreateForm;

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  driverCardId: string;
  tachoDriverId: string;
}
export function LinkCreateDriverForm({
  open,
  setOpen,
  driverCardId,
  tachoDriverId,
}: Props): JSX.Element {
  const { companyId } = useAuth();
  const { t } = useTranslation();
  const form = useForm<FormData>({
    defaultValues: {
      tabType: 'create',
      driverCardId,
    },
  });
  const currentTab = form.watch('tabType');
  const setValue = form.setValue;

  // try to load driver card info from the server so we can prefill the form
  const driverCardInfo = trpc.driver.driverCardById.useQuery(
    {
      companyId,
      id: driverCardId,
    },
    {
      enabled: !!driverCardId,
    }
  );
  const card = driverCardInfo.data;

  // if we have a suggested driver, default to the link tab
  // and set the driver id to the suggested driver id
  const suggestedDriverId = card?.suggestedDriverId;
  useEffect(() => {
    if (suggestedDriverId) {
      setValue('tabType', 'link');
      setValue('driverId', suggestedDriverId);
    }
  }, [suggestedDriverId, setValue]);

  // if we have a driver card id, set it
  useEffect(() => {
    setValue('driverCardId', driverCardId);
    if (currentTab === 'create' && card) {
      setValue('firstName', card.firstName ?? '');
      setValue('lastName', card.lastName ?? '');
      setValue('phone', card.phone ?? '');
      setValue('email', card.email?.toLowerCase() ?? '');
    }
  }, [card, currentTab, setValue, driverCardId]);

  const createDriverMutation = trpc.driver.insert.useMutation({
    onSuccess() {
      toast.success(
        upperFirst(
          upperFirst(
            t('common.api.insert.success', { type: t('drivers.base') })
          )
        )
      );
      setOpen(false);
      form.reset();
    },
    onError(error) {
      toast.error(error.message);
    },
  });
  const linkDriverCardsMutation = trpc.driver.linkDriverCards.useMutation({
    onSuccess() {
      toast.success(
        upperFirst(t('common.api.update.success', { type: t('drivers.base') }))
      );
      setOpen(false);
      form.reset();
    },
  });

  return (
    <>
      <Dialog
        open={open}
        onOpenChange={(open) => {
          setOpen(open);
          if (!open) {
            form.reset();
          }
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('drivers.linkOrCreateDriver')}</DialogTitle>
            <DialogDescription className="font-semibold">
              {tachoDriverId}
            </DialogDescription>
          </DialogHeader>

          {driverCardInfo.isLoading ? (
            <div className="min-h-[45vh] flex flex-col justify-center">
              <Spinner className="mx-auto" />
            </div>
          ) : (
            <>
              <Form {...form}>
                <form className="flex-1">
                  <Tabs
                    value={currentTab}
                    onValueChange={(value) =>
                      form.setValue('tabType', value as FormData['tabType'])
                    }
                  >
                    <TabsList>
                      <TabsTrigger value="create">
                        {t('drivers.createNewDriver')}
                      </TabsTrigger>
                      <TabsTrigger value="link">
                        {t('drivers.linkToExistingDriver')}
                      </TabsTrigger>
                    </TabsList>
                    <TabsContent value="link">
                      <FormField
                        control={form.control}
                        name="driverId"
                        rules={{
                          required: {
                            value: currentTab === 'link',
                            message: t('validations.required'),
                          },
                        }}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>{t('drivers.table.driver')}</FormLabel>
                            <FormControl>
                              <SelectSingleDriver
                                value={field.value as number}
                                onChange={field.onChange}
                              />
                            </FormControl>
                            <FormMessage />
                            {card?.suggestedDriverId && (
                              <FormDescription>
                                {t('drivers.linkToExistingDriverSuggestion')}
                              </FormDescription>
                            )}
                          </FormItem>
                        )}
                      />
                    </TabsContent>
                    <TabsContent value="create">
                      <div className="space-y-4">
                        <FormField
                          control={form.control}
                          name="firstName"
                          rules={{
                            required: {
                              value: currentTab === 'create',
                              message: t('validations.required'),
                            },
                          }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>{t('drivers.firstname')}</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="lastName"
                          rules={{
                            required: {
                              value: currentTab === 'create',
                              message: t('validations.required'),
                            },
                          }}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>{t('drivers.lastname')}</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="phone"
                          rules={
                            currentTab === 'create'
                              ? {
                                  pattern: {
                                    value: PHONE_REGEX,
                                    message: t('validations.phone.pattern'),
                                  },
                                }
                              : undefined
                          }
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>{t('drivers.table.phone')}</FormLabel>
                              <FormControl>
                                <Input {...field} />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name="email"
                          rules={
                            currentTab === 'create'
                              ? {
                                  pattern: {
                                    value: EMAIL_REGEX,
                                    message: t('validations.email.pattern'),
                                  },
                                }
                              : undefined
                          }
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel>{t('drivers.table.email')}</FormLabel>
                              <FormControl>
                                <Input {...field} type="email" />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </TabsContent>
                  </Tabs>
                </form>
              </Form>
              <DialogFooter>
                <Button
                  type="button"
                  onClick={() => setOpen(false)}
                  variant="outline"
                >
                  {t('common.cancelText')}
                </Button>
                <Button
                  type="button"
                  isLoading={
                    createDriverMutation.isLoading ||
                    linkDriverCardsMutation.isLoading
                  }
                  onClick={form.handleSubmit((data) => {
                    if (data.tabType === 'link') {
                      linkDriverCardsMutation.mutate({
                        companyId,
                        driverCardIds: [data.driverCardId],
                        id: data.driverId,
                      });
                    } else {
                      createDriverMutation.mutate({
                        companyId,
                        driverCardIds: [data.driverCardId],
                        firstName: data.firstName,
                        lastName: data.lastName,
                        phone: data.phone || null,
                        email: data.email || null,
                      });
                    }
                  })}
                >
                  {currentTab === 'create'
                    ? t('common.confirmCreateText')
                    : t('drivers.link')}
                </Button>
              </DialogFooter>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
