import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { TypographyP } from '@/components/ui/typography';
import { FirebaseActionQuery } from '.';
import { applyActionCode } from 'firebase/auth';
import { firebaseAuth } from '@/lib/firebaseClient';

const VerifyEmailForm: React.FC<FirebaseActionQuery> = ({
  oobCode,
  continueUrl,
}) => {
  const { t } = useTranslation();
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  /** Method that calls firebases's email verification method and handles loading/error states */
  const handleVerifyEmail = useCallback(async () => {
    // Only execute once
    if (isVerified) return;
    try {
      setLoading(true);
      await applyActionCode(firebaseAuth, oobCode);
      setIsVerified(true);
    } catch (error) {
      setError(true);
      setIsVerified(false);
    } finally {
      setLoading(false);
    }
  }, [isVerified, oobCode]);

  // Start verification process on page load
  useEffect(() => {
    handleVerifyEmail();
  }, [handleVerifyEmail]);

  const handleRedirectToReturnUrl = () => {
    window.location.href = continueUrl;
  };

  return (
    <div className="space-y-4">
      {loading && (
        <TypographyP>
          {t('login.firebaseAction.verifyEmail.loading')}
        </TypographyP>
      )}
      {isVerified && (
        <TypographyP>
          {t('login.firebaseAction.verifyEmail.success')}
        </TypographyP>
      )}
      {error && (
        <TypographyP className="text-destructive">
          {t('login.firebaseAction.verifyEmail.error')}
        </TypographyP>
      )}
      <Button onClick={handleRedirectToReturnUrl} disabled={loading}>
        {loading && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
        {loading
          ? t('login.firebaseAction.verifyEmail.verifying')
          : t('login.firebaseAction.continue')}
      </Button>
    </div>
  );
};

export default VerifyEmailForm;
