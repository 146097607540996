import { FilterComparisons } from '@/components/FilterDropdowns/CompareFilters/getActiveComparison';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { ReportChartTypes } from '@/types/analysis';

function useHandleChartType(reportId: number, comparison: FilterComparisons) {
  const trpcContext = trpc.useUtils();
  const { companyId } = useAuth();
  const { data: current } = trpc.analysisReport.chartById.useQuery(
    {
      id: reportId,
      companyId,
    },
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate } = trpc.analysisReport.upsertUserChartById.useMutation({
    async onMutate(variables) {
      const reportParams = { id: reportId, companyId };
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await trpcContext.analysisReport.chartById.cancel(reportParams);

      const previous =
        trpcContext.analysisReport.chartById.getData(reportParams);

      // Optimistically update to the new value
      trpcContext.analysisReport.chartById.setData(reportParams, () => {
        return {
          id: reportId,
          chartType: variables.chartType,
        };
      });
      return previous!;
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    async onError(error, variables, context) {
      trpcContext.analysisReport.chartById.setData(
        { id: reportId, companyId },
        context as never
      );
    },
  });

  const chartType = current?.chartType ?? ReportChartTypes.Graph;

  const isGrouped = comparison === 'total';

  // Get chart's default type (from table)
  // If the filters are aggregated, instead of the table list, we should just show the total/average unit for a given report.
  let displayChartType = chartType;
  if (isGrouped && chartType === 'list') {
    displayChartType = ReportChartTypes.Card;
  }

  let apexChartType: 'line' | 'area' = 'line';
  if (comparison === 'total') {
    apexChartType = 'area';
  }

  function handleChangeChartType(chartType: ReportChartTypes) {
    mutate({
      chartType,
      companyId,
      id: reportId,
    });
  }

  return { handleChangeChartType, displayChartType, apexChartType, chartType };
}

export { useHandleChartType };
