import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from '@/components/ui/switch';
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
} from '@/components/ui/form';
import TKDatePicker from '@/components/FormControls/TKDatePicker';
import TKWeekdaysPicker from '@/components/Common/TKWeekdaysPicker';
import { useCompany } from '@/hooks/auth/useCompany';
import { UseFormReturn } from 'react-hook-form';
import { TimePickerInput } from '@/components/ui/time-picker-input';
import { AutoComplete } from '@/components/ui/autocomplete';
import { useBrregService } from '@/hooks/common/useBrregService';
import { useState } from 'react';
import { TypographyBold } from '@/components/ui/typography';

export type EquipmentSharingFormValues = {
  company: {
    name: string | undefined;
    organizationNumber: string | undefined;
  };
  startDate: Date;
  endDate: Date;
  startTime: Date;
  endTime: Date;
  allDay: boolean;
  weekdays: number[];
};
type Props = {
  form: UseFormReturn<EquipmentSharingFormValues>;
  readOnlyCompany?: boolean;
};

const EquipmentSharingForm: React.FC<Props> = ({ form, readOnlyCompany }) => {
  const { data: currentCompany } = useCompany();
  const { t } = useTranslation();
  const [searchNameValue, setSearchNameValue] = useState<string>('');
  const searchNameQuery = useBrregService(searchNameValue, {
    keepPreviousData: true,
  });

  return (
    <div className="space-y-4">
      <FormField
        control={form.control}
        name="company"
        render={({ field }) => (
          <FormItem className="flex flex-col space-2 gap-2">
            <FormLabel>
              {t('equipment.EquipmentSharingAddDialog.whoToShareWith')}
            </FormLabel>
            <FormControl>
              {readOnlyCompany ? (
                <TypographyBold>{field.value?.name}</TypographyBold>
              ) : (
                <AutoComplete
                  isLoading={searchNameQuery.isInitialLoading}
                  isError={searchNameQuery.isError}
                  isFetching={searchNameQuery.isFetching}
                  emptyMessage={t('common.noData')}
                  options={
                    searchNameQuery.data?.map((company) => ({
                      label: company.name,
                      value: company.organizationNumber,
                    })) ?? []
                  }
                  value={{
                    label: field.value?.name || '',
                    value: field.value?.organizationNumber || '',
                  }}
                  onSearch={(val) => {
                    setSearchNameValue(val);
                  }}
                  searchValue={searchNameValue}
                  onValueChange={(opt) => {
                    field.onChange({
                      name: opt.label,
                      organizationNumber: opt.value,
                    });
                  }}
                />
              )}
            </FormControl>
          </FormItem>
        )}
      />

      <div className="flex space-x-4">
        <FormField
          control={form.control}
          name="startDate"
          render={({ field }) => (
            <FormItem className="flex flex-col space-2 gap-2">
              <FormLabel>
                {t('equipment.EquipmentSharingAddDialog.startDate')}
              </FormLabel>
              <FormControl>
                <TKDatePicker
                  selectedDate={field.value}
                  minDate={currentCompany?.createdAt}
                  handleDateChange={(date) => {
                    if (date) field.onChange(date);
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="endDate"
          render={({ field }) => (
            <FormItem className="flex flex-col space-2 gap-2">
              <FormLabel>
                {t('equipment.EquipmentSharingAddDialog.endDate')}
              </FormLabel>
              <FormControl>
                <TKDatePicker
                  selectedDate={field.value}
                  minDate={currentCompany?.createdAt}
                  handleDateChange={(date) => {
                    if (date) field.onChange(date);
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <FormField
        control={form.control}
        name="weekdays"
        render={({ field }) => (
          <FormItem className="flex flex-col space-2 gap-2">
            <FormLabel>
              {t('equipment.EquipmentSharingAddDialog.daysToInclude')}
            </FormLabel>
            <FormControl>
              <TKWeekdaysPicker
                selectedWeekdays={field.value}
                handleWeekdaysChange={(w) =>
                  field.onChange(w.map((v) => parseInt(v.value.toString())))
                }
              />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="allDay"
        render={({ field }) => (
          <FormItem className="flex flex-col space-2 gap-2">
            <FormLabel>
              {t('equipment.EquipmentSharingAddDialog.timeOfDay')}
            </FormLabel>

            <div className="flex items-center">
              <FormControl>
                <Switch
                  checked={field.value}
                  onCheckedChange={field.onChange}
                />
              </FormControl>
              <FormLabel>
                {t('equipment.EquipmentSharingEditDialog.allDay')}
              </FormLabel>
            </div>
          </FormItem>
        )}
      />
      {!form.watch('allDay') && (
        <div className="flex space-x-4">
          <FormField
            control={form.control}
            name="startTime"
            render={({ field }) => (
              <FormItem className="flex flex-col space-2 gap-2">
                <FormLabel>
                  {t('equipment.EquipmentSharingEditDialog.startTime')}
                </FormLabel>
                <FormControl>
                  <div className="flex">
                    <TimePickerInput
                      picker="hours"
                      date={field.value}
                      setDate={(date) => {
                        if (date) field.onChange(date);
                      }}
                    />
                    <TimePickerInput
                      picker="minutes"
                      date={field.value}
                      setDate={(date) => {
                        if (date) field.onChange(date);
                      }}
                    />
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="endTime"
            render={({ field }) => (
              <FormItem className="flex flex-col space-2 gap-2">
                <FormLabel>
                  {t('equipment.EquipmentSharingEditDialog.endTime')}
                </FormLabel>
                <FormControl>
                  <div className="flex">
                    <TimePickerInput
                      picker="hours"
                      date={field.value}
                      setDate={(date) => {
                        if (date) field.onChange(date);
                      }}
                    />
                    <TimePickerInput
                      picker="minutes"
                      date={field.value}
                      setDate={(date) => {
                        if (date) field.onChange(date);
                      }}
                    />
                  </div>
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      )}
    </div>
  );
};

export default EquipmentSharingForm;
