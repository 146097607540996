import { useRef, useEffect } from 'react';

/**
 * Returns the previous `value` found before the last re-render happened.
 *
 * @param {T} value the current value
 * @return {*}  {(T | undefined)} the previous value
 */
const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
export { usePrevious };
