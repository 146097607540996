import React from 'react';
import { useTranslation } from 'react-i18next';
import TKTextWithLabel from '@/components/Common/TKTextWithLabel';
import { useDateFns } from '@/hooks/common/useDateFns';
import { EquipmentById } from '@/types/equipment';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { EquipmentIcon } from '@/components/Common/TKCustomIcons';
import { useCompany } from '@/hooks/auth/useCompany';
import EquipmentTypeField from '@/components/Equipment/EquipmentTypeField';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

interface Props {
  equipment: EquipmentById;
}

function DetailSection({ equipment }: Props): JSX.Element {
  const { t } = useTranslation();
  const { format } = useDateFns();
  const { companyId } = useAuth();
  const { data: company } = useCompany();

  const { data: groupsData = [] } = trpc.group.list.useQuery(
    { companyId },
    { enabled: !!company?.featureEnableGroups }
  );

  const groupsMap = new Map<string, string>(
    groupsData.map((x) => [x.id, x.name])
  );

  const groups = equipment.groupIds.map((id) => ({
    id,
    name: groupsMap.get(id),
  }));

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <EquipmentIcon iconType={equipment.type} />
          {t('equipment.columns.vehicleDetails')}
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4 flex flex-col">
        <TKTextWithLabel
          label={t('equipment.type')}
          text={<EquipmentTypeField type={equipment.type} />}
        />

        {equipment?.registrationNumber && (
          <TKTextWithLabel
            label={t('equipment.registrationNumber')}
            text={equipment.registrationNumber}
          />
        )}

        <TKTextWithLabel
          label={t('equipment.columns.serialNumber')}
          text={equipment.serialNumber}
        />

        <TKTextWithLabel
          label={t('equipment.group')}
          text={
            groups.length > 0 ? (
              <ul className="list-disc pl-5">
                {groups.map((group) => (
                  <li key={group.id}>{group.name}</li>
                ))}
              </ul>
            ) : (
              t('common.notAvailable')
            )
          }
        />

        <TKTextWithLabel
          label={t('equipment.manufacturer')}
          text={equipment.brand}
        />

        <TKTextWithLabel label={t('equipment.model')} text={equipment.model} />

        <TKTextWithLabel
          label={t('equipment.columns.dateAddedToDashboard')}
          text={
            equipment.createdAt
              ? format(new Date(equipment.createdAt), 'P')
              : t('common.notAvailable')
          }
        />

        <TKTextWithLabel
          label={t('equipment.columns.status')}
          text={
            equipment.soldAt ? t('equipment.inactive') : t('equipment.active')
          }
        />
      </CardContent>
    </Card>
  );
}

export default DetailSection;
