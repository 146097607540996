import React, { createContext, useState, useRef, useContext } from 'react';

import { useTranslation } from 'react-i18next';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { TypographySmall } from '@/components/ui/typography';

type DialogType = 'create' | 'update' | 'delete';
type TKConfirmDialogOptions = {
  dialogType: DialogType | 'confirm';
  title: string;
  dataTestConfirm?: string;
  dataTestCancel?: string;
  catchOnCancel?: boolean;
  description: string;
  withInputConfirm?: {
    descriptionComponent: JSX.Element;
    valueToType: string;
  };
};
interface TKConfirmDialogProps extends TKConfirmDialogOptions {
  open: boolean;
  onConfirm: () => any;
  onCancel: () => any;
}

const TKConfirmDialog: React.FC<TKConfirmDialogProps> = ({
  title,
  description,
  open,
  onConfirm,
  onCancel,
  dialogType,
  withInputConfirm,
  dataTestCancel,
  dataTestConfirm,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleCancelWrapper = () => {
    onCancel();
    setValue('');
  };

  const handleConfirmWrapper = () => {
    onConfirm();
    setValue('');
  };

  const disabled =
    withInputConfirm && withInputConfirm.valueToType !== value ? true : false;

  return (
    <Dialog open={open} onOpenChange={() => handleCancelWrapper()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>

        {withInputConfirm && (
          <div className="space-y-4">
            <TypographySmall>
              {withInputConfirm.descriptionComponent}
            </TypographySmall>
            <Input
              value={value}
              onChange={handleValueChange}
              className={disabled ? 'border-red-500' : ''}
              autoComplete="off"
              data-testid-confirm-text={withInputConfirm.valueToType}
            />
          </div>
        )}

        <DialogFooter>
          <Button
            type="button"
            variant="outline"
            onClick={handleCancelWrapper}
            data-testid={dataTestCancel}
          >
            {t('common.cancelText')}
          </Button>
          <TKConfirmButton
            isLoading={false}
            disabled={disabled}
            onClick={handleConfirmWrapper}
            dialogType={dialogType}
            data-testid={dataTestConfirm}
          />
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

type OpenConfirmDialog = (options: TKConfirmDialogOptions) => Promise<void>;

const DialogContext = createContext<OpenConfirmDialog>(Promise.reject);

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const DialogProvider = ({ children }: Props) => {
  const [state, setState] = useState<TKConfirmDialogOptions | null>(null);

  const awaitingPromiseRef = useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openConfirm: OpenConfirmDialog = (options: TKConfirmDialogOptions) => {
    setState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (state?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setState(null);
  };

  const handleConfirm = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setState(null);
  };

  return (
    <DialogContext.Provider value={openConfirm}>
      {state && (
        <TKConfirmDialog
          open={Boolean(state)}
          onConfirm={handleConfirm}
          onCancel={handleClose}
          {...state}
        />
      )}
      {children}
    </DialogContext.Provider>
  );
};

/**
 * Context wrapper to easily handle confirmation dialogs.
 *
 * If catchOnCancel option is omitted or false, then the catch block will not be executed.
 *
 * Usage example:
 *     dialog({
 *     dialogType: 'delete',
 *     title: 'Are you sure you want to delete this?',
 *     description: 'This action is irreversible',
 *     catchOnCancel: true,
 *   }).then(() => {
 *     console.log('Confirm button clicked !');
 *   }).catch(() => {
 *     console.log('Cancel button clicked!)
 *    });
 *
 */
export const useDialog = () => {
  return useContext(DialogContext);
};

export { DialogProvider };
