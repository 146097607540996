import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { PresetTypes } from '@/types/analysis';
import { useDetermineInterval } from '@/hooks/filters/useDetermineInterval';
import { DateFiltersValue } from './DateFilters';
import { TypographySmall } from '@/components/ui/typography';
import { Button } from '@/components/ui/button';
import DescribeIcon from '@/components/Common/DescribeIcon';

type Preset = { type: PresetTypes; amount: number | null };
const presets: Preset[] = [
  // All time
  { type: 'allTime', amount: null },
  // Days presets
  { type: 'lastDays', amount: 7 },
  { type: 'lastDays', amount: 14 },
  { type: 'lastDays', amount: 30 },
  // Weeks presets
  // Months presets
  { type: 'lastMonths', amount: 3 },
  { type: 'lastMonths', amount: 6 },
  { type: 'lastMonths', amount: 12 },
  //  Previous
  { type: 'previousWeek', amount: null },
  { type: 'previousMonth', amount: null },
  { type: 'previousYear', amount: null },
  // Recent/latest
  { type: 'thisWeek', amount: null },
  { type: 'thisMonth', amount: null },
  { type: 'thisYear', amount: null },
];

interface Props {
  handleChange: (dateFilters: Partial<DateFiltersValue>) => void;
  presetType: PresetTypes | null;
  presetAmount: number | null;
}

function DateFiltersPresets({ presetType, presetAmount, handleChange }: Props) {
  const { t } = useTranslation();
  const determineInterval = useDetermineInterval();

  const handleSetPeriod = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const { presetType, presetAmount } = e.currentTarget.dataset;
    const type = presetType as PresetTypes;
    const dateType = 'preset';

    const newPartialFilters: DateFiltersValue = {
      presetAmount: Number(presetAmount) || null,
      presetType: type,
      dateType: dateType,
    } as DateFiltersValue;

    handleChange({
      ...newPartialFilters,
      interval: determineInterval(newPartialFilters),
    });
  };

  return (
    <div className="max-w-[160px]">
      <div className="flex justify-center items-center">
        <TypographySmall className="font-semibold">
          {t('filters.date.periodPresets.text')}
        </TypographySmall>
        <DescribeIcon>
          <Trans
            values={{ text: t('filters.date.views.preset') }}
            i18nKey="filters.date.periodPresets.description"
            components={{ bold: <strong /> }}
          />
        </DescribeIcon>
      </div>

      <div className="mt-2 flex flex-col space-y-1">
        {presets.map((preset) => {
          const isActive =
            preset.type === presetType && preset?.amount === presetAmount;
          const key = `date-filter-preset-${preset.type}-${preset.amount}`;
          return (
            <Button
              key={key}
              variant={isActive ? 'secondary' : 'ghost'}
              className="w-full justify-start text-left"
              data-preset-amount={preset.amount}
              data-preset-type={preset.type}
              onClick={handleSetPeriod}
              data-testid={key}
            >
              {t(`filters.date.periodPresets.${preset.type}`, {
                count: preset?.amount || undefined,
              })}
            </Button>
          );
        })}
      </div>
    </div>
  );
}

export default DateFiltersPresets;
