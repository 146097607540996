import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command';
import { CheckIcon, ChevronDown } from 'lucide-react';
import { useRef } from 'react';
import { CommandList } from 'cmdk';
import { trpc, TRPCClientErrorLike } from '@/api/trpc';
import { Spinner } from '../ui/spinner';
import { useAuth } from '@/contexts/Global/AuthContext';

type Option<T extends string | number> = {
  id: T;
  name: string;
};

interface Props<T extends string | number> {
  value: T | null;
  onChange: (value: T | null) => void;
  options: Option<T>[];
  isLoading: boolean;
  error: Error | TRPCClientErrorLike | null;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
}

interface ValueProps<T extends string | number> {
  value: T | null;
  className?: string;
  disabled?: boolean;
  onChange: (value: T | null) => void;
}

function SelectSingle<T extends string | number>({
  value,
  onChange,
  options,
  isLoading,
  error,
  placeholder,
  disabled,
  className,
}: Props<T>) {
  const { t } = useTranslation();

  const selectedOption = options.find((opt) => opt.id === value);

  // Workaround: ref to the container element, necessary to fix scrolling of the popover inside dialogs
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className={cn('w-full', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            type="button"
            variant="outline"
            role="combobox"
            className={cn(
              'h-9 w-full justify-between px-2 overflow-hidden',
              !value && 'text-muted-foreground'
            )}
            data-testid="select-single-trigger"
            disabled={disabled}
          >
            {selectedOption
              ? selectedOption.name
              : placeholder || t('common.select')}
            <ChevronDown className="ml-auto h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          container={containerRef.current}
          className="p-0"
          align="start"
        >
          <Command>
            <CommandInput placeholder={t('common.search')} className="h-9" />
            <CommandList className="max-h-64 overflow-auto">
              <CommandEmpty>{t('common.no_results')}</CommandEmpty>
              {isLoading && (
                <CommandGroup>
                  <div className="flex h-full w-full items-center justify-center">
                    <Spinner size="sm" />
                  </div>
                </CommandGroup>
              )}
              {error && (
                <CommandGroup>
                  <div className="flex h-full w-full items-center justify-center">
                    <span className="text-destructive">{error.message}</span>
                  </div>
                </CommandGroup>
              )}
              {options.map((option) => (
                <CommandItem
                  value={option.name}
                  key={option.id.toString()}
                  onSelect={() => onChange(option.id)}
                >
                  {option.name}
                  <CheckIcon
                    className={cn(
                      'ml-auto h-4 w-4',
                      value === option.id ? 'opacity-100' : 'opacity-0'
                    )}
                  />
                </CommandItem>
              ))}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

// Example usage for drivers
function SelectSingleDriver({
  value,
  onChange,
  disabled,
  className,
}: ValueProps<number>) {
  const { companyId } = useAuth();
  const {
    data = [],
    isLoading,
    error,
  } = trpc.driver.list.useQuery({ companyId }, { initialData: [] });

  const options: Option<number>[] = data.map((driver) => ({
    id: driver.id,
    name: driver.driverFullname,
  }));

  return (
    <SelectSingle<number>
      value={value}
      onChange={onChange}
      options={options}
      isLoading={isLoading}
      error={error}
      disabled={disabled}
      className={className}
    />
  );
}

export { SelectSingle, SelectSingleDriver };
