import './instrument';
import '@/lib/firebaseClient';

import React, { StrictMode } from 'react';

import i18n from '@/lib/i18nInstance';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import { createRoot } from 'react-dom/client';
import { Toaster } from './components/ui/sonner';

const el = document.getElementById('root');
if (!el) {
  throw new Error('Root element not found');
}

const root = createRoot(el);
root.render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <App />
      <Toaster />
    </I18nextProvider>
  </StrictMode>
);
