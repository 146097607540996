import { trpc } from '@/api/trpc';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import { useAuth } from '@/contexts/Global/AuthContext';
import {
  addSeconds,
  differenceInSeconds,
  endOfDay,
  endOfYear,
  startOfDay,
  startOfYear,
} from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Form } from '@/components/ui/form';
import EquipmentSharingForm, {
  EquipmentSharingFormValues,
} from './EquipmentSharingForm';

type EquipmentSharingAddDialogProps = {
  isOpen: boolean;
  closeDialog: () => void;
  equipmentId: string;
};

const formSchema = z.object({
  startDate: z.date(),
  endDate: z.date(),
  weekdays: z.array(z.number().min(1).max(7)).min(1),
  allDay: z.boolean(),
  startTime: z.date(),
  endTime: z.date(),
  company: z.object({
    name: z.string(),
    organizationNumber: z.string(),
  }),
});

type FormValues = z.infer<typeof formSchema>;

const EquipmentSharingAddDialog: React.FC<EquipmentSharingAddDialogProps> = ({
  isOpen,
  closeDialog,
  equipmentId,
}) => {
  const { t } = useTranslation();
  const auth = useAuth();

  const share = trpc.equipmentSharing.create.useMutation();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      startDate: startOfYear(new Date()),
      endDate: addSeconds(endOfYear(new Date()), 1),
      weekdays: [1, 2, 3, 4, 5, 6, 7],
      allDay: true,
      startTime: startOfDay(new Date()),
      endTime: endOfDay(new Date()),
    },
  });

  const onShare = (values: FormValues) => {
    share.mutate(
      {
        companyId: auth.companyId,
        equipmentId,
        shareWithCompanyOrganizationNumber: values.company.organizationNumber,
        period: {
          startDate: values.startDate.toISOString(),
          endDate: values.endDate.toISOString(),
          timeOfDay: values.allDay
            ? null
            : {
                startTime: differenceInSeconds(
                  values.startTime,
                  startOfDay(values.startTime)
                ),
                endTime: differenceInSeconds(
                  values.endTime,
                  startOfDay(values.endTime)
                ),
              },
          weekdays: values.weekdays,
        },
      },
      {
        onSuccess: () => {
          closeDialog();
        },
      }
    );
  };

  return (
    <Dialog open={isOpen} onOpenChange={closeDialog}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t('equipment.EquipmentSharingAddDialog.title')}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onShare)} className="space-y-4">
            <EquipmentSharingForm
              form={
                form as unknown as UseFormReturn<EquipmentSharingFormValues>
              }
            />
            <DialogFooter>
              <Button type="button" variant="secondary" onClick={closeDialog}>
                {t('common.cancelText')}
              </Button>
              <TKConfirmButton
                dialogType="share"
                isLoading={share.isLoading}
                onClick={form.handleSubmit(onShare)}
                disabled={
                  !form.watch('company') || form.watch('weekdays').length === 0
                }
              />
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default EquipmentSharingAddDialog;
