import React, { useState } from 'react';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/api/trpc';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { MoreHorizontal } from 'lucide-react';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';
import { DataTable } from '@/components/Table/data-table';
import UpdateDriverFormDialog from './EditDriverDialog';
import { toast } from 'sonner';
import { useDialog } from '@/contexts/Global/Dialog/DialogContext';
import { CollapsedBadges } from '@/components/Badges/CollapsedBadges';
import { Badge } from '@/components/ui/badge';
import CreateDriverDialog from './CreateDriverDialog';
import { useRouter } from '@/hooks/common/useRouter';
import upperFirst from 'lodash/upperFirst';
import { cn } from '@/lib/utils';

function DriversPage(): JSX.Element {
  const { t } = useTranslation();
  const { companyId } = useAuth();
  const dialog = useDialog();
  const { query, history } = useRouter();

  const driversQuery = trpc.driver.list.useQuery({ companyId });
  const drivers = driversQuery?.data || [];

  const [updateDialogOpen, setUpdateDialogOpen] = useState(
    () => !!query.editId
  );
  const [selectedDriverId, setSelectedDriverId] = useState<number | null>(() =>
    query.editId ? Number(query.editId) : null
  );

  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const deleteMutation = trpc.driver.deleteById.useMutation({
    onSuccess() {
      toast.success(
        upperFirst(t('common.api.delete.success', { type: t('drivers.base') }))
      );
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  function handleOpenUpdateDialog(driverId: number) {
    setSelectedDriverId(driverId);
    setUpdateDialogOpen(true);
  }

  function handleDeleteDriver(driver: { id: number; driverFullname: string }) {
    dialog({
      title: t('drivers.deleteDriverDialog.title', {
        name: driver.driverFullname,
      }),
      description: t('drivers.deleteDriverDialog.description', {
        name: driver.driverFullname,
      }),
      dialogType: 'delete',
    }).then(() => deleteMutation.mutate({ id: driver.id, companyId }));
  }

  const wrappedData = drivers.map((driver) => ({
    ...driver,
    name: driver.driverFullname,
    phone: driver.phone,
    tachoIds: driver.driverCards.map((card) => card.tachoDriverId),
  }));

  return (
    <DashboardLayout
      AppBarComponent={
        <TKPageAppBar title={t('drivers.title')}>
          <Button onClick={() => setCreateDialogOpen(true)}>
            {t('drivers.addDriver')}
          </Button>
        </TKPageAppBar>
      }
      noScroll
    >
      <DataTable
        columns={[
          {
            id: 'name',
            title: t('drivers.table.name'),
            enableSorting: true,
            render: (row) => {
              return (
                <span
                  className={cn(
                    !row.active && 'text-muted-foreground'
                  )}
                >
                  {row.driverFullname}
                  {!row.active && (
                    <Badge variant="secondary" className="ml-2">
                      {t('drivers.inactive')}
                    </Badge>
                  )}
                </span>
              );
            },
          },
          {
            id: 'email',
            title: t('drivers.table.email'),
            filter: false,
            render: (row) => {
              if (!row.email) return null;
              return (
                <a
                  href={`mailto:${row.email}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                >
                  {row.email}
                </a>
              );
            },
          },
          {
            id: 'phone',
            title: t('drivers.table.phone'),
            filter: false,
            render: (row) => {
              if (!row.phone) return null;
              return (
                <a href={`tel:${row.phone}`} className="hover:underline">
                  {row.phone}
                </a>
              );
            },
          },
          {
            id: 'tachoIds',
            title: `${t('drivers.overview.driverId')}`.toLocaleLowerCase(),
            filter: false,
            render: (row) => {
              const tachoIds = row.tachoIds;
              if (!tachoIds || tachoIds.length === 0) return '-';
              return (
                <CollapsedBadges
                  items={tachoIds}
                  collapseAt={2}
                  variant="secondary"
                />
              );
            },
          },
          {
            id: 'id',
            title: '',
            size: 40,
            render: (row) => (
              <DriverActionsMenu
                driver={row}
                onEdit={() => handleOpenUpdateDialog(row.id)}
                onDelete={() => handleDeleteDriver(row)}
              />
            ),
          },
        ]}
        data={wrappedData}
        searchColumn="name"
        isLoading={driversQuery.isLoading}
        error={driversQuery.error}
        paginationPageSize={100}
      />
      {selectedDriverId && (
        <UpdateDriverFormDialog
          open={updateDialogOpen}
          setOpen={(open) => {
            setUpdateDialogOpen(open);
            if (!open) {
              setSelectedDriverId(null);
              history.replace('/driver');
            }
          }}
          driverId={selectedDriverId}
        />
      )}
      <CreateDriverDialog
        open={createDialogOpen}
        setOpen={setCreateDialogOpen}
      />
    </DashboardLayout>
  );
}

export default DriversPage;

interface DriverActionsMenuProps {
  driver: {
    id: number;
    driverFullname: string;
    email: string | null;
  };
  onEdit: () => void;
  onDelete: () => void;
}

function DriverActionsMenu({
  driver,
  onEdit,
  onDelete,
}: DriverActionsMenuProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="group opacity-0 transition-all group-hover/row:opacity-100"
          data-testid={`driver-${driver.email}-actions-menu`}
        >
          <MoreHorizontal className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem
          onClick={onEdit}
          data-testid={`driver-options-${driver.email}-update-menu`}
        >
          {t('common.edit')}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={onDelete}
          data-testid={`driver-options-${driver.email}-delete-menu`}
        >
          {t('common.delete')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
