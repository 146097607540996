import React from 'react';
import { useTranslation } from 'react-i18next';
import { comparisons, getActiveComparison } from './getActiveComparison';
import { CompareFiltersProps } from './CompareFilters';
import { useCompany } from '@/hooks/auth/useCompany';
import { Button } from '@/components/ui/button';

function CompareSettingsList({
  groupDrivers,
  groupEquipment,
  groupProjects,
  handleChange,
}: CompareFiltersProps) {
  const { t } = useTranslation();
  const company = useCompany();

  const filteredComparisons = comparisons.filter((comparison) => {
    // hide projects comparison if it's disabled in the company
    if (!company.data?.featureEnableProjects && comparison === 'projects') {
      return false;
    }

    return true;
  });

  const handleSetCompare = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const comparison = e.currentTarget.dataset.comparison;
    switch (comparison) {
      // No comparison
      case 'total':
        handleChange({
          groupDrivers: true,
          groupEquipment: true,
          groupProjects: true,
        });
        break;
      // Compare equipment
      case 'equipment':
        handleChange({
          groupDrivers: true,
          groupEquipment: false,
          groupProjects: true,
        });
        break;
      // Compare drivers
      case 'drivers':
        handleChange({
          groupDrivers: false,
          groupEquipment: true,
          groupProjects: true,
        });
        break;
      // Compare projects
      case 'projects':
        handleChange({
          groupDrivers: true,
          groupEquipment: true,
          groupProjects: false,
        });
        break;
    }
  };

  return (
    <div className="flex flex-col mt-2 w-full">
      <div className="flex-grow">
        {filteredComparisons.map((currentComparison) => {
          const isActive =
            getActiveComparison({
              groupDrivers,
              groupEquipment,
              groupProjects,
            }) === currentComparison;
          const key = `comparison-filter-${currentComparison}`;
          return (
            <Button
              key={key}
              type="button"
              variant={isActive ? 'secondary' : 'ghost'}
              className="w-full justify-start text-left"
              data-comparison={currentComparison}
              onClick={handleSetCompare}
              data-testid={key}
            >
              {t(`filters.compare.${currentComparison}`)}
            </Button>
          );
        })}
      </div>
    </div>
  );
}

export default CompareSettingsList;
