import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@/types/common/select';
import { useDateFns } from '@/hooks/common/useDateFns';
import { Checkbox } from '@/components/ui/checkbox';
import { Button } from '@/components/ui/button';
import { TypographySmall } from '@/components/ui/typography';
import { Label } from '@/components/ui/label';

type TKWeekdaysPickerProps = {
  label?: string;
  selectedWeekdays: null | number[];
  handleWeekdaysChange: (weekdays: SelectOption[]) => void;
  fullWidth?: boolean;
  flexDirection?: React.CSSProperties['flexDirection'];
  hideSelectButtons?: boolean;
};

const TKWeekdaysPicker: React.FC<TKWeekdaysPickerProps> = ({
  label,
  handleWeekdaysChange,
  selectedWeekdays = [],
  fullWidth = false,
  flexDirection = 'column',
  hideSelectButtons = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const { weekdaysOptions } = useDateFns();
  const selected = selectedWeekdays || [];
  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleChange = (index: number) => {
    let days: number[] = [...selected];

    if (selected.includes(index)) {
      days = selected.filter((dayIndex) => dayIndex !== index);
    } else {
      days.push(index);
    }
    days = days.sort((a, b) => a - b);

    const newDaysOptions: SelectOption[] = [];
    days.forEach((newDayIndex) => {
      const findDay = weekdaysOptions.find(
        (weekday) => weekday.value === newDayIndex
      );
      if (findDay) newDaysOptions.push(findDay);
    });
    handleWeekdaysChange(newDaysOptions);
  };

  const handleToggleAll = () => {
    if (isAllSelected) {
      handleWeekdaysChange([]);
    } else {
      handleWeekdaysChange([...weekdaysOptions]);
    }
    setIsAllSelected(!isAllSelected);
  };

  return (
    <div>
      <div
        className={`${fullWidth ? 'w-full' : 'w-auto'} flex flex-wrap gap-2 ${
          flexDirection === 'column' ? 'flex-col' : 'flex-row'
        }`}
      >
        {label && (
          <TypographySmall className="text-muted-foreground w-full">
            {label}
          </TypographySmall>
        )}
        {weekdaysOptions.map((day) => (
          <div key={day.value} className="flex items-center space-x-2">
            <Checkbox
              id={`day-${day.value}`}
              checked={selected.includes(day.value)}
              onCheckedChange={() => handleChange(day.value)}
            />
            <Label
              htmlFor={`day-${day.value}`}
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {day.label}
            </Label>
          </div>
        ))}
      </div>
      {!hideSelectButtons && (
        <div className="mt-2">
          <Button
            variant="outline"
            onClick={handleToggleAll}
            data-testid="weekdays-picker-toggle-all"
          >
            {isAllSelected ? t('common.deselectAll') : t('common.selectAll')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default memo(TKWeekdaysPicker);
