import React, { useState, useMemo } from 'react';
import { MoreHorizontal } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

interface TKAppBarActionsComponentProps {
  actionsButtonText?: string;
  children?: React.ReactNode | React.ReactNode[];
}

const TKAppBarActions: React.FC<TKAppBarActionsComponentProps> = ({
  actionsButtonText,
  children,
}) => {
  const [open, setOpen] = useState(false);

  const validChildren = useMemo(() => {
    const _items: React.ReactNode[] = [];
    if (Array.isArray(children)) {
      for (const child of children) {
        if (child) _items.push(child);
      }
    } else if (children) {
      _items.push(children);
    }
    return _items;
  }, [children]);

  return (
    <>
      <div className="sm:hidden">
        {validChildren.length > 1 ? (
          <DropdownMenu open={open} onOpenChange={setOpen}>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                aria-label={actionsButtonText}
              >
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {validChildren}
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          validChildren
        )}
      </div>

      <div className="hidden sm:flex items-center justify-end flex-wrap gap-2">
        {validChildren}
      </div>
    </>
  );
};

export default TKAppBarActions;
