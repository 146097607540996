import React from 'react';
import { cn } from '@/lib/utils';
import TKAppBarActions from './TKAppBarActions';
import { TypographyH4 } from '@/components/ui/typography';
import { Button } from '@/components/ui/button';
import { ArrowLeft } from 'lucide-react';

interface TKPageAppBarProps {
  titledataTest?: string;

  /**
   * The title/content of this appbar.
   */
  title?: JSX.Element | string;

  onBackButtonClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * When true, this will add left padding to the title/content of the appbar, this should be mainly used when
   */
  withContentPadding?: boolean;

  variant?: 'divider' | 'elevation';

  /**
   * Decides the minHeight of the appbar in pixels
   */
  minHeight?: number | null;

  /**
   * Changes the background color of the appbar
   */
  backgroundColor?: string;

  /**
   * Displays the given component as a last child inside the root element of this component
   */
  endComponent?: React.FC | JSX.Element | false;

  /**
   * Disables default padding on the Y axis, it is `false` by default.
   */
  disablePaddingY?: boolean;

  /**
   * Disables default padding on the X axis, it is `false` by default.
   */
  disablePaddingX?: boolean;

  children?: React.ReactNode | React.ReactNode[];
}

function TKPageAppBar({
  title,
  titledataTest,
  onBackButtonClick,
  backgroundColor,
  withContentPadding = false,
  disablePaddingX = false,
  disablePaddingY = false,
  minHeight = 64,
  children,
}: TKPageAppBarProps): JSX.Element {
  return (
    <div
      className={cn(
        'flex flex-col justify-between w-full',
        !disablePaddingY && 'py-2',
        !disablePaddingX && 'px-4'
      )}
      style={{
        minHeight: minHeight ?? undefined,
        backgroundColor,
      }}
    >
      <div className="flex w-full flex-1 justify-between items-center flex-nowrap">
        <div className="flex justify-between items-center w-full">
          <div
            className={cn(
              'flex items-center flex-nowrap',
              withContentPadding && 'pl-8'
            )}
          >
            {onBackButtonClick && (
              <Button
                variant="ghost"
                size="icon"
                onClick={onBackButtonClick}
                aria-label="back"
              >
                <ArrowLeft />
              </Button>
            )}
            {typeof title === 'string' ? (
              <TypographyH4 className="truncate" data-testid={titledataTest}>
                {title}
              </TypographyH4>
            ) : (
              title
            )}
          </div>
          <div className="flex justify-end">
            <TKAppBarActions>{children}</TKAppBarActions>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TKPageAppBar;
