import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CompanyLogoPlaceholder } from '@/assets/images/companies/undraw_dashboard_nklg.svg';
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { Role } from '@/types/common/roles';
import { Spinner } from '@/components/ui/spinner';
import { Trash2, Pencil } from 'lucide-react';
import { useUserProfile } from '@/hooks/auth/useUserProfile';
import { Button } from '@/components/ui/button';
import { TypographyP, TypographySmall } from '@/components/ui/typography';
import { cn } from '@/lib/utils';

interface Props {
  name?: string;
  role?: Role;
  companyId?: number;
  isTemplateCompany?: boolean;
  children: React.ReactNode;
  isLoading: boolean;
  isError: boolean;
  onEditCompany?: (companyId: number) => Promise<void>;
  onDeleteCompany?: (companyId: number, name: string) => Promise<void>;
}

function CompanyBaseCard({
  name,
  role,
  companyId,
  isTemplateCompany,
  children,
  isLoading,
  isError,
  onEditCompany,
  onDeleteCompany,
}: Props): JSX.Element | null {
  const { t } = useTranslation();
  const userProfile = useUserProfile();

  const showEditButton = role === Role.Owner && companyId;
  const showDeleteButton =
    userProfile?.data?.isSuperuser &&
    !isTemplateCompany &&
    companyId &&
    name &&
    role === Role.Owner;

  if (isError) return null;
  return (
    <div className="w-full p-2">
      <Card className="h-full flex flex-col" data-testid="company-card">
        {isLoading ? (
          <CardContent className="flex-grow flex justify-center items-center min-h-[320px]">
            <Spinner />
          </CardContent>
        ) : (
          <>
            <CardHeader>
              <div className="flex justify-between items-center">
                <CardTitle className="text-xl">{name}</CardTitle>
                <div className="flex space-x-2">
                  {showEditButton && onEditCompany && (
                    <Button
                      variant="ghost"
                      size="icon"
                      data-edit-company-id={companyId}
                      onClick={() => onEditCompany(companyId)}
                      className="hover:text-tkd-brand"
                    >
                      <Pencil className="h-5 w-5" />
                    </Button>
                  )}
                  {showDeleteButton && onDeleteCompany && (
                    <Button
                      variant="ghost"
                      size="icon"
                      data-delete-company-id={companyId}
                      data-testid="delete-company-button"
                      onClick={() => onDeleteCompany(companyId, name)}
                    >
                      <Trash2 className="h-5 w-5 text-destructive" />
                    </Button>
                  )}
                </div>
              </div>
              {isTemplateCompany && (
                <TypographySmall className="underline text-tkd-brand">
                  (TEMPLATE)
                </TypographySmall>
              )}
              <TypographyP className="text-muted-foreground">
                {t(`shared-roles.${role as Role}`)}
              </TypographyP>
            </CardHeader>
            <CardContent className="flex-grow">
              <div className="aspect-video">
                <CompanyLogoPlaceholder
                  className={cn('w-full h-full')}
                  viewBox="0 0 1130 868.1"
                  title={name}
                />
              </div>
            </CardContent>
            <CardFooter>{children}</CardFooter>
          </>
        )}
      </Card>
    </div>
  );
}

export default CompanyBaseCard;
