import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCompany } from '@/hooks/auth/useCompany';
import TKDatePicker from '@/components/FormControls/TKDatePicker';
import TKWeekdaysPicker from '@/components/Common/TKWeekdaysPicker';
import { ProjectRuleForm } from '@/types/company/projects';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { SelectMultipleEquipment } from '@/components/FormControls/MultipleSelect';
import { endOfDay } from 'date-fns';

interface Props {
  form: UseFormReturn<ProjectRuleForm>;
}

function CompanyProjectRuleForm({ form }: Props) {
  const { data: company } = useCompany();
  const { t } = useTranslation();
  return (
    <Form {...form}>
      <div className="space-y-4">
        <FormField
          control={form.control}
          name="equipmentIds"
          rules={{
            validate: {
              message: (value) => {
                if (!value || value.length < 1) {
                  return t('validations.equipment.minLength', { count: 1 });
                }
                return true;
              },
            },
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('company.projectRules.form.whichEquipment')}
              </FormLabel>
              <SelectMultipleEquipment
                values={field.value ?? []}
                onChange={field.onChange}
                includeSold={endOfDay(new Date()).toISOString()}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        <div>
          <FormLabel>{t('company.projectRules.form.periodToShare')}</FormLabel>
          <div className="flex space-x-4">
            <FormField
              control={form.control}
              name="fromTimestamp"
              defaultValue={form.getValues('fromTimestamp')}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('company.projectRules.form.startDate')}
                  </FormLabel>
                  <TKDatePicker
                    minDate={company?.createdAt}
                    selectedDate={field.value ? new Date(field.value) : null}
                    handleDateChange={(date) => {
                      if (date) field.onChange(date.toISOString());
                    }}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="toTimestamp"
              defaultValue={form.getValues('toTimestamp')}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    {t('company.projectRules.form.endDate')}
                  </FormLabel>
                  <TKDatePicker
                    minDate={company?.createdAt}
                    selectedDate={field.value ? new Date(field.value) : null}
                    handleDateChange={(date) => {
                      if (date) field.onChange(date.toISOString());
                    }}
                    disableFuture={false}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>

        <FormField
          control={form.control}
          name="weekdays"
          defaultValue={form.getValues('weekdays')}
          rules={{
            validate: {
              message: (value) => {
                if (!value || value.length < 1) {
                  return t('validations.weekdays.minLength', { count: 1 });
                }
                return true;
              },
            },
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                {t('company.projectRules.form.daysToInclude')}
              </FormLabel>
              <TKWeekdaysPicker
                selectedWeekdays={field.value}
                handleWeekdaysChange={(w) =>
                  field.onChange(w.map((v) => parseInt(v.value.toString())))
                }
              />
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </Form>
  );
}

export default CompanyProjectRuleForm;
