import { useEffect } from 'react';

const baseTabText = 'TK Dashboard';

/**
 * Changes the current tab title.
 * Keeps `TK Dashboard` as the base appended text at the end of the text.
 *
 * @param {string} title text to prepend before the base text
 */
const useDocumentTitle = (title: string | null) => {
  useEffect(() => {
    document.title = title ? `${title} - ${baseTabText}` : baseTabText;
  }, [title]);
};

export { useDocumentTitle };
