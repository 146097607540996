import React from 'react';
import TKTextWithLabel from '@/components/Common/TKTextWithLabel';
import { useTranslation } from 'react-i18next';

interface Props {
  fuelLevelPercentage?: number | null;
  sampledAt?: string | null;
}

function FuelLevelField({ fuelLevelPercentage, sampledAt }: Props) {
  const { t } = useTranslation();

  const text = fuelLevelPercentage
    ? `${fuelLevelPercentage}%`
    : t('common.notAvailable');

  return (
    <TKTextWithLabel
      label={t('equipment.columns.fuelLevel')}
      timestamp={sampledAt}
      text={text}
    />
  );
}

export default FuelLevelField;
