import { DateFiltersValue } from '@/components/FilterDropdowns/DateFilters/DateFilters';
import {
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

interface FilterDateTimeRange {
  fromTime: string | null;
  toTime: string | null;
}

function calculateFilterDates(filters: DateFiltersValue): FilterDateTimeRange {
  // handle custom case
  if (filters.dateType === 'custom') {
    return {
      fromTime: filters.fromTime
        ? startOfDay(new Date(filters.fromTime)).toISOString()
        : null,
      toTime: filters.toTime
        ? startOfDay(new Date(filters.toTime)).toISOString()
        : null,
    };
  }
  // must be dateType preset
  switch (filters.presetType) {
    case 'allTime':
      return { fromTime: null, toTime: null };
    case 'lastDays':
      return {
        fromTime: startOfDay(
          subDays(new Date(), filters.presetAmount)
        ).toISOString(),
        toTime: startOfDay(new Date()).toISOString(),
      };
    case 'lastMonths':
      return {
        fromTime: startOfMonth(
          subMonths(new Date(), filters.presetAmount)
        ).toISOString(),
        toTime: startOfMonth(new Date()).toISOString(),
      };
    case 'lastYears':
      return {
        fromTime: startOfYear(
          subYears(new Date(), filters.presetAmount)
        ).toISOString(),
        toTime: startOfYear(new Date()).toISOString(),
      };
    case 'thisWeek':
      return {
        fromTime: startOfWeek(new Date(), { weekStartsOn: 1 }).toISOString(),
        toTime: endOfWeek(new Date(), { weekStartsOn: 1 }).toISOString(),
      };
    case 'thisMonth':
      return {
        fromTime: startOfMonth(new Date()).toISOString(),
        toTime: endOfMonth(new Date()).toISOString(),
      };
    case 'thisYear':
      return {
        fromTime: startOfYear(new Date()).toISOString(),
        toTime: endOfYear(new Date()).toISOString(),
      };
    case 'previousWeek':
      return {
        fromTime: startOfWeek(subWeeks(new Date(), 1), {
          weekStartsOn: 1,
        }).toISOString(),
        toTime: endOfWeek(subWeeks(new Date(), 1), {
          weekStartsOn: 1,
        }).toISOString(),
      };
    case 'previousMonth':
      return {
        fromTime: startOfMonth(subMonths(new Date(), 1)).toISOString(),
        toTime: endOfMonth(subMonths(new Date(), 1)).toISOString(),
      };
    case 'previousYear':
      return {
        fromTime: startOfYear(subYears(new Date(), 1)).toISOString(),
        toTime: endOfYear(subYears(new Date(), 1)).toISOString(),
      };
  }
}

export { calculateFilterDates };
