import React from 'react';
import { MultistepItem } from '.';
import CheckboxItem from './CheckboxItem';

export type MultistepElementsGeneratorProps = {
  list: MultistepItem[];
  steps: Array<string | number>;

  handleSelectElement: (item: MultistepItem, checked: boolean) => void;

  handleSelectCategory: (category: string, checked: boolean) => void;

  handleAddStep: (index: number) => void;
};

const MultistepElementsGenerator: React.FC<MultistepElementsGeneratorProps> = ({
  list,
  handleAddStep,
  handleSelectElement,
  handleSelectCategory,
}) => {
  return (
    <>
      {list.map((el, i) => {
        const hasSubItems = !!el.items?.length;
        const subItems = el.items || [];

        const hasCheckedSubItems =
          hasSubItems && !!subItems.some((subItem) => subItem.active);
        return (
          <CheckboxItem
            key={`${el.value}_${el.label}_${i}`}
            el={el}
            hasCheckedSubItems={hasCheckedSubItems}
            hasSubItems={hasSubItems}
            handleSelectCategory={handleSelectCategory}
            handleSelectElement={handleSelectElement}
            handleAddStep={handleAddStep}
            variant={hasSubItems ? 'expand' : 'select'}
            index={i}
          />
        );
      })}
    </>
  );
};

export default MultistepElementsGenerator;
