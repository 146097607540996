import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchSorter } from 'match-sorter';
import { trpc } from '@/api/trpc';
import TKDataHandler from '@/components/Progress/TKDataHandler';
import IntegrationTypeCard from './IntegrationTypeCard';
import { IntegrationTypeEnum } from '@/types/company/integrations';
import { useAuth } from '@/contexts/Global/AuthContext';
import { Search } from 'lucide-react';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { TypographyH4 } from '@/components/ui/typography';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  typeId: IntegrationTypeEnum | null;
  setTypeId: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
}
function ChooseIntegrationTypeDialog({
  open,
  setOpen,
  setTypeId,
}: Props): JSX.Element {
  const { companyId } = useAuth();
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const categoriesQuery = trpc.integration.listCategories.useQuery({
    companyId,
  });
  const items = trpc.integration.listTypes.useQuery({ companyId });

  const filteredList = matchSorter(items.data ?? [], search, {
    keys: ['name', 'id'],
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-[900px] max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{t('company.integrations.title')}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col space-y-4">
          <div className="relative">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              id="search-integration"
              placeholder={t('company.integrations.searchIntegration')}
              className="pl-8"
            />
          </div>

          <div>
            <TKDataHandler
              data={categoriesQuery.data}
              loading={categoriesQuery.isLoading}
              error={categoriesQuery.error}
              entryText={t('company.integrations.base')}
            >
              {categoriesQuery?.data?.map((item) => {
                const hasAnyIntegrationInCategory = filteredList.some(
                  (int) => int.categoryId === item.id
                );
                if (!hasAnyIntegrationInCategory) return null;

                return (
                  <div
                    key={`integration_category_${item.id}`}
                    className="space-y-2 mb-6"
                  >
                    <TypographyH4>{item.label}</TypographyH4>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                      {filteredList.map((integration) => {
                        if (integration.categoryId !== item.id) return null;
                        return (
                          <IntegrationTypeCard
                            key={`integration_card_${integration.id}`}
                            integration={integration}
                            setTypeId={setTypeId}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </TKDataHandler>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ChooseIntegrationTypeDialog;
