import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IntegrationTypeEnum,
  IntegrationTypeItem,
} from '@/types/company/integrations';
import { Card, CardContent, CardFooter } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { TypographyH4 } from '@/components/ui/typography';

// Logos
import CatLogo from '@/assets/images/integrations/CatLogo.png';
import ScaniaFmsLogo from '@/assets/images/integrations/ScaniaFmsLogo.png';
import VolvoCaretrackLogo from '@/assets/images/integrations/VolvoCaretrackLogo.png';
import VolvoConnectLogo from '@/assets/images/integrations/VolvoConnectLogo.png';
import TachoOnlineLogo from '@/assets/images/integrations/TachoOnlineLogo.png';
import TelesporLogo from '@/assets/images/integrations/TelesporLogo.png';
import MercedesFleetboardLogo from '@/assets/images/integrations/MercedesLogo.png';
import ManRioLogo from '@/assets/images/integrations/ManLogo.png';
import OpterLogo from '@/assets/images/integrations/OpterLogo.png';

const LOGO_LIST: Record<`${IntegrationTypeEnum}`, string> = {
  [IntegrationTypeEnum.Cat]: CatLogo,
  [IntegrationTypeEnum.ScaniaFms]: ScaniaFmsLogo,
  [IntegrationTypeEnum.VolvoCaretrack]: VolvoCaretrackLogo,
  [IntegrationTypeEnum.VolvoConnect]: VolvoConnectLogo,
  [IntegrationTypeEnum.VolvoCeAemp_2_0]: VolvoCaretrackLogo,
  [IntegrationTypeEnum.Tachoonline]: TachoOnlineLogo,
  [IntegrationTypeEnum.Telespor]: TelesporLogo,
  [IntegrationTypeEnum.MercedesFleetboard]: MercedesFleetboardLogo,
  [IntegrationTypeEnum.ManRio]: ManRioLogo,
  [IntegrationTypeEnum.Opter]: OpterLogo,
};

interface Props {
  integration: IntegrationTypeItem;
  setTypeId: React.Dispatch<React.SetStateAction<IntegrationTypeEnum | null>>;
}

function IntegrationTypeCard({ integration, setTypeId }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <Card className="h-full flex flex-col">
        <CardContent className="flex-grow flex flex-col p-4">
          <TypographyH4 className="mb-4">{integration.name}</TypographyH4>
          <div className="flex-grow flex justify-center items-center">
            <img
              draggable={false}
              src={LOGO_LIST[integration.id]}
              alt={integration.name}
              className="max-w-[50%] max-h-[100px] object-contain"
            />
          </div>
        </CardContent>
        <CardFooter className="p-4 flex justify-center">
          <Button
            className="w-full"
            data-integration={integration.id}
            onClick={() => setTypeId(integration.id)}
          >
            {t('company.integrations.choosePage.add')}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
}

export default IntegrationTypeCard;
