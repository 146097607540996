import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import {
  IntegrationConfig,
  IntegrationConfigFormData,
  IntegrationTypeEnum,
} from '@/types/company/integrations';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@/components/ui/form';
import { TypographyMuted, TypographyH4 } from '@/components/ui/typography';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import { useAuth } from '@/contexts/Global/AuthContext';
import { ArrowLeft, ExternalLink, RefreshCw } from 'lucide-react';

interface Props {
  integrationTypeId: IntegrationTypeEnum;
  handleClearSelectedType?: () => void;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dialogType: 'create' | 'update';
  defaultValues?: IntegrationConfig;
}

// generate hash in browser
function generateSecureCryptoHash(length = 16) {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  const hexString = Array.from(array, (byte) =>
    byte.toString(16).padStart(2, '0')
  ).join('');
  return hexString;
}

function IntegrationFormDialog({
  dialogType,
  defaultValues,
  integrationTypeId,
  handleClearSelectedType,
  open,
  setOpen,
}: Props) {
  const { companyId } = useAuth();

  const { t } = useTranslation();
  const form = useForm<IntegrationConfigFormData>({
    defaultValues: defaultValues || {
      secret:
        integrationTypeId === IntegrationTypeEnum.Opter
          ? generateSecureCryptoHash()
          : undefined,
    },
  });

  const createMutation = trpc.integration.insert.useMutation({
    onSuccess() {
      toast.success(
        t(`common.api.insert.success`, { type: t('company.integrations.base') })
      );
      form.reset();
      setOpen(false);
      handleClearSelectedType?.();
    },
    onError(error) {
      toast.error(error.message);
    },
  });
  const updateMutation = trpc.integration.update.useMutation({
    onSuccess() {
      toast.success(
        t(`common.api.update.success`, { type: t('company.integrations.base') })
      );
      form.reset();
      setOpen(false);
      handleClearSelectedType?.();
    },
    onError(error) {
      toast.error(error.message);
    },
  });

  const integrationType = trpc.integration.typeById.useQuery({
    id: integrationTypeId,
    companyId,
  });

  const isLoading = createMutation.isLoading || updateMutation.isLoading;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <div className="flex items-center">
            {handleClearSelectedType && (
              <Button
                variant="ghost"
                size="icon"
                onClick={handleClearSelectedType}
                aria-label="back"
              >
                <ArrowLeft />
              </Button>
            )}
            <DialogTitle>
              {dialogType === 'create'
                ? t('company.integrations.createIntegrationDialog.title')
                : t('company.integrations.updateIntegrationDialog.title')}
            </DialogTitle>
          </div>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) => {
              if (dialogType === 'create') {
                createMutation.mutate({
                  clientId: data.clientId,
                  name: data.name,
                  secret: data.secret,
                  type: integrationTypeId,
                  customFields: data.customFields,
                  companyId,
                });
              } else if (dialogType === 'update' && defaultValues?.id) {
                updateMutation.mutate({
                  id: defaultValues.id,
                  name: data.name ?? undefined,
                  customFields: data.customFields,
                  secret: data.secret,
                  companyId,
                  clientId: data.clientId,
                  type: integrationTypeId,
                });
              }
            })}
          >
            <div className="space-y-4">
              <TypographyH4>{integrationType?.data?.name}</TypographyH4>

              <FormField
                control={form.control}
                name="name"
                rules={{ required: t('validations.integrationName.required') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t('validations.integrationName.label')}
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        data-testid="company-integration-name-input"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {integrationTypeId !== IntegrationTypeEnum.Opter && (
                <FormField
                  control={form.control}
                  name="clientId"
                  rules={{ required: t('validations.clientId.required') }}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('validations.clientId.label')}</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          data-testid="company-integration-username-input"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <FormField
                control={form.control}
                name="secret"
                rules={{ required: t('validations.secret.required') }}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('validations.secret.label')}</FormLabel>
                    <div className="flex items-center space-x-2">
                      <FormControl>
                        <Input
                          {...field}
                          data-testid="company-integration-password-input"
                        />
                      </FormControl>
                      {integrationTypeId === IntegrationTypeEnum.Opter && (
                        <Button
                          type="button"
                          variant="outline"
                          size="icon"
                          onClick={() => {
                            const secret = generateSecureCryptoHash();
                            form.setValue('secret', secret);
                          }}
                        >
                          <RefreshCw className="h-4 w-4" />
                        </Button>
                      )}
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {integrationTypeId === IntegrationTypeEnum.VolvoCeAemp_2_0 && (
                <div className="space-y-4">
                  <FormField
                    control={form.control}
                    name="customFields.username"
                    rules={{ required: 'Username is required' }}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Username</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="customFields.password"
                    rules={{ required: 'Password is required' }}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Password</FormLabel>
                        <FormControl>
                          <Input {...field} type="password" />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="customFields.X-IBM-Client-Id"
                    rules={{ required: 'X-IBM-Client-Id is required' }}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>X-IBM-CLIENT-ID</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}

              {integrationTypeId === IntegrationTypeEnum.ManRio && (
                <FormField
                  control={form.control}
                  name="customFields.rioIntegrationId"
                  rules={{ required: 'Rio integration ID is required' }}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>RIO Integration ID</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              {integrationTypeId === IntegrationTypeEnum.VolvoConnect && (
                <>
                  <FormField
                    control={form.control}
                    name="customFields.timezone"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Time Zone</FormLabel>
                        <FormControl>
                          <Input {...field} />
                        </FormControl>
                        <TypographyMuted>
                          Timezone is required for Volvo Connect&apos;s Driver
                          Score API (the timezone can be found in Volvo&apos;s
                          portal).
                          <br />
                          If not provided, it will default to the company
                          timezone.
                        </TypographyMuted>
                        <a
                          href="https://en.wikipedia.org/wiki/List_of_tz_database_time_zones"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="hover:underline flex items-center"
                        >
                          See list of time zones
                          <ExternalLink className="ml-1 size-4" />
                        </a>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </>
              )}
            </div>
            <DialogFooter className="mt-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  setOpen(false);
                  handleClearSelectedType?.();
                }}
                disabled={isLoading}
              >
                {t('common.cancelText')}
              </Button>
              <TKConfirmButton
                dialogType={dialogType}
                isLoading={isLoading}
                disabled={isLoading}
                data-testid="confirm-company-integration"
              />
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

export default IntegrationFormDialog;
