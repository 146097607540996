import React from 'react';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import { useTranslation } from 'react-i18next';
import SVNextPeriodicInspectionReport from './Widgets/SVNextPeriodicInspectionReport';
import ServiceDistanceReport from './Widgets/ServiceDistanceReport';
import DriverCardActivityReport from './Widgets/DriverCardActivityReport';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';
import DriverScoreGradeReport from './Widgets/DriverScoreGradeReport';
import DetectedDriverCardReport from './Widgets/DetectedDriverCardReport';

/**
 * Main entrypoint for Homepage, this currently only contains some static widgets.
 */
const Homepage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <DashboardLayout
      disablePadding
      AppBarComponent={
        <TKPageAppBar title={t('homepage.title')}></TKPageAppBar>
      }
    >
      <div className="grid grid-cols-1 gap-4 p-4">
        <div className="col-span-1">
          <DriverScoreGradeReport />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <DetectedDriverCardReport />
          <DriverCardActivityReport />
          <SVNextPeriodicInspectionReport />
          <ServiceDistanceReport />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Homepage;