import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@/hooks/common/useRouter';
import { useAuth } from '@/contexts/Global/AuthContext';
import { firebaseAuth } from '@/lib/firebaseClient';
import { useFirebaseChecks } from '@/hooks/auth/useFirebaseChecks';
import { trpc } from '@/api/trpc';
import { useAuthLogout } from '@/hooks/auth/useAuthLogout';
import { toast } from 'sonner';

import AuthLayout from '@/components/Layouts/AuthLayout';
import UserProfileForm from '@/components/UserProfile/UserProfileForm';
import { Button } from '@/components/ui/button';
import { TypographyH4, TypographyP } from '@/components/ui/typography';

const AuthFinishSignUpProfile: React.FC = () => {
  const router = useRouter();
  const { logout } = useAuthLogout();
  const { user } = useAuth();

  const { t } = useTranslation();

  const signUpMutation = trpc.user.insert.useMutation({
    async onMutate() {
      await firebaseAuth.currentUser?.getIdToken(true);
    },
    async onSuccess() {
      toast.success(t('signup.userCreateSuccess'));
      router.push('/companies', {});
    },
    async onError(error) {
      toast.error(error?.message ?? t('signup.userCreateError'));
    },
  });

  const { isLogged, hasProfileFinished, emailVerified } = useFirebaseChecks();

  useEffect(() => {
    if (hasProfileFinished) {
      router.replace('/', {});
    }

    if (isLogged && !hasProfileFinished && !emailVerified) {
      router.replace('/auth-signup', {});
    }

    if (!isLogged) {
      router.replace('/auth-login', {});
    }
  }, [emailVerified, hasProfileFinished, isLogged, router]);

  return (
    <AuthLayout>
      <div className="flex flex-col space-y-6 w-full max-w-md">
        <div className="flex flex-col space-y-2 text-center">
          <TypographyH4>{t('signup.welcomeMessage')}</TypographyH4>
          <TypographyP>{t('signup.subtitle')}</TypographyP>
        </div>

        <UserProfileForm
          onUserFormSubmit={signUpMutation.mutate}
          showLegalCheckboxes
          defaultValues={{
            uid: user?.uid,
            email: user?.email || '',
            phone: user?.phoneNumber || '',
            address: '',
            firstName: '',
            lastName: '',
            zipCode: '',
            city: '',
            hasConsentedPrivacyPolicy: false,
            hasConsentedTermsOfService: false,
          }}
        >
          <div className="flex space-x-4 mt-4">
            <Button type="submit" isLoading={signUpMutation.isLoading}>
              {t('signup.register')}
            </Button>
            <Button type="button" variant="outline" onClick={logout}>
              {t('signup.logout')}
            </Button>
          </div>
        </UserProfileForm>
      </div>
    </AuthLayout>
  );
};

export default AuthFinishSignUpProfile;
