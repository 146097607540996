import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineChart, List } from 'lucide-react';
import { ReportChartTypes } from '@/types/analysis';
import { Button } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';

interface Props {
  chartType: ReportChartTypes;
  handleChangeChartType: (chart: ReportChartTypes) => void;
  reportId: number;
}

function ReportChartChangeHeader({ chartType, handleChangeChartType }: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex mr-4 gap-1">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={
                chartType === ReportChartTypes.Graph ? 'secondary' : 'ghost'
              }
              size="icon"
              className="size-8"
              onClick={() => handleChangeChartType(ReportChartTypes.Graph)}
              data-testid={`change-to-${ReportChartTypes.Graph}-chart`}
            >
              <LineChart
                className={cn(
                  'h-4 w-4',
                  chartType === ReportChartTypes.Graph && 'text-primary'
                )}
              />
            </Button>
          </TooltipTrigger>
          <TooltipContent>{t('analysis.chartTypes.graph')}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant={
                chartType === ReportChartTypes.List ? 'secondary' : 'ghost'
              }
              size="icon"
              className="size-8"
              onClick={() => handleChangeChartType(ReportChartTypes.List)}
              data-chart-type={ReportChartTypes.List}
              data-testid={`change-to-${ReportChartTypes.List}-chart`}
            >
              <List
                className={cn(
                  'h-4 w-4',
                  chartType === ReportChartTypes.List && 'text-primary'
                )}
              />
            </Button>
          </TooltipTrigger>
          <TooltipContent>{t('analysis.chartTypes.list')}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
}

export default ReportChartChangeHeader;
