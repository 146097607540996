import { useQuery } from '@tanstack/react-query';
import { useDebounce } from '@/hooks/common/useDebounce';

interface BrregCompany {
  organizationNumber: string;
  name: string;
}

interface Options {
  keepPreviousData: boolean;
}
export function useBrregService(
  searchTerm: string | undefined,
  options?: Options
) {
  const debouncedSearchTerm = useDebounce(searchTerm, 250);

  const query = useQuery<BrregCompany[]>(
    ['brreg', debouncedSearchTerm],
    async () => {
      const response = await fetch(
        `https://data.brreg.no/enhetsregisteret/api/enheter?navn=${debouncedSearchTerm}`
      );
      const data = await response.json();
      return (
        data?._embedded?.enheter?.map(
          (enhet: { organisasjonsnummer: string; navn: string }) => {
            return {
              organizationNumber: enhet?.organisasjonsnummer,
              name: enhet?.navn,
            };
          }
        ) ?? []
      );
    },
    {
      enabled: !!debouncedSearchTerm && debouncedSearchTerm.length > 0,
      keepPreviousData: options?.keepPreviousData,
    }
  );
  return query;
}
