import React, { useEffect, useState } from 'react';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
import { FilterIcon } from 'lucide-react';
import { useTailwindBreakpoint } from '@/hooks/common/useTailwindBreakpoint';

type FilterDropdownsLayoutProps = {
  children: React.ReactNode;
};

const FilterDropdownsLayout: React.FC<FilterDropdownsLayoutProps> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = useTailwindBreakpoint('md');

  useEffect(() => {
    if (isDesktop) {
      setIsOpen(false);
    }
  }, [isDesktop]);

  return (
    <div className="flex-1 max-w-xs md:max-w-full ml-8 md:ml-0">
      {/* Mobile view */}
      <div className="md:hidden">
        <Sheet open={isOpen} onOpenChange={setIsOpen}>
          <SheetTrigger asChild>
            <Button variant="outline" className="w-full">
              <FilterIcon className="mr-2 h-4 w-4" /> Filters
            </Button>
          </SheetTrigger>
          <SheetContent side="bottom" className="h-[80vh]">
            <ScrollArea className="h-full">
              <div className="flex flex-col space-y-4 flex-grow pt-4">
                {children}
              </div>
              <ScrollBar orientation="vertical" />
            </ScrollArea>
          </SheetContent>
        </Sheet>
      </div>

      {/* Desktop view */}
      <div className="hidden md:block">
        <ScrollArea>
          <div className="flex">{children}</div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
    </div>
  );
};

export default FilterDropdownsLayout;
