import { useTranslation } from 'react-i18next';
import { EquipmentTypeEnum } from '@/types/equipment';
import upperFirst from 'lodash/upperFirst';

interface Props {
  type: EquipmentTypeEnum;
}

function EquipmentTypeField({ type }: Props): string {
  const { t } = useTranslation();

  if (type === EquipmentTypeEnum.TRUCK) {
    return upperFirst(`${t('equipment.truckBase')}`);
  }
  if (type === EquipmentTypeEnum.MACHINE) {
    return upperFirst(`${t('equipment.machineBase')}`);
  }
  if (type === EquipmentTypeEnum.TRAILER) {
    return upperFirst(`${t('equipment.trailerBase')}`);
  }
  if (type === EquipmentTypeEnum.CAR) {
    return upperFirst(`${t('equipment.carBase')}`);
  }
  if (type === EquipmentTypeEnum.CONTAINER) {
    return upperFirst(`${t('equipment.containerBase')}`);
  }
  return '-';
}

export default EquipmentTypeField;
