import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollArea } from '@/components/ui/scroll-area';
import { TypographyH5 } from '@/components/ui/typography';
import { cn } from '@/lib/utils';
import Link from '@/components/Common/TKTypedLink';
import { useRouter } from '@/hooks/common/useRouter';
import { DrawerProvider } from '@/contexts/Global/Drawer/DrawerContext';
import TKDrawer from '@/components/Drawer/TKDrawer';
import { useUserProfile } from '@/hooks/auth/useUserProfile';
import { Path } from '@/routes/routesList';
import { useAuth } from '@/contexts/Global/AuthContext';
import { Role } from '@/types/common/roles';

export function CompanySettingsLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { t } = useTranslation();
  const router = useRouter();
  const auth = useAuth();
  const userProfile = useUserProfile();
  const isSuperuser = !!userProfile.data?.isSuperuser;

  const isOwner = auth.hasPermissions([Role.Owner]);

  const links = useMemo(() => {
    const links: { href: Path; label: string }[] = [
      {
        href: '/company-settings/projects',
        label: t('routes.companyProjects'),
      },
      {
        href: '/company-settings/groups',
        label: t('routes.companyGroups'),
      },
    ];
    if (isOwner) {
      links.push({
        href: '/company-settings/users',
        label: t('routes.companyUsers'),
      });
    }

    if (isSuperuser) {
      links.push({
        href: '/company-settings/integrations',
        label: t('routes.companyIntegrations'),
      });
    }

    return links;
  }, [t, isSuperuser, isOwner]);

  return (
    <DrawerProvider>
      <div className="flex h-full w-full overflow-hidden">
        <TKDrawer hideToggleOnDesktop>
          <div className="w-64 h-full flex flex-col">
            <div className="px-4 py-2">
              <TypographyH5>{t('routes.companySettings')}</TypographyH5>
            </div>
            <ScrollArea className="flex-grow py-4">
              <ul className="p-0 m-0">
                {links.map((link) => {
                  const isActive = router.pathname === link.href;
                  return (
                    <li
                      key={link.href}
                      className={cn('py-1 rounded-md w-full')}
                    >
                      <Link
                        to={link.href}
                        params={{}}
                        className={cn(
                          'h-10 w-full justify-start px-4 py-2 block items-center hover:bg-accent hover:no-underline whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
                          isActive &&
                            'bg-accent text-accent-foreground font-medium'
                        )}
                      >
                        <span>{link.label}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </ScrollArea>
          </div>
        </TKDrawer>
        <div className="flex-grow px-4 flex-1 flex-col overflow-auto flex">
          {children}
        </div>
      </div>
    </DrawerProvider>
  );
}
