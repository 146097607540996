import React, { useState } from 'react';
import { trpc } from '@/api/trpc';
import DateFilters, {
  type DateFiltersValue,
} from '@/components/FilterDropdowns/DateFilters/DateFilters';
import FilterDropdownsLayout from '@/components/FilterDropdowns/FilterDropdownsLayout';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import { useAuth } from '@/contexts/Global/AuthContext';
import { startOfDay, subMonths, endOfDay } from 'date-fns';
import { ReportInterval } from '@/hooks/analysis/useReportsTemplatesList';
import { calculateFilterDates } from '@/utils/analysis/calculateFilterDates';
import { useTranslation } from 'react-i18next';
import TKDataHandler from '@/components/Progress/TKDataHandler';
import TKTimeTooltip from '@/components/Common/TKTimeTooltip';
import {
  gramToKilogram,
  meterToKilometer,
  milliliterToLiter,
} from '@/utils/analysis/unitsConvertor';
import { ReportUnit } from '@/types/analysis';
import { useExportDataTable } from '@/hooks/common/useExportDataTable';
import { useDateFns } from '@/hooks/common/useDateFns';
import { DataTable } from '@/components/Table/data-table';
import { Button } from '@/components/ui/button';
import { FileSpreadsheet } from 'lucide-react';

export default function OpterDeliveredOrdersReport() {
  const { t, i18n } = useTranslation();
  const { companyId } = useAuth();
  const { format } = useDateFns();

  const [dateFilters, setDateFilters] = useState<DateFiltersValue>({
    fromTime: startOfDay(subMonths(new Date(), 3)).toISOString(),
    toTime: endOfDay(new Date()).toISOString(),
    presetAmount: 7,
    dateType: 'preset',
    presetType: 'lastDays',
    interval: ReportInterval.NoInterval,
  });

  function handleSetDateFilters(value: Partial<DateFiltersValue>) {
    setDateFilters({ ...dateFilters, ...value });
  }

  const dateRange = calculateFilterDates(dateFilters);
  const { data, isLoading, error } =
    trpc.report.generateOpterOrdersReport.useQuery({
      companyId,
      fromTime: dateRange.fromTime,
      toTime: dateRange.toTime,
    });

  const { exportToExcel } = useExportDataTable(
    data ?? [],
    [
      {
        id: 'orderId',
        title: t('analysis.reports.opter_delivered_orders_report.order_id'),
      },
      {
        id: 'customerId',
        title: t('analysis.reports.opter_delivered_orders_report.customer_id'),
      },
      {
        id: 'customerName',
        title: t(
          'analysis.reports.opter_delivered_orders_report.customer_name'
        ),
      },
      {
        id: 'pickupLocationName',
        title: t(
          'analysis.reports.opter_delivered_orders_report.pickup_location_name'
        ),
      },
      {
        id: 'deliveryLocationName',
        title: t(
          'analysis.reports.opter_delivered_orders_report.delivery_location_name'
        ),
      },
      {
        id: 'registrationNumber',
        title: t(
          'analysis.reports.opter_delivered_orders_report.equipment_registration_number'
        ),
      },
      {
        id: 'driverName',
        title: t('analysis.reports.opter_delivered_orders_report.driver_name'),
      },
      {
        id: 'cargoType',
        title: t('analysis.reports.opter_delivered_orders_report.cargo_type'),
      },
      {
        id: 'cargoWeight',
        title: t('analysis.reports.opter_delivered_orders_report.cargo_weight'),
      },
      {
        id: 'income',
        title: t('analysis.reports.opter_delivered_orders_report.income'),
      },
      {
        id: 'startedAt',
        title: t('analysis.reports.opter_delivered_orders_report.started_at'),
        render: (row) =>
          row.startedAt ? format(new Date(row.startedAt), 'Pp') : '',
      },
      {
        id: 'deliveredAt',
        title: t('analysis.reports.opter_delivered_orders_report.delivered_at'),
        render: (row) =>
          row.deliveredAt ? format(new Date(row.deliveredAt), 'Pp') : '',
      },
      {
        id: 'orderIntervalSeconds',
        title: t(
          'analysis.reports.opter_delivered_orders_report.duration_seconds'
        ),
      },
      {
        id: 'drivingDistanceMeter',
        title: t(
          'analysis.reports.opter_delivered_orders_report.driving_distance_meters'
        ),
      },
      {
        id: 'totalFuelUsedMl',
        title: t('analysis.reports.opter_delivered_orders_report.fuel_used_ml'),
      },
      {
        id: 'totalGaseousFuelUsedG',
        title: t(
          'analysis.reports.opter_delivered_orders_report.gaseous_fuel_used_g'
        ),
      },
      {
        id: 'engineTimeSeconds',
        title: t(
          'analysis.reports.opter_delivered_orders_report.engine_time_seconds'
        ),
      },
    ],
    `${t('analysis.reports.opter_delivered_orders_report.title')}_${format(
      new Date(),
      'Pp'
    )}`
  );

  return (
    <DashboardLayout
      disablePadding
      AppBarComponent={
        <div className="px-4 py-2 w-full flex justify-between">
          <FilterDropdownsLayout>
            <DateFilters
              handleChange={handleSetDateFilters}
              variant="dark"
              {...dateFilters}
            />
          </FilterDropdownsLayout>

          <Button
            variant="outline"
            onClick={exportToExcel}
            disabled={isLoading || !data}
            data-testid="export-widget-to-excel"
          >
            <FileSpreadsheet className="mr-2 h-4 w-4" />
            {t('analysis.exportToExcel')}
          </Button>
        </div>
      }
    >
      <div className="p-4 flex-1 h-full flex flex-col">
        <TKDataHandler
          loading={isLoading}
          error={error}
          data={data}
          entryText={t('orders.base', { count: 2 })}
          showChildrenWhenNoData
        >
          <DataTable
            columns={[
              {
                id: 'orderId',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.order_id'
                ),
              },
              {
                id: 'customerName',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.customer_name'
                ),
              },
              {
                id: 'registrationNumber',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.equipment_registration_number'
                ),
              },
              {
                id: 'driverName',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.driver_name'
                ),
              },
              {
                id: 'cargoType',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.cargo_type'
                ),
              },
              {
                id: 'cargoWeight',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.cargo_weight'
                ),
              },
              {
                id: 'income',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.income'
                ),
              },
              {
                id: 'startedAt',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.started_at'
                ),
                render: (row) =>
                  row.startedAt ? (
                    <TKTimeTooltip
                      timestamp={row.startedAt}
                      displayFormat="Pp"
                    />
                  ) : null,
              },
              {
                id: 'deliveredAt',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.delivered_at'
                ),
                render: (row) =>
                  row.deliveredAt ? (
                    <TKTimeTooltip
                      timestamp={row.deliveredAt}
                      displayFormat="Pp"
                    />
                  ) : null,
              },
              {
                id: 'orderIntervalSeconds',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.duration'
                ),
                render: (row) => {
                  if (!row.orderIntervalSeconds) return null;
                  const seconds = row.orderIntervalSeconds;
                  const hours = Math.floor(seconds / 3600);
                  const minutes = Math.floor((seconds % 3600) / 60);
                  const hrsText =
                    hours > 0 ? `${hours}${t('shared-common.hour')}` : '';
                  const minText =
                    minutes > 0 ? `${minutes}${t('shared-common.minute')}` : '';
                  return `${hrsText} ${minText}`.trim();
                },
              },
              {
                id: 'drivingDistanceMeter',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.driving_distance'
                ),
                render: (row) => {
                  if (!row.drivingDistanceMeter) return null;
                  const meters = row.drivingDistanceMeter;
                  const km = meterToKilometer(meters);
                  if (km === 0) return null;
                  return t(`shared-reports.units.${ReportUnit.Kilometer}`, {
                    amount: km.toLocaleString(i18n.language),
                    count: km,
                  });
                },
              },
              {
                id: 'totalFuelUsedMl',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.fuel_used_l'
                ),
                render: (row) => {
                  if (!row.totalFuelUsedMl) return null;
                  const ml = row.totalFuelUsedMl;
                  const liters = milliliterToLiter(ml);
                  if (liters === 0) return null;
                  return t(`shared-reports.units.${ReportUnit.Liter}`, {
                    amount: liters.toLocaleString(i18n.language),
                    count: liters,
                  });
                },
              },
              {
                id: 'totalGaseousFuelUsedG',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.gaseous_fuel_used_kg'
                ),
                render: (row) => {
                  if (!row.totalGaseousFuelUsedG) return null;
                  const grams = row.totalGaseousFuelUsedG;
                  const kg = gramToKilogram(grams);
                  if (kg === 0) return null;
                  return t(`shared-reports.units.${ReportUnit.Kg}`, {
                    amount: kg.toLocaleString(i18n.language),
                    count: kg,
                  });
                },
              },
              {
                id: 'engineTimeSeconds',
                title: t(
                  'analysis.reports.opter_delivered_orders_report.engine_time'
                ),
                render: (row) => {
                  if (!row.engineTimeSeconds) return null;
                  const seconds = row.engineTimeSeconds;
                  const hours = Math.floor(seconds / 3600);
                  const minutes = Math.floor((seconds % 3600) / 60);
                  const hrsText =
                    hours > 0 ? `${hours}${t('shared-common.hour')}` : '';
                  const minText =
                    minutes > 0 ? `${minutes}${t('shared-common.minute')}` : '';
                  return `${hrsText} ${minText}`.trim();
                },
              },
            ]}
            data={data ?? []}
            isLoading={isLoading}
            error={error}
            defaultSortColumn="startedAt"
            defaultSortOrder="desc"
            wrapperClassName="p-0 border-none"
            paginationPageSize={50}
          />
        </TKDataHandler>
      </div>
    </DashboardLayout>
  );
}
