import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { UserProfile } from '@/hooks/auth/useUserProfile';
import { PHONE_REGEX } from '@/utils/common/validators';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@/components/ui/form';
import UserLegalConsent from './UserLegalConsent';

interface Props {
  onUserFormSubmit: (form: UserProfile) => void;
  showLegalCheckboxes?: boolean;
  defaultValues?: Partial<UserProfile>;
  children: React.ReactNode;
}

/**
 * Main form component with the required inputs for creating/updating user's information.
 */
function UserProfileForm({
  onUserFormSubmit,
  defaultValues,
  showLegalCheckboxes = false,
  children,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const form = useForm<UserProfile>({
    defaultValues,
  });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => {
          if (defaultValues?.uid) {
            onUserFormSubmit({ ...data, uid: defaultValues.uid });
          }
        })}
      >
        <div className="space-y-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="firstName"
              rules={{ required: t('validations.firstname.required') }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('validations.firstname.label')}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      data-testid="user-firstname-input"
                      autoComplete="given-name"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              rules={{ required: t('validations.lastname.required') }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('validations.lastname.label')}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      data-testid="user-lastname-input"
                      autoComplete="family-name"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="phone"
            rules={{
              required: t('validations.phone.required'),
              pattern: {
                value: PHONE_REGEX,
                message: t('validations.phone.pattern'),
              },
            }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('validations.phone.label')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    data-testid="user-phone-input"
                    autoComplete="phone"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="address"
            rules={{ required: t('validations.address.required') }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('validations.address.label')}</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    data-testid="user-address-input"
                    autoComplete="street-address"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="zipCode"
              rules={{ required: t('validations.zipCode.required') }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('validations.zipCode.label')}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      data-testid="user-zip-code-input"
                      type="text"
                      autoComplete="postal-code"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="city"
              rules={{ required: t('validations.city.required') }}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t('validations.city.label')}</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      data-testid="user-city-input"
                      autoComplete="address-level2"
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="gender"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t('validations.gender.label')}</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder=" " />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="M">
                      {t('validations.gender.M')}
                    </SelectItem>
                    <SelectItem value="F">
                      {t('validations.gender.F')}
                    </SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          {showLegalCheckboxes && <UserLegalConsent form={form} />}
        </div>
        {children}
      </form>
    </Form>
  );
}

export default UserProfileForm;
