import React from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useDateFns } from '@/hooks/common/useDateFns';

type TKTimeTooltipProps = {
  timestamp: string | Date | number;
  displayFormat?: string;
  tooltipFormat?: string;
};

/**
 * This component formats the time and displays the full time in a tooltip when hovered.
 */
const TKTimeTooltip: React.FC<TKTimeTooltipProps> = ({
  timestamp,
  displayFormat,
  tooltipFormat,
}): JSX.Element => {
  const { format, locale } = useDateFns();

  const display = displayFormat ?? locale.formatLong?.date({ width: 'short' });
  const tooltip = tooltipFormat ?? 'LLLL d, yyyy kk:mm:ss';
  const currentTime = new Date(timestamp);

  return (
    <TooltipProvider delayDuration={150}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span>{timestamp ? format(currentTime, display) : '-'}</span>
        </TooltipTrigger>
        <TooltipContent>
          <p>{timestamp ? format(currentTime, tooltip) : '-'}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default TKTimeTooltip;
