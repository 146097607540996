import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/api/trpc';
import { TypographySmall } from '@/components/ui/typography';
import IntegrationAccordionItem from './IntegrationAccordionItem';
import TKDataHandler from '@/components/Progress/TKDataHandler';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import ChooseIntegrationTypeDialog from './ChooseIntegrationTypeDialog';
import { IntegrationTypeEnum } from '@/types/company/integrations';
import IntegrationFormDialog from './IntegrationFormDialog';
import { useAuth } from '@/contexts/Global/AuthContext';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { CompanySettingsLayout } from '@/components/Layouts/DashboardLayout/CompanySettingsLayout';

function CompanyIntegrations() {
  const { companyId } = useAuth();
  const { t } = useTranslation();

  const integrationQuery = trpc.integration.listConfig.useQuery({ companyId });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [typeId, setTypeId] = useState<IntegrationTypeEnum | null>(null);
  const [expandedPanelId, setExpandedPanelId] = useState<number | null>(null);

  const enabledIntegrations = integrationQuery?.data?.filter(
    (integration) => integration.enabled
  );
  const disabledIntegrations = integrationQuery?.data?.filter(
    (integration) => !integration.enabled
  );

  return (
    <CompanySettingsLayout>
      <DashboardLayout disablePadding>
        <TKPageAppBar title={t('routes.integrations')}>
          <Button
            onClick={() => setDialogOpen(true)}
            data-testid="open-create-integration-dialog"
          >
            <Plus className="mr-2 h-4 w-4" />
            {t('company.integrations.addNewIntegration')}
          </Button>
        </TKPageAppBar>
        <TKDataHandler
          loading={integrationQuery.isLoading}
          error={integrationQuery.error}
          entryText={t('company.integrations.base', { count: 2 })}
          data={integrationQuery.data}
        >
          <div className="space-y-6 p-2">
            <div className="space-y-4">
              <TypographySmall className="text-muted-foreground">
                {t('company.integrations.activated')}
                {` (${enabledIntegrations?.length || 0})`}
              </TypographySmall>
              {enabledIntegrations?.map((item) => (
                <IntegrationAccordionItem
                  key={item.id}
                  integration={item}
                  setExpandedPanelId={setExpandedPanelId}
                  expandedPanelId={expandedPanelId}
                  type="enabled"
                />
              ))}
            </div>

            <div className="space-y-4">
              <TypographySmall className="text-muted-foreground">
                {t('company.integrations.deactivated')}
                {` (${disabledIntegrations?.length || 0})`}
              </TypographySmall>
              {disabledIntegrations?.map((item) => (
                <IntegrationAccordionItem
                  key={item.id}
                  integration={item}
                  setExpandedPanelId={setExpandedPanelId}
                  expandedPanelId={expandedPanelId}
                  type="disabled"
                />
              ))}
            </div>
          </div>
        </TKDataHandler>
      </DashboardLayout>
      {dialogOpen && !typeId && (
        <ChooseIntegrationTypeDialog
          open={dialogOpen}
          setOpen={setDialogOpen}
          setTypeId={setTypeId}
          typeId={typeId}
        />
      )}
      {dialogOpen && typeId && (
        <IntegrationFormDialog
          dialogType="create"
          integrationTypeId={typeId}
          open={dialogOpen}
          setOpen={setDialogOpen}
          handleClearSelectedType={() => setTypeId(null)}
        />
      )}
    </CompanySettingsLayout>
  );
}

export default CompanyIntegrations;
