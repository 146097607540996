import {
  CheckboxList,
  MultistepItem,
} from '@/components/FormControls/MultistepListCheckbox';
import { matchSorter } from 'match-sorter';

/**
 * Finds the title for current step
 */
const getCurrentStepTitle = (data: CheckboxList, steps: Array<number>) => {
  let title = data.title;

  if (steps.length > 0) {
    let prevStepData: MultistepItem = {
      items: [],
      label: '',
      value: '',
      title: '',
      active: false,
      searchFields: [],
    };
    for (let i = 0; i < steps.length; i++) {
      const current = steps[i];

      if (prevStepData && prevStepData?.items?.length && i < steps.length) {
        prevStepData = prevStepData.items[current];
      } else {
        prevStepData = data.items[current];
      }
    }

    if (prevStepData) {
      title = prevStepData?.title || prevStepData.label;
    }
  }
  return title;
};

/**
 * Find the current data based on the current steps that were clicked on
 * This is index-based.
 */
const getDataForCurrentStep = (data: CheckboxList, steps: Array<number>) => {
  let items = data.items;

  let prevStepData: any = null;
  for (let i = 0; i < steps.length; i++) {
    const stepIndex = steps[i];
    const list = prevStepData || items;
    prevStepData = list[stepIndex].items;
  }

  if (prevStepData) {
    items = prevStepData;
  }

  return items;
};

/**
 * Recursively finds every clickable item that doesn't have subitems.
 */
const getAllSelectableItems = (items: MultistepItem[]) => {
  const innerItems: MultistepItem[] = [];

  for (const item of items) {
    if (item.items?.length) {
      innerItems.push(...getAllSelectableItems(item.items));
    } else {
      innerItems.push(item);
    }
  }

  return innerItems;
};

const searchSelectableItems = (items: MultistepItem[], searchTerm: string) => {
  const selectableItems = getAllSelectableItems(items);

  return matchSorter(selectableItems, searchTerm, {
    keys: ['searchFields', 'label'],
  });
};

export { getCurrentStepTitle, getDataForCurrentStep, searchSelectableItems };
