import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreHorizontal } from 'lucide-react';
import { trpc } from '@/api/trpc';
import { useDialog } from '@/contexts/Global/Dialog/DialogContext';
import CompanyGroupsFormDialog from './CompanyGroupsFormDialog';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

interface Props {
  id: string;
  companyId: number;
}

function CompanyGroupActions({ id, companyId }: Props): JSX.Element {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const dialog = useDialog();
  const groupQuery = trpc.group.byId.useQuery({ id, companyId });

  const deleteMutation = trpc.group.deleteById.useMutation({
    async onSuccess() {},
  });

  function handleOpenUpdateDialog() {
    setDialogOpen(true);
  }

  function handleOpenDeleteDialog() {
    dialog({
      dialogType: 'delete',
      title: t('company.groups.deleteGroupsDialog.title'),
      description: t('company.groups.deleteGroupsDialog.description', {
        name: groupQuery.data?.name,
      }),
      dataTestConfirm: 'confirm-delete-group',
    }).then(() => {
      if (!groupQuery?.data?.companyId) return;
      deleteMutation.mutate({ id, companyId: groupQuery.data.companyId });
    });
  }

  return (
    <>
      <div className="w-full flex justify-end">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button
              variant="ghost"
              size="icon"
              className="group opacity-0 transition-all group-hover/row:opacity-100"
              data-testid="group-options-menu"
            >
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem
              onClick={handleOpenUpdateDialog}
              data-testid="group-options-update-menu"
            >
              {t('common.edit')}
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={handleOpenDeleteDialog}
              data-testid="group-options-delete-menu"
            >
              {t('common.delete')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {dialogOpen && (
        <CompanyGroupsFormDialog
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          dialogType="update"
          initialValues={groupQuery.data}
        />
      )}
    </>
  );
}

export default CompanyGroupActions;
