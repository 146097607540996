import React from 'react';
import { Button } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';

type MultistepFooterProps = {
  handleSelectAll?: () => void;
  handleDeselectAll?: () => void;
};

const MultistepFooter: React.FC<MultistepFooterProps> = ({
  handleSelectAll,
  handleDeselectAll,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-1 mt-4">
      {handleSelectAll && (
        <Button
          onClick={handleSelectAll}
          variant="outline"
          size="sm"
          className="flex-1 h-8"
          data-testid="multistep-select-all"
        >
          {t('common.selectAll')}
        </Button>
      )}
      {handleDeselectAll && (
        <Button
          onClick={handleDeselectAll}
          variant="outline"
          size="sm"
          className="flex-1 h-8"
          data-testid="multistep-deselect-all"
        >
          {t('common.deselectAll')}
        </Button>
      )}
    </div>
  );
};

export default MultistepFooter;
