import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import CompanyBaseCard from './CompanyBaseCard';
import { useDialog } from '@/contexts/Global/Dialog/DialogContext';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';

interface Props {
  id: string;
  companyId: number;
}

function UserInvitationCard({ id, companyId }: Props) {
  const dialog = useDialog();
  const { t } = useTranslation();
  const acceptMutation = trpc.invitation.accept.useMutation({
    async onSuccess() {
      toast.success(t('companies.acceptInvitationSuccess'));
    },
    async onError() {
      toast.error(t('companies.acceptInvitationError'));
    },
  });

  const invitation = trpc.invitation.byId.useQuery({ id, companyId });

  const declineMutation = trpc.invitation.decline.useMutation({
    async onSuccess() {
      toast.success(t('companies.declineInvitationSuccess'));
    },
    onError() {
      toast.error(t('companies.declineInvitationError'));
    },
  });

  const showDeclineDialog = async () => {
    dialog({
      title: t('companies.deleteInvitationDialog.title'),
      description: t('companies.deleteInvitationDialog.description'),
      dialogType: 'delete',
    }).then(() => {
      declineMutation.mutate({
        id,
        companyId,
      });
    });
  };

  const disabled = declineMutation.isLoading || acceptMutation.isLoading;

  return (
    <CompanyBaseCard
      name={invitation.data?.companyName}
      role={invitation.data?.userRole}
      isLoading={invitation.isLoading}
      isError={invitation.isError}
    >
      <div className="flex space-x-2">
        <Button
          variant="outline"
          onClick={showDeclineDialog}
          isLoading={declineMutation.isLoading}
          disabled={disabled}
        >
          {t('companies.declineInvitation')}
        </Button>
        <Button
          onClick={() => acceptMutation.mutate({ id, companyId })}
          isLoading={acceptMutation.isLoading}
          disabled={disabled}
        >
          {t('companies.acceptInvitation')}
        </Button>
      </div>
    </CompanyBaseCard>
  );
}
export default UserInvitationCard;
