import React, { useRef } from 'react';
import {
  CountryOption,
  useCountriesTranslation,
} from '@/hooks/common/useCountriesTranslation';
import CountryImageFlag from '@/components/Common/CountryImageFlag';
import { Check } from 'lucide-react';

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface Props {
  onChange: (value: CountryOption | null) => void;
  error?: string;
  value: CountryOption | null;
  defaultValue: CountryOption | null;
  className?: string;
}

function SelectCountry({
  onChange,
  value,

  className,
}: Props): JSX.Element {
  const countries = useCountriesTranslation();
  const [open, setOpen] = React.useState(false);

  // Workaround: ref to the container element, necessary to fix scrolling of the popover inside dialogs
  // https://github.com/radix-ui/primitives/issues/1159#issuecomment-1741282769
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className={cn('w-full', className)}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="justify-between w-full max-w-32"
          >
            {value ? (
              <>
                <CountryImageFlag
                  style={{ height: 'auto', width: 26 }}
                  className="mr-1"
                  alphaCode={value.alphaCode}
                />
                <span className="truncate">{value.countryName}</span>
              </>
            ) : (
              'Select country...'
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          container={containerRef.current}
          className="w-[300px] p-0 max-h-[300px] overflow-hidden"
        >
          <Command>
            <CommandInput placeholder="Search country..." />
            <CommandEmpty>No country found.</CommandEmpty>
            <CommandGroup className="overflow-auto max-h-[300px]">
              {countries.map((country) => (
                <CommandItem
                  key={country.alphaCode}
                  onSelect={() => {
                    onChange(country);
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      value?.alphaCode === country.alphaCode
                        ? 'opacity-100'
                        : 'opacity-0'
                    )}
                  />
                  <CountryImageFlag
                    style={{ height: 'auto', width: 26 }}
                    className="mr-2"
                    alphaCode={country.alphaCode}
                  />
                  {country.countryName} ({country.alphaCode})
                  <span className="ml-auto font-bold">
                    {country.phonePrefix}
                  </span>
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export default SelectCountry;
