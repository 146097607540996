import React, { useState } from 'react';
import TKTextWithLabel from '@/components/Common/TKTextWithLabel';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';

interface Props {
  equipmentId: string;
}

function CurrentDriverField({ equipmentId }: Props) {
  const { t } = useTranslation();
  const { companyId } = useAuth();
  const [now] = useState(() => new Date().toISOString());

  const { data } = trpc.equipment.driverAtTime.useQuery({
    id: equipmentId,
    companyId,
    atTime: now,
  });
  const text =
    (data?.driverFullname || data?.tachoDriverId) ??
    t('common.notAvailable');
  if (!text) return null;
  return (
    <TKTextWithLabel
      label={t('equipment.columns.currentDriver')}
      timestamp={data?.sampledAt}
      text={text}
    />
  );
}

export default CurrentDriverField;
