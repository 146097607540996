import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { ReportUnit } from '@/types/analysis';

enum ReportCategory {
  All = 'all',
  Fuel = 'fuel',
  Efficiency = 'efficiency',
  Environment = 'environment',
  DrivingStyle = 'drivingStyle',
}

export enum ReportInterval {
  'Day' = 'day',
  'Week' = 'week',
  'Month' = 'month',
  'NoInterval' = 'no_interval',
}

export interface ReportTemplate {
  name: string;
  description: string;
  chartLabel: string;
  reportTypeId: string;
  unit: ReportUnit;
  categoryId: ReportCategory;
  categoryName: string;
}

/** Retrieves report templates */
const useReportsTemplatesList = () => {
  const { companyId } = useAuth();
  const templatesQuery = trpc.analysisReport.listTemplates.useQuery({
    companyId,
  });

  return templatesQuery;
};

export { useReportsTemplatesList };
