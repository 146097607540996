import React from 'react';
import { useTranslation } from 'react-i18next';
import { MoreVertical } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

interface Props {
  handleExportToCsv?: () => void;
  handleExportToExcel?: () => Promise<void>;
  loading?: boolean;
}

function HomepageWidgetHeader({
  handleExportToCsv,
  handleExportToExcel,
  loading,
}: Props): JSX.Element | null {
  const { t } = useTranslation();

  if (!handleExportToCsv && !handleExportToExcel) return null;

  return (
    <div className="notDraggableWidget">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="size-8"
            data-testid="open-widget-actions-menu"
          >
            <MoreVertical className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="notDraggableWidget">
          {handleExportToExcel && (
            <DropdownMenuItem
              onClick={handleExportToExcel}
              data-testid="export-widget-to-excel"
              disabled={loading}
            >
              {t('analysis.exportToExcel')}
            </DropdownMenuItem>
          )}
          {handleExportToCsv && (
            <DropdownMenuItem
              onClick={handleExportToCsv}
              data-testid="export-widget-to-csv"
              disabled={loading}
            >
              {t('analysis.exportToCsv')}
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

export default HomepageWidgetHeader;
