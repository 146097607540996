import React, { useState } from 'react';
import LibraryList from './LibraryList';
import LibraryCategoriesSelect from './LibraryCategoriesSelect';
import TKDataHandler from '@/components/Progress/TKDataHandler';
import { TRPCClientErrorLike } from '@/api/trpc';
import { useDebounce } from '@/hooks/common/useDebounce';
import { Search } from 'lucide-react';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Button } from '../ui/button';
import { cn } from '@/lib/utils';
import { TypographyH6 } from '@/components/ui/typography';

export type LibraryItemType<T> = {
  id: T;
  category: string;
  name: string;
  description: string;
};
export type LibraryCategoryType = {
  label: string;
  id: string;
};

type LibraryProps<T> = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  handleSelect: (id: T) => Promise<void> | void;
  items: LibraryItemType<T>[] | undefined;
  selectButtonText: string;
  categories: LibraryCategoryType[];
  loading: boolean;
  loadingId?: string;
  listLoading: boolean;
  listError: undefined | TRPCClientErrorLike | null;
  dataHandlerEntryText: string;
};

const Library = <T extends string>({
  open,
  setOpen,
  handleSelect,
  categories,
  selectButtonText,
  items,
  loading,
  listLoading,
  listError,
  loadingId,
  dataHandlerEntryText,
}: LibraryProps<T>) => {
  const [categoryId, setCategoryId] = useState<string>('all');
  const [search, setSearch] = useState('');
  const searchTerm = useDebounce(search, 50);

  const handleInternalSelect = async (id: T) => {
    await handleSelect(id);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent
        className="p-0 overflow-hidden sm:max-w-[800px]"
        forceMount
      >
        <div className="flex flex-col md:flex-row h-full md:h-[70vh]">
          <TKDataHandler
            loading={listLoading}
            error={listError}
            data={items}
            entryText={dataHandlerEntryText}
            size="small"
          >
            <div className="md:hidden">
              <LibraryCategoriesSelect
                categoryId={categoryId}
                setCategoryId={setCategoryId}
                categories={categories}
              />
            </div>
            <div className="hidden md:flex bg-background flex-[0_1_30%] flex-col py-2 space-y-2">
              {categories.map((item) => (
                <Button
                  key={`add_library_category_${item.id}`}
                  variant={categoryId === item.id ? 'secondary' : 'ghost'}
                  className={cn(
                    'justify-start',
                    categoryId === item.id
                      ? 'bg-secondary/50'
                      : 'hover:bg-secondary/20'
                  )}
                  onClick={() => setCategoryId(item.id)}
                >
                  <TypographyH6 className="text-foreground">
                    {item.label}
                  </TypographyH6>
                </Button>
              ))}
            </div>

            <div className="flex flex-col flex-1">
              <div className="pt-1 px-2 w-full md:w-[92%]">
                <div className="relative">
                  <Input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder="Search..."
                    className="pl-10"
                  />
                  <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                </div>
              </div>
              <div className="flex-1 overflow-auto">
                <LibraryList
                  searchTerm={searchTerm}
                  categoryId={categoryId}
                  loading={loading}
                  loadingId={loadingId}
                  handleSelect={handleInternalSelect}
                  selectButtonText={selectButtonText}
                  items={items || []}
                />
              </div>
            </div>
          </TKDataHandler>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Library;
