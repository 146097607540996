import { useTranslation } from 'react-i18next';
import { ReactComponent as MaintenanceImage } from '@/assets/images/undraw_bug_fixing_oc7a.svg';
import { Button } from '@/components/ui/button';
import { TypographyH3 } from '@/components/ui/typography';

function Maintenance() {
  const { t } = useTranslation();

  const handleReloadApplication = async () => {
    window.location.reload();
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="w-full max-w-3xl">
        <div className="flex flex-col items-center space-y-6 p-6">
          <MaintenanceImage
            width="100%"
            height="auto"
            viewBox="0 0 1022.7 785.81"
            className="max-w-md"
          />

          <TypographyH3 className="text-center">
            {t('shared-maintenance.description')}
          </TypographyH3>

          <Button onClick={handleReloadApplication}>
            {t('shared-maintenance.reload')}
          </Button>
        </div>
      </div>
    </div>
  );
}
export default Maintenance;
