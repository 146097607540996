import React, { Suspense, useState } from 'react';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { endOfMonth, startOfMonth } from 'date-fns';
import { CenteredSpinner } from '@/components/ui/spinner';

const Timeline = React.lazy(() => import('@/components/Timeline/Timeline'));
type EquipmentSharingTimelineProps = {
  equipmentId: string;
  onSharingRuleClicked: (id: string) => void;
};

const EquipmentSharingTimeline: React.FC<EquipmentSharingTimelineProps> = ({
  equipmentId,
  onSharingRuleClicked,
}) => {
  const { companyId } = useAuth();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const startOfPeriod = startOfMonth(selectedDate);
  const endOfPeriod = endOfMonth(selectedDate);

  const companies = trpc.equipmentSharing.listCompanies.useQuery({
    companyId,
    equipmentId,
  });

  const periods = trpc.equipmentSharing.listPeriods.useQuery({
    companyId,
    equipmentId,
    startTime: startOfPeriod.toISOString(),
    endTime: endOfPeriod.toISOString(),
  });

  const groups =
    companies.data?.reduce(
      (acc, c) => ({
        ...acc,
        [c.companyId.toString()]: {
          name: c.companyName,
        },
      }),
      {}
    ) ?? {};

  const events =
    periods.data?.map((p) => ({
      id: `${p.startTime}-${p.endTime}-${p.sharedWithCompanyId}`,
      startTime: new Date(p.startTime),
      endTime: new Date(p.endTime),
      groupId: p.sharedWithCompanyId.toString(),
      onClick: () => onSharingRuleClicked(p.id),
    })) ?? [];

  return (
    <div className="w-full">
      <Suspense fallback={<CenteredSpinner />}>
        <Timeline
          loading={companies.isLoading || periods.isLoading}
          date={selectedDate}
          onDateChange={(d) => setSelectedDate(d)}
          groups={groups}
          events={events}
        />
      </Suspense>
    </div>
  );
};

export default EquipmentSharingTimeline;
