import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Fuel } from 'lucide-react';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import TKTextWithLabel from '@/components/Common/TKTextWithLabel';

interface Props {
  equipmentId: string;
}

function EngineEmissionSection({ equipmentId }: Props) {
  const { t } = useTranslation();
  const { companyId } = useAuth();
  const equipment = trpc.equipment.byId.useQuery({
    id: equipmentId,
    companyId,
  });
  const query = trpc.statensVegvesen.engineEmission.useQuery({
    companyId,
    equipmentId,
  });
  const data = query.data;

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Fuel />
          {t('equipment.columns.vehicleEngineAndEmission')}
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4 flex flex-col">
        <TKTextWithLabel
          label={t('equipment.columns.euroclass')}
          text={data?.euroClassName}
        />
        <TKTextWithLabel
          label={t('equipment.columns.fuelType')}
          text={
            equipment.data?.fuelType
              ? t(`equipment.${equipment.data.fuelType}`)
              : undefined
          }
        />
        <TKTextWithLabel
          label={t('equipment.columns.effect')}
          text={data?.maxNettoEffect}
        />
        <TKTextWithLabel
          label={t('equipment.columns.gearboxType')}
          text={data?.gearboxTypeName}
        />
      </CardContent>
    </Card>
  );
}

export default EngineEmissionSection;
