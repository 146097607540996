import { TypographyH2 } from '@/components/ui/typography';
import React from 'react';

interface Props {
  text: string | null;
}
function TextCardChart({ text }: Props) {
  return (
    <div
      className="h-full w-full flex justify-center items-center"
      data-testid="card-chart"
    >
      <TypographyH2 className=" font-semibold leading-none tracking-tight">
        {text}
      </TypographyH2>
    </div>
  );
}
export default TextCardChart;
