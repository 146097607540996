import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import EquipmentTable from './EquipmentTable';
import CreateEquipmentDialog from '@/views/EquipmentPage/CreateEquipmentDialog';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';
import { Button } from '@/components/ui/button';
import { PlusIcon } from 'lucide-react';

const EquipmentRegister: React.FC = () => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <DashboardLayout
        AppBarComponent={
          <TKPageAppBar title={t('equipment.title')}>
            <Button
              onClick={() => setDialogOpen(true)}
              data-testid="open-create-equipment-dialog"
            >
              <PlusIcon className="mr-2 h-4 w-4" />
              {t('equipment.addCustomEquipment')}
            </Button>
          </TKPageAppBar>
        }
        noScroll
      >
        <EquipmentTable />
      </DashboardLayout>
      <CreateEquipmentDialog open={dialogOpen} setOpen={setDialogOpen} />
    </>
  );
};

export default EquipmentRegister;
