import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlertTriangle } from 'lucide-react';
import { ProjectsFiltersValue } from './ProjectsFilters';
import { TypographySmall } from '@/components/ui/typography';

interface Props extends ProjectsFiltersValue {
  hasSelectedProjectWithoutRules: boolean;
  dataLength: number;
  isLoading: boolean;
}

function Title({
  projectIds,
  hideDataFromUnknownProjects,
  dataLength,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const total = projectIds?.length || 0;

  if ((!projectIds || dataLength === total) && !hideDataFromUnknownProjects) {
    return <>{t('filters.projects.all')}</>;
  }
  if (
    Array.isArray(projectIds) &&
    !projectIds?.length &&
    !hideDataFromUnknownProjects
  ) {
    return <>{t('filters.projects.unknownProject')}</>;
  }

  if (total === 0) return <>{t('filters.projects.title')}</>;

  return <>{t('filters.projects.base', { count: total })}</>;
}

function ProjectsFiltersTitle({
  hasSelectedProjectWithoutRules,
  projectIds,
  hideDataFromUnknownProjects,
  dataLength,
  isLoading,
}: Props): JSX.Element {
  return (
    <span className="flex items-center">
      <TypographySmall>
        <Title
          isLoading={isLoading}
          projectIds={projectIds}
          hasSelectedProjectWithoutRules={hasSelectedProjectWithoutRules}
          dataLength={dataLength}
          hideDataFromUnknownProjects={hideDataFromUnknownProjects}
        />
      </TypographySmall>
      {!isLoading && hasSelectedProjectWithoutRules && (
        <AlertTriangle className="h-4 w-4 ml-1" />
      )}
    </span>
  );
}

export default ProjectsFiltersTitle;
