import React from 'react';
import { useTranslation } from 'react-i18next';
import TKTypedLink from '@/components/Common/TKTypedLink';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useCompany } from '@/hooks/auth/useCompany';
import TKTimeTooltip from '@/components/Common/TKTimeTooltip';
import EquipmentTypeField from '@/components/Equipment/EquipmentTypeField';
import { DataTable } from '@/components/Table/data-table';
import { CollapsedBadges } from '@/components/Badges/CollapsedBadges';
import { endOfDay } from 'date-fns';
import { EquipmentTypeEnum } from '@/types/equipment';
import upperFirst from 'lodash/upperFirst';

function EquipmentTable(): JSX.Element {
  const { t } = useTranslation();
  const { companyId } = useAuth();
  const company = useCompany();
  const equipmentQuery = trpc.equipment.list.useQuery({
    companyId,
    includeSold: endOfDay(new Date()).toISOString(),
  });
  const equipment = equipmentQuery.data ?? [];
  const { data: groups = [] } = trpc.group.list.useQuery(
    { companyId },
    { enabled: !!company.data?.featureEnableGroups }
  );

  const groupsMap = new Map<string, string>(groups.map((x) => [x.id, x.name]));

  const equipmentWithGroupData = equipment.map((x) => ({
    ...x,
    groupNames: x.groupIds.map((id) => groupsMap.get(id)!),
  }));

  return (
    <DataTable
      data={equipmentWithGroupData}
      columns={[
        {
          id: 'displayName',
          title: t('equipment.equipmentName'),
          enableSorting: true,
          render: (row) => (
            <TKTypedLink
              data-testid="link-equipment"
              to="/equipment/:id"
              params={{ id: row.id }}
            >
              {row.displayName}
            </TKTypedLink>
          ),
        },
        {
          id: 'groupNames',
          title: t('equipment.group'),
          hidden: !company.data?.featureEnableGroups,
          render: (row) => (
            <CollapsedBadges
              items={row.groupNames}
              variant="secondary"
              collapseAt={3}
            />
          ),
          filter: true,
        },
        {
          id: 'type',
          title: t('equipment.type'),
          render: (row) => <EquipmentTypeField type={row.type} />,
          valueToLabel: (value: EquipmentTypeEnum) => {
            if (value === EquipmentTypeEnum.TRUCK) {
              return upperFirst(`${t('equipment.truckBase')}`);
            }
            if (value === EquipmentTypeEnum.MACHINE) {
              return upperFirst(`${t('equipment.machineBase')}`);
            }
            if (value === EquipmentTypeEnum.TRAILER) {
              return upperFirst(`${t('equipment.trailerBase')}`);
            }
            if (value === EquipmentTypeEnum.CAR) {
              return upperFirst(`${t('equipment.carBase')}`);
            }
            if (value === EquipmentTypeEnum.CONTAINER) {
              return upperFirst(`${t('equipment.containerBase')}`);
            }
            return '-';
          },
          filter: true,
        },
        {
          id: 'brand',
          title: t('equipment.brand'),
          filter: true,
        },
        {
          id: 'model',
          title: t('equipment.model'),
          filter: true,
        },
        {
          id: 'createdAt',
          title: t('equipment.addedAt'),
          render: (row) => <TKTimeTooltip timestamp={row.createdAt} />,
          enableSorting: true,
        },
      ]}
      searchColumn="displayName"
      defaultSortColumn="displayName"
      defaultSortOrder="desc"
      isLoading={equipmentQuery.isLoading}
      error={equipmentQuery.error}
      paginationPageSize={50}
    />
  );
}

export default EquipmentTable;
