import defaultTheme from 'tailwindcss/defaultTheme';
import { useEffect, useState } from 'react';

const isAboveBreakpoint = (breakpoint: 'sm' | 'md' | 'lg' | 'xl' | '2xl') => {
  const breakpointValue = parseInt(defaultTheme.screens[breakpoint], 10);
  return window.innerWidth >= breakpointValue;
};
export const useTailwindBreakpoint = (
  breakpoint: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
): boolean => {
  const [isAbove, setIsAbove] = useState(() => isAboveBreakpoint(breakpoint));

  useEffect(() => {
    // Check initially
    setIsAbove(isAboveBreakpoint(breakpoint));

    function checkBreakpoint() {
      setIsAbove(isAboveBreakpoint(breakpoint));
    }

    // Add event listener for resize
    window.addEventListener('resize', checkBreakpoint);

    // Clean up
    return () => {
      window.removeEventListener('resize', checkBreakpoint);
    };
  }, [breakpoint]);

  return isAbove;
};
