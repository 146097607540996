import React from 'react';
import TKTextWithLabel from '@/components/Common/TKTextWithLabel';
import { useTranslation } from 'react-i18next';

interface Props {
  engineSeconds?: number | null;
  sampledAt?: string | null;
}

function EngineTimeField({ engineSeconds, sampledAt }: Props): JSX.Element {
  const { t, i18n } = useTranslation();

  const formatText = (seconds: number) => {
    return Math.floor(seconds / 60 / 60).toLocaleString(i18n.language);
  };

  const text =
    typeof engineSeconds === 'number'
      ? `${formatText(engineSeconds)} ${t('common.unit.hour.short')}`
      : t('common.notAvailable');

  return (
    <TKTextWithLabel
      label={t('equipment.columns.engineTime')}
      timestamp={sampledAt}
      text={text}
    />
  );
}

export default EngineTimeField;
