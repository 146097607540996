import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';

import { useAuth } from '@/contexts/Global/AuthContext';
import { toast } from 'sonner';
import { useLoginIntoCompany } from '@/hooks/auth/useLoginIntoCompany';
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
  PASSWORD_REGEX,
} from '@/utils/common/validators';

import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { TypographyBold } from '@/components/ui/typography';

type ChangePasswordFormData = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

/**
 * Form that allows the user to change their password if they have email-password authentication already enabled.
 */
const ChangePassword: React.FC = () => {
  const { user, companyId } = useAuth();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { loginIntoCompany } = useLoginIntoCompany();

  const form = useForm<ChangePasswordFormData>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
  });

  /**
   * Re-authenticates the user using the current password, then updates it to the new one.
   *
   */
  const handleChangePassword = async ({
    oldPassword,
    newPassword,
  }: ChangePasswordFormData) => {
    try {
      // Save the current company ID
      const currentCompanyId = companyId;
      setLoading(true);
      if (!user?.email) throw new Error();

      // Re-authenticate for 2 reasons:
      // 1. Firebase requires recent re-authentication token
      // 2. We need to make sure that the user knows the old(current) password
      const credential = EmailAuthProvider.credential(user.email, oldPassword);
      await reauthenticateWithCredential(user, credential);

      await updatePassword(user, newPassword);

      // Clear the form
      form.reset({
        oldPassword: undefined,
        newPassword: undefined,
        confirmNewPassword: undefined,
      });

      // Silently relog user into the company because the auth token was changed
      if (currentCompanyId) {
        await loginIntoCompany(currentCompanyId);
      }

      toast.success(t('userProfile.accountSettings.success'));
    } catch (error) {
      toast.error(t('userProfile.accountSettings.error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(handleChangePassword)}
        className="space-y-4"
      >
        <TypographyBold>
          {t('userProfile.accountSettings.changePassword')}
        </TypographyBold>

        <FormField
          control={form.control}
          name="oldPassword"
          rules={{
            required: t('validations.password.required'),
            minLength: {
              value: MIN_PASSWORD_LENGTH,
              message: t('validations.password.minLength', {
                amount: MIN_PASSWORD_LENGTH,
              }),
            },
            maxLength: {
              value: MAX_PASSWORD_LENGTH,
              message: t('validations.password.maxLength', {
                amount: MAX_PASSWORD_LENGTH,
              }),
            },
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('validations.oldPassword.label')}</FormLabel>
              <FormControl>
                <Input type="password" disabled={loading} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="newPassword"
          rules={{
            required: t('validations.password.required'),
            pattern: {
              value: PASSWORD_REGEX,
              message: t('validations.password.pattern'),
            },
            minLength: {
              value: MIN_PASSWORD_LENGTH,
              message: t('validations.password.minLength', {
                amount: MIN_PASSWORD_LENGTH,
              }),
            },
            maxLength: {
              value: MAX_PASSWORD_LENGTH,
              message: t('validations.password.maxLength', {
                amount: MAX_PASSWORD_LENGTH,
              }),
            },
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('validations.newPassword.label')}</FormLabel>
              <FormControl>
                <Input type="password" disabled={loading} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="confirmNewPassword"
          rules={{
            required: t('validations.confirmPassword.required'),
            validate: (value) =>
              value === form.watch('newPassword') ||
              t('validations.confirmPassword.pattern'),
          }}
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t('validations.confirmNewPassword.label')}</FormLabel>
              <FormControl>
                <Input type="password" disabled={loading} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" isLoading={loading}>
          {t('userProfile.accountSettings.changePassword')}
        </Button>
      </form>
    </Form>
  );
};

export default ChangePassword;
