import React from 'react';
import { Switch, Route } from 'react-router-dom';

import NotFound from '@/views/Common/NotFound';
import TKRoute from './TKRoute';

import { routesList } from './routesList';

/**
 * Main component that handles routes/pages in TK Dashboard.
 *
 * @return {*}  {JSX.Element}
 */
const Routes = (): JSX.Element => {
  return (
    <Switch>
      {/* Main routes */}
      {routesList.map((route) => {
        return <TKRoute {...route} key={`TKRoute_${route.path}`} />;
      })}

      {/* 404 Not Found Page */}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Routes;
