import React, { useMemo } from 'react';
import { LibraryItemType } from './index';
import { matchSorter } from 'match-sorter';
import { useTranslation } from 'react-i18next';
import TKNoDataFound from '@/components/Progress/TKNoDataFound';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';

type LibraryListProps<T> = {
  categoryId: string;
  loading: boolean;
  handleSelect: (id: T) => void;
  loadingId?: string | null;
  items: LibraryItemType<T>[];
  selectButtonText: string;
  searchTerm: string;
};

const LibraryList = <T extends string>({
  categoryId,
  loading,
  handleSelect,
  searchTerm,
  items,
  selectButtonText,
  loadingId,
}: LibraryListProps<T>) => {
  const { t } = useTranslation();

  const filteredItems = useMemo(() => {
    if (!searchTerm && categoryId === 'all') return items;

    let filteredByCategory: LibraryItemType<T>[] = items;

    if (categoryId !== 'all') {
      filteredByCategory = matchSorter(filteredByCategory, categoryId, {
        keys: ['category'],
        threshold: matchSorter.rankings.CASE_SENSITIVE_EQUAL,
      });
    }

    return matchSorter(filteredByCategory, searchTerm, {
      keys: ['name', 'description'],
      threshold: matchSorter.rankings.CONTAINS,
    });
  }, [searchTerm, categoryId, items]);

  return (
    <div className="flex flex-col py-4 px-2 items-center flex-1">
      <div className="flex flex-col space-y-4 w-full">
        {filteredItems.length > 0 ? (
          <>
            {filteredItems.map((item, i) => (
              <Card
                key={`select_library_${item.id}_${i}`}
                className="hover:bg-accent"
              >
                <CardContent className="flex justify-between items-center py-4 px-4">
                  <CardHeader className="p-1">
                    <CardTitle>{item.name}</CardTitle>
                    <CardDescription>{item.description}</CardDescription>
                  </CardHeader>
                  <div className="px-4">
                    <Button
                      variant="default"
                      isLoading={loadingId === item.id && loading}
                      disabled={loading}
                      onClick={() => handleSelect(item.id)}
                      data-library-id={item.id}
                      data-testid="add-library-button"
                    >
                      {selectButtonText}
                    </Button>
                  </div>
                </CardContent>
              </Card>
            ))}
          </>
        ) : (
          <div className="flex justify-center items-center h-full w-full">
            <TKNoDataFound
              imageWidth="100%"
              title={t('common.noDataMatchingFilters')}
            >
              {t('common.noDataMatchingFilters')}
            </TKNoDataFound>
          </div>
        )}
      </div>
    </div>
  );
};

export default LibraryList;
