import { useQueryClient } from '@tanstack/react-query';
import { signOut } from 'firebase/auth';
import { toast } from 'sonner';
import { useRouter } from '@/hooks/common/useRouter';
import { firebaseAuth } from '@/lib/firebaseClient';

export function useAuthLogout() {
  const router = useRouter();
  const queryClient = useQueryClient();
  /**
   * Wrapper to log out the existing user, it also discards the current apollo client and creates a new one.
   */
  async function logout() {
    try {
      queryClient.cancelQueries();
      queryClient.clear();
      queryClient.getQueryCache().clear();
      queryClient.unmount();
      await signOut(firebaseAuth);
      router.push('/auth-login', {});
    } catch (error: any) {
      toast.error(error?.message);
    }
  }

  return { logout };
}
