import { useState } from 'react';
import { Compass } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { EquipmentById } from '@/types/equipment';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import CurrentPositionField from '@/components/Equipment/CurrentPositionField';
import CurrentDriverField from '@/components/Equipment/CurrentDriverField';
import CurrentSpeedField from '@/components/Equipment/CurrentSpeedField';
import CurrentWeightField from '@/components/Equipment/CurrentWeightField';
import FuelLevelField from '@/components/Equipment/FuelLevelField';
import DefLevelField from '@/components/Equipment/DefLevelField';
import DistanceDrivenField from '@/components/Equipment/DistanceDrivenField';
import EngineTimeField from '@/components/Equipment/EngineTimeField';

interface Props {
  equipment: EquipmentById;
}

function StatusSection({ equipment }: Props): JSX.Element {
  const { t } = useTranslation();
  const { companyId } = useAuth();
  const [now] = useState(() => new Date().toISOString());

  const { data: statusData } = trpc.equipment.statusAtTime.useQuery({
    companyId,
    id: equipment.id,
    atTime: now,
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Compass className="h-5 w-5" />
          {t('equipment.columns.vehicleStatus')}
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-4 flex flex-col">
        <CurrentPositionField
          equipmentId={equipment.id}
          displayName={equipment.displayName}
        />
        {['TRUCK', 'CAR'].includes(equipment.type) && (
          <>
            <CurrentDriverField equipmentId={equipment.id} />
            <CurrentSpeedField
              kmH={statusData?.speedKmH}
              sampledAt={statusData?.speedSampledAt}
            />
            <CurrentWeightField
              weightKg={statusData?.weightKg}
              sampledAt={statusData?.weightSampledAt}
            />
            <FuelLevelField
              fuelLevelPercentage={statusData?.fuelLevelPercentage}
              sampledAt={statusData?.fuelLevelSampledAt}
            />
            <DefLevelField
              defLevelPercentage={statusData?.defLevelPercentage}
              sampledAt={statusData?.defLevelSampledAt}
            />
            <DistanceDrivenField
              drivingDistanceMeter={statusData?.drivingDistanceMeter}
              sampledAt={statusData?.drivingDistanceSampledAt}
            />
            <EngineTimeField
              engineSeconds={statusData?.engineSeconds}
              sampledAt={statusData?.engineSampledAt}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
}

export default StatusSection;
